import gambar0 from '../image/gambar0.jpg';
import gambar1 from '../image/gambar1.jpeg';
import gambar2 from '../image/image-artikel/artikel-bahan.jpeg';
import gambar3 from '../image/gambar3.jpeg';
import gambar4 from '../image/image-artikel/kekebalan.jpeg';
import gambar5 from '../image/image-artikel/radang_tenggorokan.png';
import gambar6 from '../image/image-artikel/14-26-01-2024.jpg';
import gambar7 from '../image/image-artikel/batuk_berdahak.webp';
import gambar8 from '../image/image-artikel/11-26-01-2024.jpg';
import gambar9 from '../image/image-artikel/16-26-01-2024.jpg';
import gambar10 from '../image/image-artikel/12-26-01-2024.jpg';
import gambar11 from '../image/image-artikel/gambar11.jpg';
import gambar12 from '../image/image-artikel/gambar12.jpg';
import gambar13 from '../image/gambar13.jpg';
import gambar14 from '../image/gambar14.jpg';
import gambar15 from '../image/gambar15.jpg';
import gambar16 from '../image/image-artikel/17-26-01-2024.jpg';
import gambar17 from '../image/image-artikel/18-26-01-2024.png';
import gambar18 from '../image/image-artikel/13-26-01-2024.webp';
import gambar19 from '../image/image-artikel/15-26-01-2024.jpg';
import gambar20 from '../image/image-artikel/lode.jpg';
import gambar21 from '../image/image-artikel/update-22-04.webp';
import gambar22 from '../image/image-artikel/61e89eb479b45.jpg';
import gambar23 from '../image/image-artikel/burger.jpg';

const  dataArtikel = [
    {
        id: {
            "nama": "Lodeh Terong Asam Jawa Menu Sayuran yang Lezat dan Bergizi",
            "deskripsi": `
            Lodeh Terong Asam Jawa adalah sajian sayuran yang memadukan kelezatan terong dengan cita rasa khas asam jawa. Hidangan tradisional ini tidak hanya menggugah selera tetapi juga kaya akan nutrisi yang baik untuk kesehatan.

            Terong yang lembut dan gurih berpadu sempurna dengan kuah lodeh yang kaya rempah, memberikan rasa yang dalam dan kompleks. Asam jawa memberikan sentuhan segar dan asam yang menyempurnakan keseluruhan rasa, membuat setiap suapan menjadi pengalaman yang memuaskan.
            `,
            "image": gambar20,
            "masak": {
                "bahan": `1) 2 buah terong ungu, potong-potong sesuai selera
                2) 100 gram kacang panjang, potong-potong
                3) 200 ml santan kental
                4) 500 ml santan encer
                5) 2 lembar daun salam
                6) 2 cm lengkuas, memarkan
                7) 1 buah cabai merah besar, iris serong
                8) 2 buah cabai hijau besar, iris serong
                9) 1 sendok makan asam jawa, larutkan dalam 50 ml air, saring
                10) 2 sendok makan minyak untuk menumis
                11) Garam dan gula secukupnya
                12) 6 butir bawang merah
                13) 4 siung bawang putih
                14) 3 butir kemiri, sangrai
                15) 2 cm kunyit, bakar
                16) 1 sendok teh terasi, bakar
                17) 3 buah cabai merah keriting`,
                "cara_masak": `Persiapan Bahan:

                Cuci bersih semua sayuran. Potong-potong terong dan kacang panjang sesuai selera. Rendam terong dalam air garam selama 10 menit, lalu tiriskan.
                Tumis Bumbu:
                
                Panaskan minyak dalam wajan. Tumis bumbu halus bersama daun salam dan lengkuas hingga harum dan matang.
                Masak Sayuran:
                
                Masukkan terong dan kacang panjang ke dalam wajan. Aduk rata hingga terong agak layu.
                Tuangkan santan encer. Aduk perlahan agar santan tidak pecah.
                Tambahkan Bumbu:
                
                Masukkan cabai merah dan cabai hijau iris. Tambahkan garam dan gula secukupnya. Masak hingga sayuran setengah matang.
                Tambahkan Asam Jawa:
                
                Tuangkan air asam jawa yang telah disaring ke dalam wajan. Aduk rata dan masak hingga sayuran benar-benar matang.
                Santan Kental:
                
                Tuangkan santan kental ke dalam wajan. Masak sambil terus diaduk perlahan hingga mendidih dan semua bahan matang sempurna. Koreksi rasa.
                Penyajian:
                
                Angkat dan tuang lodeh terong asam jawa ke dalam mangkuk saji. Sajikan hangat dengan nasi putih.`
                },
                "post": "Diposting pada 12/10/2024",
                "kategori": "trend",
                "referensi": "https://www.halodoc.com/artikel/ini-resep-sayur-lodeh-khas-jawa-yang-lezat-dan-mudah-dibuat",
            },
         en: {
                "nama": "Tamarind Eggplant Lodeh A Delicious and Nutritious Vegetable Menu",
                "deskripsi": `
                Tamarind Eggplant Lodeh is a vegetable dish that combines the deliciousness of eggplant with the distinctive taste of tamarind. This traditional dish is not only appetizing but also rich in nutrients that are good for health.

                The soft and savory eggplant combines perfectly with the rich, spiced lodeh sauce, providing a deep and complex taste. Tamarind provides a fresh and tart touch that enhances the overall taste, making every mouthful a satisfying experience.
                `,
                "image": gambar20,
                "masak": {
                    "bahan": `1) 2 purple eggplants, cut into pieces according to taste
                    2) 100 grams long beans, cut into pieces
                    3) 200 ml thick coconut milk
                    4) 500 ml thin coconut milk
                    5) 2 bay leaves
                    6) 2 cm galangal, crushed
                    7) 1 large red chili, sliced obliquely
                    8) 2 large green chilies, sliced obliquely
                    9) 1 tablespoon tamarind, dissolve in 50 ml water, strain
                    10) 2 tablespoons oil for frying
                    11) Salt and sugar to taste
                    12) 6 red onions
                    13) 4 cloves of garlic
                    14) 3 candlenuts, roasted
                    15) 2 cm turmeric, roasted
                    16) 1 teaspoon shrimp paste, grilled
                    17) 3 curly red chilies`,
                    "cara_masak": `Material Preparation:

                    Wash all vegetables thoroughly. Cut the eggplant and long beans according to taste. Soak the eggplant in salt water for 10 minutes, then drain.
                    Saute Seasoning:
                   
                    Heat oil in a frying pan. Saute ground spices with bay leaves and galangal until fragrant and cooked.
                    Cook Vegetables:
                   
                    Add the eggplant and long beans to the pan. Stir well until the eggplant wilts slightly.
                    Pour in the thin coconut milk. Stir gently so that the coconut milk doesn't break.
                    Add Spices:
                   
                    Add the red chilies and sliced green chilies. Add salt and sugar to taste. Cook until vegetables are half cooked.
                    Add Tamarind:
                   
                    Pour the strained tamarind water into the pan. Stir well and cook until the vegetables are completely cooked.
                    Thick coconut milk:
                   
                    Pour the thick coconut milk into the pan. Cook while continuing to stir gently until it boils and all ingredients are perfectly cooked. Taste correction.
                    Presentation:
                   
                    Remove and pour the tamarind eggplant lodeh into a serving bowl. Serve warm with white rice.`
                    },
                    "post": "Post on 12/10/2024",
                    "kategori": "trend",
                    "referensi": "https://www.halodoc.com/artikel/ini-resep-sayur-lodeh-khas-jawa-yang-lezat-dan-mudah-dibuat",
                },
            ar: {
                "nama": "لودة الباذنجان والتمر الهندي قائمة خضروات لذيذة ومغذية",
                "deskripsi": `
                لودة الباذنجان والتمر الهندي هو طبق خضار يجمع بين لذة الباذنجان وطعم التمر الهندي المميز. هذا الطبق التقليدي ليس فاتح للشهية فحسب، بل غني أيضًا بالعناصر الغذائية المفيدة للصحة.

                يمتزج الباذنجان الناعم واللذيذ بشكل مثالي مع صلصة اللوده الغنية والمتبلة، مما يوفر طعمًا عميقًا ومعقدًا. يوفر التمر الهندي لمسة طازجة ولاذعة تعزز المذاق العام، مما يجعل كل لقمة تجربة مرضية.
                `,
                "image": gambar20,
                "masak": {
                    "bahan": `1) 2 حبة باذنجان أرجوانية مقطعة إلى قطع حسب الرغبة
                    2) 100 جرام فاصوليا طويلة مقطعة إلى قطع
                    3) 200 مل حليب جوز الهند سميك القوام
                    4) 500 مل حليب جوز الهند رقيق
                    5) 2 ورق غار
                    6) 2 سم خولنجان مطحون
                    7) 1 حبة فلفل أحمر كبيرة، مقطعة بشكل مائل
                    8) 2 حبة فليفلة خضراء كبيرة الحجم، مقطعة بشكل مائل
                    9) 1 ملعقة كبيرة تمر هندي، تذوب في 50 مل ماء، تصفى
                    10) 2 ملاعق كبيرة زيت للقلي
                    11) الملح والسكر حسب الرغبة
                    12) 6 بصل أحمر
                    13) 4 فصوص ثوم
                    14) 3 حبات شمع محمصة
                    15) 2 سم كركم محمص
                    16) 1 ملعقة صغيرة معجون جمبري مشوي
                    17) 3 حبات فلفل أحمر حار مجعد`,
                    "cara_masak": `الإعداد المادي:

                    اغسل جميع الخضار جيدًا. قطع الباذنجان والفاصوليا الطويلة حسب الذوق. ينقع الباذنجان في الماء المملح لمدة 10 دقائق، ثم يصفى.
                    توابل مقلية:
                   
                    تسخين الزيت في مقلاة. تقلى البهارات المطحونة مع أوراق الغار والخولنجان حتى تفوح رائحتها وتنضج.
                    طبخ الخضار:
                   
                    أضف الباذنجان والفاصوليا الطويلة إلى المقلاة. يقلب جيدا حتى يذبل الباذنجان قليلا.
                    صب في حليب جوز الهند رقيقة. حرك بلطف حتى لا ينكسر حليب جوز الهند.
                    إضافة التوابل:
                   
                    أضيفي الفلفل الأحمر الحار والفلفل الأخضر المقطع. إضافة الملح والسكر إلى الذوق. اطبخي حتى تنضج الخضار نصفًا.
                    إضافة التمر الهندي:
                   
                    صب ماء التمر الهندي المصفى في المقلاة. يقلب جيدًا ويطهى حتى تنضج الخضار تمامًا.
                    حليب جوز الهند السميك:
                   
                    صب حليب جوز الهند السميك في المقلاة. يُطهى مع الاستمرار في التحريك بلطف حتى يغلي وتنضج جميع المكونات تمامًا. تصحيح الذوق.
                    عرض تقديمي:
                   
                    نخرج لفة الباذنجان بالتمر الهندي ونسكبها في وعاء التقديم. تقدم دافئة مع الأرز الأبيض.`
                    },
                    "post": "نشر على 12/10/2024",
                    "kategori": "trend",
                    "referensi": "https://www.halodoc.com/artikel/ini-resep-sayur-lodeh-khas-jawa-yang-lezat-dan-mudah-dibuat",
                },
            zh: {
                "nama": "罗望子茄子 Lodeh 美味又营养的蔬菜菜单",
                "deskripsi": `
                罗望子茄子 Lodeh 是一道蔬菜菜肴，结合了茄子的美味和罗望子的独特味道。 这道传统菜肴不仅味道鲜美，而且富含有益健康的营养成分。

                柔软可口的茄子与浓郁的五香洛德酱完美结合，带来深沉而复杂的味道。 罗望子提供了新鲜和酸的口感，增强了整体味道，让每一口都是令人满意的体验。
                `,
                "image": gambar20,
                "masak": {
                    "bahan": `1）紫茄子2个，根据口味切块
                    2）100克长豆，切块
                    3）200毫升浓椰浆
                    4）500毫升稀椰奶
                    5）2片月桂叶
                    6）2厘米高良姜，压碎
                    7）大红辣椒1个，斜片
                    8）大青辣椒2个，斜切片
                    9：1汤匙罗望子，溶解在50毫升水中，滤干
                    10) 2汤匙油 煎炸用
                    11）盐和糖适量
                    12）红洋葱6个
                    13）4瓣大蒜
                    14) 3 个 石栗，烤的
                    15) 2厘米姜黄，烤的
                    16) 1茶匙虾酱，烤的
                    17) 3个卷红辣椒`,
                    "cara_masak": `材料准备：

                    彻底清洗所有蔬菜。 根据口味将茄子和长豆切块。 将茄子在盐水中浸泡10分钟，然后沥干。
                    炒香调料：
                   
                    在煎锅中加热油。 将香料粉与月桂叶和高良姜一起炒香并煮熟。
                    煮蔬菜：
                   
                    将茄子和长豆放入锅中。 搅拌均匀，直到茄子稍微枯萎。
                    倒入稀椰奶。 轻轻搅拌，以免椰奶破裂。
                    添加香料：
                   
                    加入红辣椒和切好的青辣椒。 添加盐和糖调味。 煮至蔬菜半熟。
                    添加罗望子：
                   
                    将过滤后的罗望子水倒入锅中。 充分搅拌并煮至蔬菜完全煮熟。
                    浓椰奶：
                   
                    将浓稠的椰奶倒入锅中。 煮的同时继续轻轻搅拌，直到沸腾并且所有原料都完全煮熟。 口味修正。
                    推介会：
                   
                    将罗望子茄子捞出，倒入碗中。 与白米饭一起温热食用。`
                    },
                    "post": "发表于 12/10/2024",
                    "kategori": "trend",
                    "referensi": "https://www.halodoc.com/artikel/ini-resep-sayur-lodeh-khas-jawa-yang-lezat-dan-mudah-dibuat",
                },
     },
     {
        id: {
            "nama": "Sop Buntut Asam Jawa Paduan Lezat Antara Gurih dan Asam",
            "deskripsi": `
            Sop buntut asam Jawa merupakan hidangan yang menggugah selera dengan perpaduan unik antara cita rasa gurih dari daging buntut dan sentuhan segar asam Jawa. Berikut adalah resep untuk menyajikan hidangan lezat ini di rumah:
            `,
            "image": gambar21,
            "masak": {
                "bahan": `1) 1 kg daging buntut sapi, potong-potong
                2) 2 liter air
                3) 2 batang serai, memarkan
                4) 4 lembar daun salam
                5) 3 cm lengkuas, memarkan
                6) 5 butir bawang merah, haluskan
                7) 3 siung bawang putih, haluskan
                8) 3 buah tomat, potong-potong
                9) 2 buah wortel, potong-potong
                10) 2 sendok makan kecap manis
                11) 3 sendok makan air asam Jawa
                12) Garam secukupnya
                13) Gula secukupnya
                14) Minyak untuk menumis
                15) 5 butir bawang merah
                16) 3 siung bawang putih
                17) 2 cm jahe
                18) 3 cm kunyit
                19) 2 cm lengkuas
                20) 2 batang serai, bagian putihnya saja`,
                "cara_masak": `Tumis Bumbu Halus: Panaskan sedikit minyak dalam wajan, tumis bumbu halus hingga harum dan matang.

                Rebus Daging Buntut: Rebus daging buntut dalam air mendidih hingga empuk. Tiriskan daging buntut dan buang air rebusannya.
                
                Memasak Sop Buntut: Panaskan sedikit minyak dalam panci besar. Tumis bawang merah dan bawang putih halus hingga harum. Tambahkan serai, daun salam, dan lengkuas. Aduk merata.
                
                Tambahkan Bumbu: Masukkan daging buntut yang telah direbus ke dalam panci. Aduk rata dengan bumbu yang telah ditumis.
                
                Tambahkan Air: Tuangkan air secukupnya ke dalam panci hingga menutupi daging buntut. Biarkan mendidih.
                
                Tambahkan Bahan Tambahan: Setelah mendidih, tambahkan tomat dan wortel ke dalam panci. Aduk rata.
                
                Berikan Rasa: Tambahkan kecap manis, air asam Jawa, garam, dan gula secukupnya sesuai dengan selera. Aduk kembali dan biarkan mendidih hingga semua bahan matang dan bumbu meresap.
                
                Sajikan: Angkat sop buntut asam Jawa dan sajikan dalam mangkuk. Hidangkan selagi hangat.
                
                Hidangkan dengan Pelengkap: Sajikan sop buntut asam Jawa dengan irisan daun bawang dan seiris jeruk nipis di atasnya untuk memberikan aroma segar yang khas.`
                },
                "post": "Diposting pada 12/10/2024",
                "kategori": "trend",
                "referensi": "https://endeus.tv/resep/sop-buntut-asam-pedas-yang-segar",
            },
         en: {
                "nama": "Tamarind Oxtail Soup is a delicious combination of savory and sour",
                "deskripsi": `
                Tamarind oxtail soup is an appetizing dish with a unique combination of the savory taste of oxtail meat and a fresh touch of tamarind. Here is a recipe to serve this delicious dish at home:
                `,
                "image": gambar21,
                "masak": {
                    "bahan": `1) 1 kg oxtail meat, cut into pieces
                    2) 2 liters of water
                    3) 2 stalks of lemongrass, bruised
                    4) 4 bay leaves
                    5) 3 cm galangal, crushed
                    6) 5 red onions, puree
                    7) 3 cloves of garlic, crushed
                    8) 3 tomatoes, cut into pieces
                    9) 2 carrots, cut into pieces
                    10) 2 tablespoons sweet soy sauce
                    11) 3 tablespoons tamarind water
                    12) Salt to taste
                    13) Sugar to taste
                    14) Oil for frying
                    15) 5 red onions
                    16) 3 cloves of garlic
                    17) 2 cm ginger
                    18) 3 cm turmeric
                    19) 2 cm galangal
                    20) 2 stalks of lemongrass, white part only`,
                    "cara_masak": `Saute ground spices: Heat a little oil in a frying pan, saute ground spices until fragrant and cooked.

                    Boiled Oxtail Meat: Boil the oxtail meat in boiling water until tender. Drain the oxtail meat and discard the cooking water.
                   
                    Cooking Oxtail Soup: Heat some oil in a large saucepan. Saute the shallots and garlic until fragrant. Add lemongrass, bay leaves and galangal. Stir evenly.
                   
                    Add Seasoning: Put the boiled oxtail meat into the pan. Mix well with the sauteed spices.
                   
                    Add Water: Pour enough water into the pan to cover the oxtail meat. Let it boil.
                   
                    Add Additional Ingredients: Once boiling, add the tomatoes and carrots to the pot. Stir well.
                   
                    Give it taste: Add sweet soy sauce, tamarind juice, salt and sugar to taste according to taste. Stir again and let it boil until all the ingredients are cooked and the spices are absorbed.
                   
                    Serve: Remove the tamarind oxtail soup and serve in a bowl. Serve while warm.
                   
                    Serve with accompaniments: Serve the tamarind oxtail soup with sliced spring onions and a slice of lime on top to give it a distinctive fresh aroma.`
                    },
                    "post": "Post on 12/10/2024",
                    "kategori": "trend",
                    "referensi": "https://endeus.tv/resep/sop-buntut-asam-pedas-yang-segar",
                },
            ar: {
                "nama": "حساء التمر الهندي ذيل الثور هو مزيج لذيذ من المالح والحامض",
                "deskripsi": `
                حساء التمر الهندي هو طبق شهي مع مزيج فريد من المذاق اللذيذ للحوم ذيل الثور ولمسة طازجة من التمر الهندي. وإليكم وصفة لتقديم هذا الطبق اللذيذ في المنزل:
                `,
                "image": gambar21,
                "masak": {
                    "bahan": `1) 1 كيلو لحم ذيل الثور مقطع إلى قطع صغيرة
                    2) 2 لتر ماء
                    3) 2 ساق من عشبة الليمون، مفرومة
                    4) 4 أوراق غار
                    5) خولنجان 3 سم مطحون
                    6) 5 حبات بصل أحمر مهروسة
                    7) 3 فصوص من الثوم المهروس
                    8) 3 حبات طماطم مقطعة إلى قطع
                    9) 2 جزرة مقطعة إلى قطع
                    10) 2 ملعقة كبيرة صوص صويا حلو
                    11) 3 ملاعق كبيرة ماء التمر الهندي
                    12) ملح حسب الرغبة
                    13) سكر حسب الرغبة
                    14) زيت للقلي
                    15) 5 بصل أحمر
                    16) 3 فصوص ثوم
                    17) 2 سم زنجبيل
                    18) 3 سم كركم
                    19) خولنجان 2 سم
                    20) 2 ساق عشبة الليمون الجزء الأبيض فقط`,
                    "cara_masak": `البهارات المطحونة: سخني القليل من الزيت في مقلاة، واقلي البهارات المطحونة حتى تفوح رائحتها وتنضج.

                    لحم ذيل الثور المسلوق: يُسلق لحم ذيل الثور في الماء المغلي حتى ينضج. صفي لحم ذيل الثور وتخلصي من ماء الطهي.
                   
                    طبخ حساء ذيل الثور: سخني بعض الزيت في قدر كبيرة. يقلى الكراث والثوم حتى تفوح رائحته. أضف عشبة الليمون وأوراق الغار والخولنجان. حرك بالتساوي.
                   
                    أضف التوابل: ضع لحم ذيل الثور المسلوق في المقلاة. تخلط جيدا مع البهارات المقلية.
                   
                    أضف الماء: اسكب كمية كافية من الماء في المقلاة لتغطية لحم ذيل الثور. دعها تغلي.
                   
                    إضافة مكونات إضافية: بمجرد الغليان، أضف الطماطم والجزر إلى الوعاء. يقلب جيدا.
                   
                    أعطها المذاق: أضف صلصة الصويا الحلوة وعصير التمر الهندي والملح والسكر حسب الرغبة. حركي مرة أخرى واتركيه حتى يغلي حتى تنضج جميع المكونات وتمتص البهارات.
                   
                    للتقديم: أخرجي شوربة التمر الهندي وقدميها في وعاء. يقدم وهو دافئ.
                   
                    تقديمها مع المرافقات: تُقدم شوربة ذيل الثور بالتمر الهندي مع شرائح البصل الأخضر وشريحة من الليمون فوقها لتعطيها رائحة منعشة ومميزة.`
                    },
                    "post": "نشر على 12/10/2024",
                    "kategori": "trend",
                    "referensi": "https://endeus.tv/resep/sop-buntut-asam-pedas-yang-segar",
                },
            zh: {
                "nama": "Sop Buntut Asam Jawa Paduan Lezat Antara Gurih 在阿萨姆",
                "deskripsi": `
                罗望子牛尾汤是一道开胃的菜肴，独特地结合了牛尾肉的鲜味和罗望子的新鲜感。 这是在家做这道美味菜肴的食谱：
                `,
                "image": gambar21,
                "masak": {
                    "bahan": `1）1公斤牛尾肉，切块
                    2）2升水
                    3) 2 枝柠檬草，捣碎
                    4）4片月桂叶
                    5) 3厘米高良姜，压碎
                    6) 5个红洋葱，泥
                    7）3瓣大蒜，压碎
                    8）西红柿3个，切块
                    9）胡萝卜2根，切块
                    10）甜酱油2汤匙
                    11）3汤匙罗望子水
                    12) 盐适量
                    13）糖适量
                    14) 煎炸油
                    15) 5个红洋葱
                    16) 3瓣大蒜
                    17) 2厘米姜
                    18) 3厘米姜黄
                    19) 2厘米高良姜
                    20) 柠檬草 2 枝，只取白色部分`,
                    "cara_masak": `炒香料：在煎锅中加热少许油，将香料炒香并煮熟。

                    煮牛尾肉：将牛尾肉放入沸水中煮至软。 将牛尾肉沥干并倒掉煮水。
                   
                    烹饪牛尾汤：在大平底锅中加热一些油。 将青葱和大蒜炒香。 添加柠檬草、月桂叶和高良姜。 搅拌均匀。
                   
                    添加调料：将煮好的牛尾肉放入锅中。 与炒好的香料拌匀。
                   
                    加水：锅里倒入足够的水，没过牛尾肉。 让它沸腾。
                   
                    添加其他成分：煮沸后，将西红柿和胡萝卜加入锅中。 搅拌均匀。
                   
                    品尝：根据口味加入甜酱油、罗望子汁、盐和糖调味。 再次搅拌并煮沸，直至所有原料煮熟且香料被吸收。
                   
                    食用：取出罗望子牛尾汤，盛入碗中。 趁热食用。
                   
                    搭配佐料：将罗望子牛尾汤与切片葱和一片酸橙放在上面，赋予其独特的新鲜香气。`
                    },
                    "post": "发表于 12/10/2024",
                    "kategori": "trend",
                    "referensi": "https://endeus.tv/resep/sop-buntut-asam-pedas-yang-segar",
                },
     },
     {
        id: {
            "nama": "Smoothie Bowl Asam Jawa untuk Pagi yang Enerjik",
            "deskripsi": `
            Smoothie Bowl Asam Jawa adalah sajian sarapan yang menyegarkan dan memberi energi untuk memulai hari Anda dengan semangat. Dengan sentuhan unik dari bubuk asam Jawa yang memberikan rasa asam segar, smoothie bowl ini menggabungkan kelezatan buah-buahan beku seperti pisang, stroberi, atau mangga dengan kekentalan lembut yogurt dan rasa manis alami dari madu atau gula jawa.

            Smoothie ini disajikan dalam bentuk mangkuk yang memungkinkan Anda untuk menambahkan beragam topping sesuai selera. Potongan buah segar seperti pisang, stroberi, kiwi, atau blueberry memberikan sentuhan kelezatan dan nutrisi tambahan. Tambahkan juga potongan alpukat yang lembut, granola yang renyah, biji-bijian seperti chia seeds atau biji rami untuk kaya akan serat, serta potongan kacang almond atau kacang walnut untuk rasa dan tekstur yang lebih kaya.
            
            Dengan kombinasi yang seimbang antara rasa asam, manis, dan segar, serta kandungan nutrisi yang tinggi dari buah-buahan dan bahan-bahan lainnya, smoothie bowl asam Jawa ini adalah pilihan sarapan yang sempurna untuk memenuhi kebutuhan energi Anda pada pagi yang sibuk. Selain itu, kelezatan dan keunikan rasanya juga membuatnya menjadi sajian yang cocok untuk dinikmati kapan pun Anda menginginkan semangkuk sajian yang menyegarkan dan bergizi.
            `,
            "image": gambar22,
            "masak": {
                "bahan": `1) 1 buah pisang matang, potong-potong dan beku semalam
                2) 1 cangkir buah-buahan beku (misalnya, stroberi, blueberry, atau mangga)
                3) 2-3 sendok makan bubuk asam Jawa (sesuai selera)
                4) 1/2 cangkir yogurt plain atau yogurt almond
                5) 1/4 cangkir air atau susu almond (tambahkan lebih jika konsistensi terlalu kental)
                6) 1 sendok makan madu atau gula jawa (opsional, sesuai selera)
                7) Potongan buah segar (pisang, stroberi, kiwi, atau blueberry)
                8) Potongan alpukat
                9) Potongan mangga
                10) Chia seeds atau biji rami
                11) Granola
                12) Potongan kacang almond atau kacang walnut`,
                "cara_masak": `Persiapan Buah-buahan: Potong-potong buah-buahan segar seperti pisang, stroberi, mangga, dan kiwi. Jika Anda ingin menggunakan buah-buahan beku, pastikan untuk menyimpannya dalam freezer semalam sebelumnya.

                Blend: Masukkan pisang beku, buah-buahan beku, bubuk asam Jawa, yogurt, air atau susu almond, dan madu atau gula jawa ke dalam blender. Proses hingga halus dan konsistensi seperti es krim. Jika terlalu kental, tambahkan sedikit air atau susu almond.
                
                Penyajian: Tuangkan smoothie ke dalam mangkuk besar. Pastikan konsistensinya cukup tebal untuk menopang topping, tetapi masih bisa dimakan dengan sendok.
                
                Tambahkan Topping: Susun potongan buah segar, alpukat, granola, chia seeds, dan kacang almond atau walnut di atas smoothie.
                
                Nikmati: Sajikan segera dan nikmati smoothie bowl asam Jawa yang segar dan energik ini sebagai sarapan pagi yang menyehatkan.`
                },
                "post": "Diposting pada 12/10/2024",
                "kategori": "trend",
                "referensi": "https://palu.tribunnews.com/2019/07/13/10-kreasi-smoothie-bowl-dari-aneka-buah-yang-menyehatkan-simak-resepnya?page=all",
            },
         en: {
                "nama": "Tamarind Smoothie Bowl for an Energetic Morning",
                "deskripsi": `
                Tamarind Smoothie Bowl is a refreshing and energizing breakfast dish to start your day with enthusiasm. With a unique touch of tamarind powder which provides a fresh sour taste, this smoothie bowl combines the deliciousness of frozen fruit such as banana, strawberry or mango with the soft consistency of yogurt and the natural sweetness of honey or palm sugar.

                This smoothie is served in a bowl which allows you to add various toppings according to your taste. Pieces of fresh fruit such as bananas, strawberries, kiwi, or blueberries add a touch of deliciousness and added nutrition. Also add pieces of soft avocado, crunchy granola, seeds such as chia seeds or flax seeds for rich fiber, and chopped almonds or walnuts for a richer taste and texture.
            
                 With a balanced combination of sour, sweet and fresh flavors, as well as high nutritional content from fruit and other ingredients, this tamarind smoothie bowl is the perfect breakfast choice to meet your energy needs on busy mornings. Apart from that, its deliciousness and unique taste also make it a suitable dish to enjoy whenever you want a bowl of refreshing and nutritious food.
                `,
                "image": gambar22,
                "masak": {
                    "bahan": `1) 1 ripe banana, cut into pieces and freeze overnight
                    2) 1 cup frozen fruit (e.g., strawberries, blueberries, or mango)
                    3) 2-3 tablespoons tamarind powder (according to taste)
                    4) 1/2 cup plain yogurt or almond yogurt
                    5) 1/4 cup water or almond milk (add more if the consistency is too thick)
                    6) 1 tablespoon honey or palm sugar (optional, according to taste)
                    7) Fresh fruit pieces (banana, strawberries, kiwi, or blueberries)
                    8) Avocado slices
                    9) Mango chunks
                    10) Chia seeds or flax seeds
                    11) Granola
                    12) Slices of almonds or walnuts`,
                    "cara_masak": `Fruit Preparation: Cut fresh fruit such as banana, strawberry, mango and kiwi. If you want to use frozen fruit, make sure to store it in the freezer the night before.

                    Blend: Put frozen bananas, frozen fruit, tamarind powder, yogurt, water or almond milk, and honey or palm sugar in a blender. Process until smooth and has the consistency of ice cream. If it's too thick, add a little water or almond milk.
                   
                    To Serve: Pour the smoothie into a large bowl. Make sure the consistency is thick enough to support the topping, but can still be eaten with a spoon.
                   
                    Add Toppings: Arrange chopped fresh fruit, avocado, granola, chia seeds, and almonds or walnuts on top of the smoothie.
                   
                    Enjoy: Serve immediately and enjoy this fresh and energetic tamarind smoothie bowl as a healthy breakfast.`
                    },
                    "post": "Post on 12/10/2024",
                    "kategori": "trend",
                    "referensi": "https://palu.tribunnews.com/2019/07/13/10-kreasi-smoothie-bowl-dari-aneka-buah-yang-menyehatkan-simak-resepnya?page=all",
                },
            ar: {
                "nama": "وعاء عصير التمر الهندي لصباح مفعم بالحيوية",
                "deskripsi": `
                وعاء عصير التمر الهندي هو طبق إفطار منعش ومنشط لبدء يومك بحماس. مع لمسة فريدة من مسحوق التمر الهندي الذي يوفر مذاقًا حامضًا طازجًا، يجمع وعاء العصير هذا بين نكهة الفاكهة المجمدة مثل الموز أو الفراولة أو المانجو مع القوام الناعم للزبادي والحلاوة الطبيعية للعسل أو سكر النخيل.

                يتم تقديم هذا العصير في وعاء يسمح لك بإضافة طبقات مختلفة حسب ذوقك. تضيف قطع الفاكهة الطازجة مثل الموز أو الفراولة أو الكيوي أو التوت لمسة من الطعم اللذيذ والتغذية المضافة. أضف أيضًا قطعًا من الأفوكادو الناعم والجرانولا المقرمشة والبذور مثل بذور الشيا أو بذور الكتان للحصول على ألياف غنية واللوز أو الجوز المفروم للحصول على طعم وملمس أكثر ثراءً.
               
                مع مزيج متوازن من النكهات الحامضة والحلوة والطازجة، بالإضافة إلى المحتوى الغذائي العالي من الفاكهة والمكونات الأخرى، يعد وعاء عصير التمر الهندي هذا خيار الإفطار المثالي لتلبية احتياجاتك من الطاقة في الصباح المزدحم. وبصرف النظر عن ذلك، فإن لذتها وطعمها الفريد يجعلها أيضًا طبقًا مناسبًا للاستمتاع به كلما أردت وعاءً من الطعام المنعش والمغذي.
                `,
                "image": gambar22,
                "masak": {
                    "bahan": `1) 1 موزة ناضجة، مقطعة إلى قطع وتجمد طوال الليل
                    2) 1 كوب من الفاكهة المجمدة (مثل الفراولة أو التوت الأزرق أو المانجو)
                    3) 2-3 ملاعق كبيرة من مسحوق التمر الهندي (حسب الذوق)
                    4) 1/2 كوب زبادي عادي أو زبادي اللوز
                    5) ربع كوب ماء أو حليب اللوز (أضيفي المزيد إذا كان القوام سميكًا جدًا)
                    6) 1 ملعقة كبيرة عسل أو سكر النخيل (اختياري حسب الذوق)
                    7) قطع فواكه طازجة (موز، فراولة، كيوي، أو توت أزرق)
                    8) شرائح الأفوكادو
                    9) قطع المانجو
                    10) بذور الشيا أو بذور الكتان
                    11) الجرانولا
                    12) شرائح اللوز أو الجوز`,
                    "cara_masak": `تحضير الفاكهة: قطع الفاكهة الطازجة مثل الموز والفراولة والمانجو والكيوي. إذا كنت ترغب في استخدام الفاكهة المجمدة، تأكد من تخزينها في الثلاجة في الليلة السابقة.

                    الخلطة: ضعي الموز المجمد، والفواكه المجمدة، ومسحوق التمر الهندي، واللبن، والماء أو حليب اللوز، والعسل أو سكر النخيل في الخلاط. قم بالمعالجة حتى تصبح ناعمة ولها قوام الآيس كريم. إذا كان سميكًا جدًا، أضيفي القليل من الماء أو حليب اللوز.
                   
                    للتقديم: أسكبي العصير في وعاء كبير. تأكد من أن القوام سميك بدرجة كافية لدعم الطبقة العلوية، ولكن لا يزال من الممكن تناوله بالملعقة.
                   
                    أضف الطبقة: قم بترتيب الفواكه الطازجة المقطعة والأفوكادو والجرانولا وبذور الشيا واللوز أو الجوز فوق العصير.
                   
                    استمتع: قدمه على الفور واستمتع بوعاء عصير التمر الهندي الطازج والحيوي كوجبة إفطار صحية.`
                    },
                    "post": "نشر على 12/10/2024",
                    "kategori": "trend",
                    "referensi": "https://palu.tribunnews.com/2019/07/13/10-kreasi-smoothie-bowl-dari-aneka-buah-yang-menyehatkan-simak-resepnya?page=all",
                },
            zh: {
                "nama": "罗望子冰沙碗让您充满活力的早晨",
                "deskripsi": `
                罗望子冰沙碗是一道清爽又充满活力的早餐菜肴，让您充满热情地开始新的一天。 这款冰沙碗具有独特的罗望子粉味道，带来新鲜的酸味，将香蕉、草莓或芒果等冷冻水果的美味与酸奶的柔软稠度以及蜂蜜或棕榈糖的天然甜味结合在一起。

                这种冰沙装在一个碗里，您可以根据自己的口味添加各种配料。 香蕉、草莓、猕猴桃或蓝莓等新鲜水果增添了一丝美味和营养。 还可以添加柔软的鳄梨片、脆脆的格兰诺拉麦片、奇亚籽或亚麻籽等种子以获取丰富的纤维，以及切碎的杏仁或核桃以获得更丰富的味道和质地。
               
                这款罗望子冰沙碗均衡地结合了酸、甜和新鲜的口味，以及水果和其他成分的高营养含量，是满足您忙碌的早晨能量需求的完美早餐选择。 除此之外，它的鲜美和独特的味道也使它成为您想要一碗清爽营养的菜肴时的最佳选择。
                `,
                "image": gambar22,
                "masak": {
                    "bahan": `1）1根熟香蕉，切块，冷冻过夜
                    2）1杯冷冻水果（例如草莓、蓝莓或芒果）
                    3）2-3汤匙罗望子粉（根据口味）
                    4）1/2杯原味酸奶或杏仁酸奶
                    5）1/4杯水或杏仁奶（如果稠度太稠就多加一些）
                    6）1汤匙蜂蜜或棕榈糖（可选，根据口味）
                    7) 新鲜水果片（香蕉、草莓、猕猴桃或蓝莓）
                    8) 牛油果片
                    9) 芒果块
                    10) 奇亚籽或亚麻籽
                    11) 格兰诺拉麦片
                    12) 杏仁片或核桃片`,
                    "cara_masak": `水果准备：切新鲜水果，如香蕉、草莓、芒果和猕猴桃。 如果您想使用冷冻水果，请务必在前一天晚上将其存放在冰箱中。

                    混合：将冷冻香蕉、冷冻水果、罗望子粉、酸奶、水或杏仁奶、蜂蜜或棕榈糖放入搅拌机中。 加工直至光滑并具有冰淇淋的稠度。 如果太稠，加一点水或杏仁奶。
                   
                    食用：将冰沙倒入一个大碗中。 确保稠度足以支撑配料，但仍然可以用勺子吃。
                   
                    添加配料：将切碎的新鲜水果、鳄梨、格兰诺拉麦片、奇亚籽、杏仁或核桃放在冰沙上。
                   
                    享用：立即享用，享用这款新鲜而充满活力的罗望子冰沙碗作为健康早餐。`
                    },
                    "post": "发表于 12/10/2024",
                    "kategori": "trend",
                    "referensi": "https://palu.tribunnews.com/2019/07/13/10-kreasi-smoothie-bowl-dari-aneka-buah-yang-menyehatkan-simak-resepnya?page=allr",
                },
     },
     {
        id: {
            "nama": "Burger Vegetarian dengan Sambal Asam Jawa",
            "deskripsi": `
            Burger Vegetarian dengan Sambal Asam Jawa adalah harmoni cita rasa yang menggoda antara kelezatan nabati yang kaya akan protein dan serat dengan kepedasan menyengat dan kesegaran asam dari sambal asam Jawa. |
            Patty nabati yang lembut dan renyah, yang dipadukan dengan cita rasa rempah-rempah dan saus yang terpadu, memberikan pengalaman kuliner yang memikat bagi siapa pun yang mencarinya.
            Sambal asam Jawa yang unik memberikan sentuhan eksotis pada hidangan ini, dengan perpaduan pedas, manis, dan asam yang merangsang lidah. Ketika dihadirkan bersama dengan irisan segar tomat, mentimun, dan selada dalam burger bun yang empuk, 
            setiap gigitan menyajikan ledakan rasa yang memenuhi setiap sudut mulut dengan kelezatan yang tak tertandingi. Dengan kandungan nutrisi yang tinggi dan cita rasa yang memuaskan, burger ini bukan hanya pilihan yang menggugah selera, tetapi juga merupakan alternatif sehat dan berkesan untuk dinikmati dalam setiap kesempatan.
            `,
            "image": gambar23,
            "masak": {
                "bahan": `1) 1 kaleng kacang hitam, tiriskan dan bilas
                2) 1 cangkir oatmeal
                3) 1/2 cangkir tepung roti atau tepung roti panko
                4) 1/2 cangkir wortel parut
                5) 1/4 cangkir bawang bombay cincang
                6) 2 siung bawang putih, cincang halus
                7) 2 sendok makan saus tomat
                8) 1 sendok makan saus BBQ
                9) 1 sendok teh bubuk jinten
                10) 1 sendok teh bubuk paprika
                11) Garam dan lada hitam secukupnya
                12) Minyak zaitun untuk menggoreng
                13) 3-4 buah cabai merah besar, buang bijinya jika Anda ingin sambalnya tidak terlalu pedas
                14) 2 buah tomat, potong-potong
                15) 2 sendok makan pasta asam Jawa
                16) 1 sendok makan gula jawa atau madu
                17) Garam secukupnya
                18) Air secukupnya (untuk konsistensi yang diinginkan)
                19) Burger bun
                20) Selada
                21) Tomat
                22) Mentimun
                23) Keju (opsional)
                24) Mayones (opsional)`,
                "cara_masak": `Burger Vegetarian:
                Campurkan Bahan: Dalam mangkuk besar, hancurkan kacang hitam dengan garpu atau prosesor makanan hingga halus. Tambahkan oatmeal, tepung roti, wortel parut, bawang bombay, bawang putih, saus tomat, saus BBQ, bubuk jinten, bubuk paprika, garam, dan lada hitam. Aduk rata hingga semua bahan tercampur dengan baik.
                
                Bentuk Patty: Ambil sebagian adonan dan bentuk menjadi patty sesuai dengan ukuran yang diinginkan. Pastikan patty memiliki ketebalan yang seragam untuk memastikan matangnya yang merata.
                
                Panggang atau Goreng: Anda bisa memilih untuk memanggang atau menggoreng patty. Jika dipanggang, panggang dalam oven yang dipanaskan sebelumnya pada suhu 180°C selama sekitar 20-25 menit atau hingga matang sempurna. Jika digoreng, panaskan sedikit minyak zaitun dalam wajan anti lengket dan goreng patty hingga kecokelatan di kedua sisinya.
                
                Sambal Asam Jawa:
                Blend Bahan: Campur cabai merah besar, tomat, pasta asam Jawa, gula jawa atau madu, garam, dan sedikit air dalam blender. Proses hingga halus dan membentuk pasta. Tambahkan air secukupnya untuk mencapai konsistensi sambal yang diinginkan.
                
                Panaskan: Panaskan sedikit minyak dalam wajan, kemudian tumis pasta sambal yang sudah dibuat hingga harum dan matang. Anda juga bisa menambahkan sedikit gula atau garam jika perlu, sesuai dengan selera Anda.
                
                Penyajian:
                Siapkan Burger Bun: Potong burger bun menjadi dua bagian dan panggang sebentar jika Anda menginginkan tekstur yang lebih renyah.
                
                Susun Burger: Letakkan selembar selada, irisan tomat, mentimun, dan patty vegetarian di atas bagian bawah burger bun. Jika diinginkan, tambahkan sepotong keju di atas patty.
                
                Tambahkan Sambal: Oleskan sambal asam Jawa yang sudah Anda buat di atas patty atau letakkan di atas bagian atas burger bun sebelum menutupnya.
                
                Sajikan: Tutup burger dengan bagian atas bun dan sajikan segera. Anda juga bisa menambahkan mayones atau saus tambahan sesuai dengan selera Anda.`
                },
                "post": "Diposting pada 12/10/2024",
                "kategori": "trend",
                "referensi": "https://www.liputan6.com/hot/read/5307523/5-resep-burger-vegan-cocok-untuk-diet-dan-vegetarian",
            },
         en: {
                "nama": "Vegetarian Burger with Tamarind Sauce",
                "deskripsi": `
                Vegetarian Burger with Tamarind Sauce is a tantalizing harmony of taste between a vegetable delicacy that is rich in protein and fiber with the pungent spiciness and sour freshness of Javanese tamarind chili sauce. |
                The tender and crispy plant-based patty, combined with the integrated flavors of spices and sauce, provides an enticing culinary experience for anyone who seeks it.
                The unique tamarind chili sauce gives this dish an exotic touch, with a combination of spicy, sweet and sour that stimulates the taste buds. When served with fresh slices of tomato, cucumber and lettuce in a soft burger bun,
                every bite presents an explosion of flavors that fills every corner of the mouth with unmatched deliciousness. With its high nutritional content and satisfying taste, this burger is not only an appetizing choice, but also a healthy and memorable alternative to enjoy on any occasion.
                `,
                "image": gambar23,
                "masak": {
                    "bahan": `1) 1 can black beans, drain and rinse
                    2) 1 cup oatmeal
                    3) 1/2 cup breadcrumbs or panko breadcrumbs
                    4) 1/2 cup grated carrots
                    5) 1/4 cup chopped onions
                    6) 2 cloves of garlic, finely chopped
                    7) 2 tablespoons tomato sauce
                    8) 1 tablespoon BBQ sauce
                    9) 1 teaspoon cumin powder
                    10) 1 teaspoon paprika powder
                    11) Salt and black pepper to taste
                    12) Olive oil for frying
                    13) 3-4 large red chilies, remove the seeds if you want the chili sauce to be less spicy
                    14) 2 tomatoes, cut into pieces
                    15) 2 tablespoons tamarind paste
                    16) 1 tablespoon palm sugar or honey
                    17) Salt to taste
                    18) Enough water (for desired consistency)
                    19) Burger bun
                    20) Lettuce
                    21) Tomato
                    22) Cucumber
                    23) Cheese (optional)
                    24) Mayonnaise (optional)`,
                    "cara_masak": `Veggie Burger:
                    Combine Ingredients: In a large bowl, mash the black beans with a fork or food processor until finely chopped. Add oatmeal, breadcrumbs, grated carrots, onions, garlic, tomato sauce, BBQ sauce, cumin powder, paprika powder, salt and black pepper. Stir well until all ingredients are mixed well.
                   
                    Patty Shape: Take some dough and shape it into a patty according to the desired size. Make sure the patties are uniform in thickness to ensure even cooking.
                   
                    Grill or Fry: You can choose to grill or fry the patty. If baked, bake in a preheated oven at 180°C for about 20-25 minutes or until completely cooked. If frying, heat a little olive oil in a non-stick frying pan and fry the patties until browned on both sides.
                   
                    Tamarind Chili Sauce:
                    Blend Ingredients: Mix large red chilies, tomatoes, tamarind paste, palm sugar or honey, salt, and a little water in a blender. Process until smooth and forms a paste. Add enough water to reach the desired chili consistency.
                   
                    Heat: Heat a little oil in a frying pan, then saute the chili paste that has been made until fragrant and cooked. You can also add a little sugar or salt if necessary, according to your taste.
                   
                    Presentation:
                    Prepare the Burger Bun: Cut the burger bun in half and grill lightly if you want a crispier texture.
                   
                    Assemble the Burger: Place a sheet of lettuce, tomato slices, cucumber, and veggie patty on top of the bottom of the burger bun. If desired, add a slice of cheese on top of the patty.
                   
                    Add Sambal: Spread the tamarind chili sauce you have made on top of the patty or place it on the top of the burger bun before covering it.
                   
                    Serve: Cover the burger with the bun top and serve immediately. You can also add mayonnaise or additional sauce according to your taste.`
                    },
                    "post": "Post on 12/10/2024",
                    "kategori": "trend",
                    "referensi": "https://www.liputan6.com/hot/read/5307523/5-resep-burger-vegan-cocok-untuk-diet-dan-vegetarian",
                },
            ar: {
                "nama": "برجر نباتي مع صلصة التمر الهندي",
                "deskripsi": `
                البرجر النباتي مع صلصة التمر الهندي هو تناغم مذهل في المذاق بين نكهة الخضروات الغنية بالبروتين والألياف مع البهارات اللاذعة والنضارة الحامضة لصلصة التمر الهندي الحار الجاوية. |
                توفر هذه الفطيرة النباتية اللذيذة والمقرمشة، جنبًا إلى جنب مع النكهات المتكاملة من البهارات والصلصة، تجربة طهي جذابة لأي شخص يبحث عنها.
                صلصة التمر الهندي الحار الفريدة تضفي على هذا الطبق لمسة غريبة، مع مزيج من التوابل والحلو والحامض الذي يحفز براعم التذوق. عندما تقدم مع شرائح الطماطم والخيار والخس الطازجة في خبزة البرجر الناعمة،
                تقدم كل قضمة انفجارًا من النكهات التي تملأ كل ركن من أركان الفم بلذة لا مثيل لها. بفضل محتواه الغذائي العالي ومذاقه المُرضي، فإن هذا البرجر ليس فقط خيارًا فاتح للشهية، ولكنه أيضًا بديل صحي لا يُنسى للاستمتاع به في أي مناسبة.
                `,
                "image": gambar23,
                "masak": {
                    "bahan": `1) 1 علبة فاصوليا سوداء، تصفى وتشطف
                    2) ½ كوب شوفان
                    3) 1/2 كوب بقسماط أو بقسماط بانكو
                    4) 1/2 كوب جزر مبشور
                    5) 1/4 كوب بصل مفروم
                    6) 2 فص ثوم مفروم ناعماً
                    7) 2 ملعقة كبيرة صلصة طماطم
                    8) 1 ملعقة كبيرة صلصة باربكيو
                    9) 1 ملعقة صغيرة من مسحوق الكمون
                    10) 1 ملعقة صغيرة بابريكا بودرة
                    11) ملح وفلفل أسود حسب الرغبة
                    12) زيت زيتون للقلي
                    13) 3-4 حبات فلفل أحمر كبيرة الحجم، أزيلي البذور إذا كنت تريد أن تكون صلصة الفلفل الحار أقل حارًا
                    14) 2 طماطم مقطعة إلى قطع
                    15) 2 ملعقة كبيرة معجون تمر هندي
                    16) 1 ملعقة كبيرة سكر النخيل أو العسل
                    17) ملح حسب الرغبة
                    18) كمية كافية من الماء (للحصول على القوام المطلوب)
                    19) كعكة البرجر
                    20) الخس
                    21) الطماطم
                    22) الخيار
                    23) الجبن (اختياري)
                    24) مايونيز (اختياري)`,
                    "cara_masak": `برغر نباتي:
                    الجمع بين المكونات: في وعاء كبير، اهرسي الفاصوليا السوداء بالشوكة أو محضرة الطعام حتى يتم تقطيعها جيدًا. أضيفي دقيق الشوفان، فتات الخبز، الجزر المبشور، البصل، الثوم، صلصة الطماطم، صلصة الباربيكيو، مسحوق الكمون، مسحوق البابريكا، الملح والفلفل الأسود. يقلب جيدا حتى تمتزج جميع المكونات جيدا.
                   
                    شكل الفطيرة: نأخذ كمية من العجينة ونشكلها على شكل قرص حسب الحجم المطلوب. تأكد من أن الفطائر موحدة في السمك لضمان طهيها بالتساوي.
                   
                    شواء أو قلي: يمكنك اختيار شواء أو قلي الفطيرة. إذا خبزت، اخبزيها في فرن مسخن مسبقاً على حرارة 180 درجة مئوية لمدة تتراوح بين 20-25 دقيقة أو حتى تنضج تماماً. في حالة القلي، سخني القليل من زيت الزيتون في مقلاة غير لاصقة واقلي الفطائر حتى تحمر من الجانبين.
                   
                    صلصة التمر الهندي بالفلفل الحار:
                    مكونات الخلطة: اخلطي الفلفل الأحمر الكبير، والطماطم، ومعجون التمر الهندي، وسكر النخيل أو العسل، والملح، والقليل من الماء في الخلاط. قم بالمعالجة حتى تصبح ناعمة وتشكل عجينة. أضف كمية كافية من الماء للوصول إلى قوام الفلفل الحار المطلوب.
                   
                    التسخين: يُسخن القليل من الزيت في مقلاة، ثم يُقلى معجون الفلفل الحار حتى تفوح رائحته وينضج. يمكنك أيضًا إضافة القليل من السكر أو الملح إذا لزم الأمر، حسب ذوقك.
                   
                    عرض تقديمي:
                    تحضير كعكة البرجر: قم بتقطيع كعكة البرجر إلى نصفين وشويها قليلاً إذا كنت تريد قوامًا مقرمشًا.
                   
                    قم بتجميع البرجر: ضع شريحة من الخس وشرائح الطماطم والخيار وشريحة الخضروات فوق الجزء السفلي من خبز البرجر. إذا رغبت في ذلك، أضف شريحة من الجبن فوق الفطيرة.
                   
                    أضف السامبال: قم بتوزيع صلصة التمر الهندي بالفلفل الحار التي قمت بتحضيرها فوق الفطيرة أو ضعها فوق كعكة البرجر قبل تغطيتها.
                   
                    التقديم: قم بتغطية البرجر بالكعكة ثم قدمه على الفور. يمكنك أيضًا إضافة المايونيز أو الصلصة الإضافية حسب ذوقك.`
                    },
                    "post": "نشر على 12/10/2024",
                    "kategori": "trend",
                    "referensi": "https://www.liputan6.com/hot/read/5307523/5-resep-burger-vegan-cocok-untuk-diet-dan-vegetarian",
                },
            zh: {
                "nama": "罗望子酱素食汉堡",
                "deskripsi": `
                罗望子酱素食汉堡是富含蛋白质和纤维的蔬菜美味与爪哇罗望子辣椒酱的辛辣和酸鲜之间的美味和谐。 |
                嫩脆的植物肉饼，结合香料和酱汁的综合风味，为任何寻求它的人提供诱人的美食体验。
                独特的罗望子辣椒酱赋予这道菜异域风情，辣、甜、酸的结合刺激味蕾。 当与新鲜的番茄片、黄瓜片和生菜一起放入柔软的汉堡面包中时，
                每一口都呈现出爆炸般的味道，让口腔的每个角落都充满无与伦比的美味。 凭借其高营养含量和令人满意的口感，这款汉堡不仅是开胃的选择，也是在任何场合享用的健康和难忘的选择。
                `,
                "image": gambar23,
                "masak": {
                    "bahan": `1）1罐黑豆，沥干并冲洗干净
                    2）燕麦片1杯
                    3）1/2杯面包屑或panko面包屑
                    4）1/2杯磨碎的胡萝卜
                    5）1/4杯切碎的洋葱
                    6）2瓣大蒜，切碎
                    7）番茄酱2汤匙
                    8）烧烤酱1汤匙
                    9）孜然粉1茶匙
                    10）辣椒粉1茶匙
                    11) 盐和黑胡椒粉适量
                    12) 煎炸用橄榄油
                    13：大红辣椒3-4个，如果想让辣椒酱不那么辣，就去掉籽
                    14）西红柿2个，切块
                    15) 2汤匙罗望子酱
                    16）1汤匙棕榈糖或蜂蜜
                    17) 盐适量
                    18）足够的水（达到所需的稠度）
                    19) 汉堡包
                    20) 生菜
                    21) 番茄
                    22) 黄瓜
                    23）奶酪（可选）
                    24）蛋黄酱（可选）`,
                    "cara_masak": `素汉堡：
                    混合成分：在一个大碗中，用叉子或食品加工机将黑豆捣碎，直至切碎。 添加燕麦片、面包屑、磨碎的胡萝卜、洋葱、大蒜、番茄酱、烧烤酱、孜然粉、辣椒粉、盐和黑胡椒。 充分搅拌直至所有成分充分混合。
                   
                    馅饼形状：取一些面团，根据需要的大小，将其塑造成馅饼。 确保肉饼厚度均匀，以确保烹饪均匀。
                   
                    烧烤或煎炸：您可以选择烧烤或煎炸肉饼。 如果烘烤，请在预热的烤箱中以 180°C 烘烤约 20-25 分钟或直至完全煮熟。 如果煎炸，在不粘煎锅中加热少许橄榄油，将肉饼煎至两面焦黄。
                   
                    罗望子辣椒酱：
                    混合配料：在搅拌机中混合大红辣椒、​​西红柿、罗望子酱、棕榈糖或蜂蜜、盐和少量水。 加工直至光滑并形成糊状物。 添加足够的水以达到所需的辣椒稠度。
                   
                    热：炒锅中放入少许油，烧热后将做好的辣椒酱炒香熟。 如果需要的话，你也可以根据自己的口味添加一点糖或盐。
                   
                    推介会：
                    准备汉堡包：如果你想要更脆的口感，将汉堡包切成两半并轻轻烧烤。
                   
                    组装汉堡：将一片生菜、番茄片、黄瓜和蔬菜馅饼放在汉堡面包的底部。 如果需要，可以在肉饼上加一片奶酪。
                   
                    添加参巴酱：将您制作的罗望子辣椒酱涂在肉饼上，或将其放在汉堡包的顶部，然后覆盖它。
                   
                    食用：用面包顶部盖住汉堡并立即食用。 您还可以根据自己的口味添加蛋黄酱或其他酱汁。`
                    },
                    "post": "发表于 12/10/2024",
                    "kategori": "trend",
                    "referensi": "https://www.liputan6.com/hot/read/5307523/5-resep-burger-vegan-cocok-untuk-diet-dan-vegetarian",
                },
     },
     {
        "id": {
          "nama": "Ayam Bakar Asam Jawa",
          "deskripsi": "Ayam Bakar Asam Jawa cocok menjadi hidangan utama untuk perayaan Natal. Perpaduan bumbu rempah khas memberikan rasa unik, menggabungkan asam segar dari asam jawa, pedas, manis, dan gurih. Proses pemanggangan membuat ayam memiliki tekstur renyah di luar dan juicy di dalam, menggugah selera siapa saja. Bumbu marinasi yang meresap sempurna ke dalam daging ayam, seperti bawang putih, bawang merah, cabai, dan kecap manis, menciptakan cita rasa yang kaya dan seimbang. Hidangan ini membawa nuansa rasa Indonesia yang khas, memberikan sentuhan istimewa di meja makan Natal keluarga.",
          "image": gambar0,
          "masak": {
            "bahan": "1) 1 ekor ayam, potong menjadi beberapa bagian\n2) 3 sendok makan asam jawa\n3) 4 siung bawang putih, haluskan\n4) 5 butir bawang merah, haluskan\n5) 2 buah cabai merah, iris tipis (sesuai selera tingkat kepedasan)\n6) 2 sendok makan kecap manis\n7) 1 sendok teh garam\n8) 1 sendok teh gula pasir\n9) 1 batang serai, memarkan\n10) 3 lembar daun salam\n11) 2 cm jahe, geprek\n12) Minyak untuk mengoles ayam",
            "cara_masak": "Marinasi Ayam:\nCampurkan bawang putih, bawang merah, cabai, asam jawa, kecap manis, garam, dan gula pasir dalam wadah. Oleskan campuran bumbu ini ke seluruh bagian ayam, pastikan bumbu meresap ke dalam daging. Tambahkan serai, daun salam, dan jahe, lalu biarkan ayam meresap selama minimal 2 jam atau lebih untuk mendapatkan rasa yang lebih kuat.\nBakar Ayam:\nPanaskan arang atau grill, olesi dengan sedikit minyak untuk menghindari lengket. Letakkan ayam yang telah dimarinasi di atas panggangan, pastikan jarak antara ayam dan api tidak terlalu dekat. Panggang ayam sambil sesekali diolesi dengan bumbu marinasi agar tidak kering dan tetap juicy. Bakar hingga ayam matang sempurna dan berwarna kecoklatan di seluruh permukaannya.\nSajikan:\nAngkat ayam bakar dan sajikan hangat. Hidangkan dengan nasi putih dan lalapan segar.",
        },
        "post": "Diposting pada 18/12/2024",
        "kategori": "resep",
        "referensi": "https://cookpad.com/id/cari/ayam%20bakar%20asam%20jawa"
        },
          "en": {
            "nama": "Tamarind Chicken Soto",
            "deskripsi": "Soto Ayam Tamarind is a soupy dish that combines savory and fresh flavors in every bite. This dish uses chicken as the main ingredient which is cooked with typical Indonesian spices such as galangal, ginger, lemongrass and bay leaves, which provide a fragrant aroma and deep taste. A touch of sourness from tamarind adds freshness to the soto soup, creating the perfect balance of savory and sour. This soto is equipped with vermicelli, bean sprouts, boiled egg, and shredded chicken doused with hot soto sauce, as well as accompaniments such as fried potatoes, fried shallots, and lime juice to enrich the taste.\n\nThis dish is very suitable to be enjoyed in a warm atmosphere with family or friends. The uniqueness of Soto Ayam Tamarind lies in the combination of spices and the use of tamarind which provides a different flavor dimension from soto in general. Every element in this soto complements each other, resulting in a dish that is not only delicious but also refreshing. This dish provides a satisfying and enjoyable culinary experience, making it the right choice to warm up the atmosphere at your dining table.",
            "image": gambar0,
            "masak": {
              "bahan": "1) 1 chicken, cut into 4 parts\n2) 2 liters of water\n3) 4 bay leaves\n4) 4 lime leaves, remove the leaf bones\n5) 2 stalks of lemongrass, bruised\n6) 3 cm galangal, crushed\n7) 3 cm ginger, crushed\n8) 100 grams of tamarind, soak in 200 ml of water, squeeze and strain\n9) 200 ml thick coconut milk\n10) 2 spring onions, finely sliced\n11) 2 celery stalks, finely sliced\n12) 3 tablespoons oil for frying\n13) Salt and sugar to taste\n14) 8 cloves of garlic\n15) 6 red onions\n16) 4 candlenuts, roasted\n17) 2 cm turmeric, roasted\n18) 1 tsp coriander\n19) 1/2 tsp granulated pepper",
              "cara_masak": "Boiled Chicken:\nBoil the chicken in 2 liters of water with bay leaves, lime leaves, lemongrass, galangal and ginger until the chicken is tender. Remove the chicken and shred the meat, set aside. Save the broth for gravy.\nSaute Seasoning:\nHeat oil, saute ground spices until fragrant and cooked. Add it to the chicken stock.\nCook the Gravy:\nAdd the tamarind water to the broth, cook until it boils. Add thick coconut milk, stir gently so that the coconut milk doesn't break. Add salt and sugar to taste.\nAdd Vegetables:\nAdd the leeks and celery, cook briefly until wilted.\nServe:\nPrepare a bowl, arrange the vermicelli, bean sprouts, shredded chicken and boiled eggs. Drizzle with hot soto sauce. Add accompaniments such as French fries, fried shallots and lime. Serve with chili sauce to add a spicy sensation.",
            },
            "post": "Post on 18/12/2024",
            "kategori": "resep",
            "referensi": "https://cookpad.com/id/cari/ayam%20bakar%20asam%20jawa"
          },
          "ar": {
            "nama": "تمر هندي دجاج سوتو",
            "deskripsi": "سوتو أيام تمر هندي هو طبق حساء يجمع بين النكهات اللذيذة والطازجة في كل قضمة. يستخدم هذا الطبق الدجاج كمكون رئيسي يتم طهيه مع التوابل الإندونيسية النموذجية مثل الخولنجان والزنجبيل وعشب الليمون وأوراق الغار، والتي توفر رائحة عطرة وطعمًا عميقًا. تضيف لمسة من الحموضة من التمر الهندي نضارة إلى حساء السوتو، مما يخلق التوازن المثالي بين المالح والحامض. تم تجهيز هذا السوتو بالشعيرية وبراعم الفاصوليا والبيض المسلوق والدجاج المبشور المغطى بصلصة السوتو الساخنة، بالإضافة إلى المرافقات مثل البطاطس المقلية والكراث المقلي وعصير الليمون لإثراء المذاق.\n\nهذا الطبق مناسب جدًا للاستمتاع به في أجواء دافئة مع العائلة أو الأصدقاء. يكمن تفرد سوتو أيام تمر هندي في مزيج التوابل واستخدام التمر الهندي الذي يوفر بعدًا مختلفًا للنكهة عن سوتو بشكل عام. كل عنصر في هذا السوتو يكمل بعضها البعض، مما يؤدي إلى طبق ليس لذيذًا فحسب، بل منعشًا أيضًا. يوفر هذا الطبق تجربة طهي مرضية وممتعة، مما يجعله الخيار الصحيح لتدفئة الأجواء على طاولة طعامك.",
            "image": gambar0,
            "masak": {
              "bahan": "1) 1 دجاجة، مقطعة إلى 4 أجزاء\n2) 2 لتر ماء\n3) 4 أوراق غار\n4) 4 أوراق من الليمون الحامض، قم بإزالة عظام الأوراق\n5) 2 ساق من عشبة الليمون، مفرومة\n6) خولنجان 3 سم مطحون\n7) 3 سم زنجبيل مطحون\n8) 100 جرام من التمر الهندي، ينقع في 200 مل من الماء، ويعصر ويصفى\n9) 200 مل حليب جوز الهند سميك القوام\n10) 2 بصل أخضر مقطع إلى شرائح رفيعة\n11) 2 عود كرفس مقطع إلى شرائح رفيعة\n12) 3 ملاعق كبيرة زيت للقلي\n13) الملح والسكر حسب الرغبة\n14) 8 فصوص من الثوم\n15) 6 حبات بصل أحمر\n16) 4 حبات شمع محمصة\n17) 2 سم كركم محمص\n18) 1 ملعقة صغيرة كزبرة\n19) 1/2 ملعقة صغيرة فلفل حبيبات",
              "cara_masak": "دجاج مسلوق:\n\n يُسلق الدجاج في 2 لتر من الماء مع أوراق الغار، وأوراق الليمون، وعشب الليمون، والخولنجان، والزنجبيل حتى ينضج الدجاج. نخرج الدجاج ونقطع اللحم ونتركه جانباً. احفظ المرق للمرق.\n\nتوابل مقلية:\n\n يُحمّى الزيت، وتُقلى البهارات المطحونة حتى تفوح رائحتها وتنضج. أضفه إلى مرق الدجاج.\n\nتحضير المرق:\n\n أضف ماء التمر الهندي إلى المرق، اتركه يغلي. أضف حليب جوز الهند السميك وحركه برفق حتى لا يتخثر الحليب. أضف الملح والسكر حسب الرغبة.\n\nأضف الخضروات:\n\n أضف البصل الأخضر والكرفس، واطبخهم قليلاً حتى يذبلوا.\n\nالتقديم:\n\n حضّر وعاء، رتب فيه الشعيرية، براعم الفاصوليا، الدجاج المبشور والبيض المسلوق. رشّ عليه صلصة السوتو الساخنة. أضف المرافق مثل البطاطس المقلية، الكراث المقلي، وعصير الليمون. قدمه مع صلصة الفلفل الحار لإضفاء طعم حار.",
            },
            "post": "نُشر في 18/12/2024",
            "kategori": "resep",
            "referensi": "https://cookpad.com/id/cari/ayam%20bakar%20asam%20jawa"
          },
          "zh": {
            "nama": "印尼酸角烤鸡",
            "deskripsi": "印尼酸角烤鸡是圣诞节庆祝活动的理想主菜。其香料的独特组合融合了酸角的清新、辛辣、甜味和咸味。烤制的过程使鸡肉外脆内嫩，口感丰富，令人垂涎。腌制调料的完美渗透，如大蒜、红葱、辣椒和甜酱油，创造了丰富均衡的风味。这道菜带有浓厚的印尼特色，给圣诞家庭餐桌增添了别样风味。",
            "image": gambar0,
            "masak": {
              "bahan": "1) 1只鸡，切成几块\n2) 3汤匙酸角\n3) 4瓣大蒜，捣碎\n4) 5个红葱头，捣碎\n5) 2个红辣椒，切薄片（根据辣味需求调整）\n6) 2汤匙甜酱油\n7) 1茶匙盐\n8) 1茶匙糖\n9) 1根香茅，拍松\n10) 3片香叶\n11) 2厘米姜，拍松\n12) 少许油用于涂抹鸡肉",
              "cara_masak": "腌制鸡肉：\n将大蒜、红葱、辣椒、酸角、甜酱油、盐和糖混合在一个容器中，涂抹到鸡肉上，确保味道渗透到鸡肉中。加入香茅、香叶和姜，腌制至少2小时，让味道充分融合。\n烤鸡肉：\n预热烤炉或烧烤架，涂上一层油以防粘连。将腌制好的鸡肉放在烤架上，确保鸡肉与火源的距离适中。烤制鸡肉时不时涂上一层腌料，以保持肉质的多汁和鲜嫩。烤至鸡肉表面呈金黄色，熟透为止。\n上菜：\n将烤好的鸡肉取出，热食。搭配白米饭和新鲜蔬菜一起享用。",
            },
            "post": "发布于 18/12/2024",
            "kategori": "食谱",
            "referensi": "https://cookpad.com/id/cari/ayam%20bakar%20asam%20jawa"
          }
        },
      
      {
        "id": {
          "nama": "Sambal Terasi Asam Jawa",
          "deskripsi": "Sambal Terasi Asam Jawa menawarkan perpaduan rasa pedas, gurih, dan asam yang segar. Sambal ini menggunakan terasi sebagai bahan utama, memberikan rasa khas yang kuat, serta asam jawa yang menambah kedalaman rasa dan keasaman yang menyegarkan. Cocok sebagai pelengkap hidangan utama, sambal ini bisa dinikmati dengan nasi panas, ikan bakar, atau ayam goreng, menambah cita rasa Indonesia yang autentik dalam setiap suapan.",
          "image": gambar1,
          "masak": {
            "bahan": "1) 2 sendok makan terasi bakar\n2) 5 buah cabai merah\n3) 3 buah cabai rawit (sesuai selera pedas)...",
            "cara_masak": "Tumis Bumbu: Panaskan minyak goreng dalam wajan, tumis bawang merah, bawang putih..."
          },
          "post": "Diposting pada 18/12/2024",
          "kategori": "resep",
          "referensi": "https://cookpad.com/id/resep/13030117-sambal-terasi-mentah-asam-jawa"
        },
        "en": {
          "nama": "Sauteed Tamarind Chayote",
          "deskripsi": "This Tamarind Chayote Saute offers a fresh and savory taste with a refreshing touch of sourness. The combination of simple spices but rich in flavor makes this dish delicious and suitable to be enjoyed with the family. Good luck!",
          "image": gambar1,
          "masak": {
            "bahan": "1) 2 chayote, peeled and thinly sliced\n2) 2 tablespoons cooking oil\n3) 4 cloves of garlic, finely chopped...",
            "cara_masak": "Prepare Chayote: Peel the chayote, then cut it into thin slices. Sprinkle a little salt and knead until wilted..."
          },
          "post": "Posted on 18/12/2024",
          "kategori": "recipe",
          "referensi": "https://cookpad.com/id/resep/13030117-sambal-terasi-mentah-asam-jawa"
        },
        "ar": {
          "nama": "تمر هندي مقلي شايوت",
          "deskripsi": "يقدم Tamarind Chayote Saute طعمًا طازجًا ولذيذًا مع لمسة منعشة من الحموضة. مزيج التوابل البسيطة ولكن الغنية بالنكهة يجعل هذا الطبق لذيذًا ومناسبًا للاستمتاع به مع العائلة. حظ سعيد!",
          "image": gambar1,
          "masak": {
            "bahan": "1) 2 جزرة مقشرة ومقطعة إلى شرائح رفيعة\n2) 2 ملاعق كبيرة زيت طبخ\n3) 4 فصوص من الثوم المفروم ناعماً...",
            "cara_masak": "تحضير شايوت: قشري الشايوت، ثم قطعيه إلى شرائح رفيعة. يرش القليل من الملح ويعجن حتى يذبل ثم يشطف بالماء النظيف..."
          },
          "post": "تم النشر بتاريخ 18/12/2024",
          "kategori": "resep",
          "referensi": "https://cookpad.com/id/resep/13030117-sambal-terasi-mentah-asam-jawa"
        },
        "zh": {
          "nama": "炒罗望子佛手瓜",
          "deskripsi": "这款罗望子佛手瓜炒菜味道清新可口，带有清爽的酸味。简单的香料搭配，却味道浓郁，使得这道菜味道鲜美，适合与家人一起享用。祝你好运！",
          "image": gambar1,
          "masak": {
            "bahan": "1）2个佛手瓜，去皮，切成薄片\n2）2汤匙食用油\n3）4瓣大蒜，切碎...",
            "cara_masak": "准备佛手瓜：将佛手瓜去皮，然后切成薄片。撒少许盐揉至枯萎，然后用清水冲洗并沥干..."
          },
          "post": "发表于 18/12/2024",
          "kategori": "resep",
          "referensi": "https://cookpad.com/id/resep/13030117-sambal-terasi-mentah-asam-jawa"
        }
      },
      
        {

           id: {
                    "nama": "Rahasia Kulit Sehat Asam Jawa untuk Mengatasi Jerawat dan Mencerahkan Kulit",
                    "deskripsi": `Asam jawa atau tamarind memiliki khasiat luar biasa untuk kesehatan kulit, terutama dalam mengatasi masalah jerawat dan mencerahkan kulit. Kandungan alami dalam asam jawa, seperti AHA (Alpha Hydroxy Acids) dan vitamin C, membantu mempercepat regenerasi sel kulit, mengangkat sel kulit mati, dan membersihkan pori-pori yang tersumbat. Hal ini membuat asam jawa efektif untuk meredakan peradangan akibat jerawat dan mengurangi munculnya noda hitam atau bekas jerawat.
                                  Selain itu, sifat antioksidan dalam asam jawa mampu melawan radikal bebas dan memperbaiki kerusakan kulit akibat paparan sinar matahari. Penggunaan rutin asam jawa dalam perawatan kulit dapat membantu mencerahkan kulit secara alami dan membuatnya tampak lebih segar serta bercahaya. Campuran asam jawa dalam masker atau toner juga berfungsi untuk mengontrol minyak berlebih, sehingga cocok untuk jenis kulit berminyak yang rentan berjerawat.`,
                    "image": gambar2,
                    "post": "Diposting pada 12/10/2024",
                    "kategori": "pengobatan",
                    "referensi": "https://www.sukabumiupdate.com/food-travel/139285/cara-membuat-rebusan-asem-jawa-untuk-menurunkan-gula-darah-ini-8-langkahnya"
                },
           en: {
                    "nama": "The Secret to Healthy Skin: Tamarind to Treat Acne and Brighten Skin",
                    "deskripsi": `Tamarind, known for its powerful skincare benefits, is especially effective in treating acne and brightening the skin. Its natural components, such as Alpha Hydroxy Acids (AHA) and vitamin C, aid in exfoliating dead skin cells, promoting cell renewal, and unclogging pores. This makes it a great remedy for reducing acne inflammation and fading acne scars or dark spots.
                                  Additionally, the antioxidants in tamarind help combat free radicals and repair sun-damaged skin. With consistent use, it can naturally brighten the complexion, leaving the skin fresh and radiant. Tamarind-based masks or toners also regulate excess oil, making it ideal for oily, acne-prone skin.`,
                    "image": gambar2,
                    "post": "Post on 12/10/2024",
                    "kategori": "pengobatan",
                    "referensi": "https://www.sukabumiupdate.com/food-travel/139285/cara-membuat-rebusan-asem-jawa-untuk-menurunkan-gula-darah-ini-8-langkahnya",
               },
            ar: {
                    "nama": "سر البشرة الصحية: التمر الهندي لعلاج حب الشباب وتفتيح البشرة",
                    "deskripsi": `تُعد التمر الهندي خيارًا ممتازًا للعناية بالبشرة، خاصة في علاج حب الشباب وتفتيح البشرة. تحتوي هذه الفاكهة على أحماض ألفا هيدروكسي (AHA) وفيتامين C، مما يُساعد على تقشير الخلايا الميتة وتعزيز تجديد البشرة وتنظيف المسام المسدودة. هذا يجعلها فعالة في تقليل التهابات حب الشباب وإزالة آثارها أو البقع الداكنة.
كما تُساهم مضادات الأكسدة الموجودة في التمر الهندي في مكافحة الجذور الحرة وإصلاح الأضرار الناتجة عن التعرض للشمس. ومع الاستخدام المنتظم، يمكن للتمر الهندي أن يُحسّن إشراقة البشرة بشكل طبيعي ويجعلها أكثر نضارة. ويمكن استخدامه أيضًا في شكل ماسك أو تونر للتحكم في إفراز الدهون، مما يجعله مناسبًا للبشرة الدهنية المعرضة لحب الشباب.`,
                    "image": gambar2,
                    "post": "تم النشر بتاريخ 12/10/2024",
                    "kategori": "pengobatan",
                    "referensi": "https://www.sukabumiupdate.com/food-travel/139285/cara-membuat-rebusan-asem-jawa-untuk-menurunkan-gula-darah-ini-8-langkahnya"
                },
            zh: {
                    "nama": "健康皮肤的秘密：罗望子治疗痤疮并提亮肤色",
                    "deskripsi": `罗望子（酸角）在护肤方面有显著效果，尤其适合治疗痤疮和提亮肤色。它含有天然的α-羟基酸（AHA）和维生素C，有助于去除死皮细胞、促进细胞更新并清理堵塞的毛孔。这使其成为减轻痤疮炎症和淡化痘印或色斑的理想选择。

                                  此外，罗望子中的抗氧化剂能够对抗自由基，并修复因阳光损伤的皮肤。持续使用可以自然地提亮肤色，使肌肤焕发光彩。罗望子面膜或爽肤水还能调节多余的油脂，特别适合油性和易长痘的肌肤。`,
                    "image": gambar2,
                    "post": "发表于 12/10/2024",
                    "kategori": "pengobatan",
                    "referensi": "https://www.sukabumiupdate.com/food-travel/139285/cara-membuat-rebusan-asem-jawa-untuk-menurunkan-gula-darah-ini-8-langkahnya"
                },
        },
        {
            "id": {
                "nama": "Salad Natal dengan Dressing Asam Jawa",
                "deskripsi": "Salad Natal dengan Dressing Asam Jawa merupakan pilihan hidangan segar dan sehat untuk melengkapi perayaan Natal. Perpaduan sayuran segar seperti selada, tomat, dan wortel dipadukan dengan dressing asam jawa yang unik, memberikan rasa asam, manis, dan sedikit pedas. Dressing asam jawa ini memberikan cita rasa yang khas dan menyegarkan, menciptakan kombinasi yang sempurna dengan sayuran segar dan buah-buahan manis. Salad ini tidak hanya lezat, tetapi juga memberikan warna cerah yang cocok untuk meja makan Natal.",
                "image": gambar3,
                "masak": {
                    "bahan": "1) 1 batang selada romaine, sobek kasar\n2) 1 buah tomat merah, potong dadu\n3) 1 buah wortel, serut halus\n4) 1/2 buah mentimun, iris tipis\n5) 1/4 cangkir jagung manis rebus\n6) 1/4 cangkir kacang almond panggang (opsional)\n7) 1/2 buah apel hijau, potong dadu kecil\n8) 1 sendok makan asam jawa\n9) 2 sendok makan madu atau gula merah serut\n10) 1 sendok teh kecap manis\n11) 1 sendok makan minyak zaitun\n12) 1 sendok teh air perasan jeruk nipis\n13) 1 siung bawang putih, parut halus\n14) 1/2 sendok teh garam\n15) 1/4 sendok teh cabai bubuk (opsional, sesuai selera)",
                    "cara_masak": "Membuat Dressing:\nCampurkan asam jawa, madu, kecap manis, minyak zaitun, air jeruk nipis, bawang putih, garam, dan cabai bubuk dalam mangkuk kecil. Aduk rata hingga semua bahan tercampur dengan baik dan rasa seimbang antara manis, asam, dan sedikit pedas.\nMenyusun Salad:\nDalam mangkuk besar, masukkan selada, tomat, wortel, mentimun, jagung, apel, dan kacang almond panggang. Aduk rata agar bahan-bahan tercampur dengan baik.\nMenambahkan Dressing:\nTuangkan dressing asam jawa ke atas salad dan aduk perlahan hingga semua bahan terbalut dressing dengan rata.\nSajikan:\nSajikan salad segar ini sebagai hidangan pembuka atau pendamping di meja makan Natal Anda.",
                },
                "post": "Diposting pada 18/12/2024",
                "kategori": "resep",
                "referensi": "https://cookpad.com/id/cari/salad%20segar%20bumbu%20asam%20jawa"
            },
            "en": {
                "nama": "Tamarind Fried Rice is a harmonious combination of spicy and sour that is appetizing",
                "deskripsi": "Fried rice is a favorite dish that can be easily prepared and adapted to individual tastes. With the addition of tamarind, fried rice not only becomes fresher and more flavorful, but also adds a new dimension to its distinctive taste. In this article, we will discuss a simple recipe for making delicious and refreshing tamarind fried rice.",
                "image": gambar3,
                "masak": {
                    "bahan": "1) 3 plates of white rice (cold rice is better)\n2) 2 tablespoons cooking oil\n3) 2 eggs, well beaten\n4) 3 cloves of garlic, finely chopped\n5) 1 onion, chopped\n6) 1 spring onion, thinly sliced\n7) 1 carrot, cut into small cubes\n8) 100 grams peas (optional)\n9) 2 tablespoons sweet soy sauce\n10) 1 tablespoon soy sauce\n11) 2 tablespoons chili sauce (optional)\n12) 2 tablespoons tamarind, dissolve in 4 tablespoons water\n13) Salt and pepper to taste\n14) Crackers (optional)\n15) Cucumber slices for garnish (optional)\n16) Fried onions for sprinkling (optional)",
                    "cara_masak": "Heat cooking oil in a large wok or frying pan. Saute the garlic and onion until fragrant.\nAdd the beaten eggs to the pan and stir quickly until the eggs are half cooked.\nPlace the carrots and peas in the pan. Stir well and cook until the vegetables are half cooked.\nAdd white rice to the pan. Mix well with the other ingredients.\nPour sweet soy sauce, light soy sauce and chili sauce into the pan. Stir well until the rice is mixed well with the spices.\nAdd the tamarind solution to the pan. Stir again until all ingredients are mixed evenly. Test the taste, add salt and pepper to taste.\nRemove the fried rice from the heat. Serve hot tamarind fried rice topped with crackers, cucumber slices and fried onions as garnish.",
                },
                "post": "Post on 18/12/2024",
                "kategori": "resep",
                "referensi": "https://cookpad.com/id/cari/salad%20segar%20bumbu%20asam%20jawa"
            },
            "ar": {
                "nama": "الأرز المقلي بالتمر الهندي هو مزيج متناغم من التوابل والحامض اللذيذ",
                "deskripsi": "الأرز المقلي هو الطبق المفضل الذي يمكن تحضيره بسهولة وتكييفه مع الأذواق الفردية. مع إضافة التمر الهندي، لا يصبح الأرز المقلي طازجًا وأكثر نكهة فحسب، بل يضيف أيضًا بعدًا جديدًا لمذاقه المميز. سنتناول في هذا المقال وصفة بسيطة لتحضير الأرز المقلي بالتمر الهندي اللذيذ والمنعش.",
                "image": gambar3,
                "masak": {
                    "bahan": "1) 3 أطباق من الأرز الأبيض (الأرز البارد أفضل)\n2) 2 ملاعق كبيرة زيت طبخ\n3) 2 بيضة مخفوقة جيداً\n4) 3 فصوص من الثوم المفروم ناعماً\n5) 1 بصلة مفرومة\n6) 1 بصل أخضر، مقطع إلى شرائح رفيعة\n7) 1 جزرة، مقطعة إلى مكعبات صغيرة\n8) 100 جرام بازلاء (اختياري)\n9) 2 ملاعق كبيرة صلصة الصويا الحلوة\n10) 1 ملعقة كبيرة صويا صوص\n11) 2 ملعقة كبيرة صلصة الفلفل الحار (اختياري)\n12) 2 ملعقة كبيرة تمر هندي، مذوبة في 4 ملاعق كبيرة ماء\n13) ملح وفلفل حسب الرغبة\n14) المفرقعات (اختياري)\n15) شرائح خيار للتزيين (اختياري)\n16) بصل مقلي للرش (اختياري)",
                    "cara_masak": "تسخين زيت الطهي في مقلاة كبيرة أو مقلاة. يقلى الثوم والبصل حتى تفوح رائحتهما.\n\n يُضاف البيض المخفوق إلى المقلاة ويُحرَّك بسرعة حتى ينضج البيض نصفًا.\n\n ضع الجزر والبازلاء في المقلاة. يقلب جيدًا ويطهى حتى تنضج الخضار نصفًا.\n\n أضف الأرز الأبيض إلى المقلاة. تخلط جيدا مع المكونات الأخرى.\n\n تُسكب صلصة الصويا الحلوة وصلصة الصويا الخفيفة وصلصة الفلفل الحار في المقلاة. يقلب جيدًا حتى يمتزج الأرز جيدًا مع البهارات.\n\n أضف محلول التمر الهندي إلى المقلاة. يقلب مرة أخرى حتى يتم خلط جميع المكونات بالتساوي. اختبر الطعم وأضف الملح والفلفل حسب الرغبة.\n\n ارفعي الأرز المقلي عن النار. يُقدم الأرز المقلي بالتمر الهندي ساخنًا مع البسكويت وشرائح الخيار والبصل المقلي للتزيين.",
                },
                "post": "تم النشر بتاريخ 18/12/2024",
                "kategori": "resep",
                "referensi": "https://cookpad.com/id/cari/salad%20segar%20bumbu%20asam%20jawa"
            },
            "zh": {
                "nama": "罗望子炒饭，酸辣和谐，开胃",
                "deskripsi": "炒饭是人们喜爱的菜肴，制作简单，而且可以根据个人口味进行调整。加入罗望子后，炒饭不仅变得更加新鲜可口，而且还为其独特的口味增添了新的维度。在这篇文章中，我们将讨论制作美味清爽的罗望子炒饭的简单食谱。",
                "image": gambar3,
                "masak": {
                    "bahan": "1）白米饭3盘（冷米饭更好）\n2）2汤匙食用油\n3）鸡蛋2个，打散\n4）3瓣大蒜，切碎\n5）洋葱1个，切碎\n6）青葱1根，切成薄片\n7）胡萝卜1条，切小块\n8）100克豌豆（可选）\n9）甜酱油2汤匙\n10）酱油1汤匙\n11：辣椒酱2汤匙（可选）\n12：2汤匙罗望子，溶解在4汤匙水中\n13) 盐和胡椒调味\n14）饼干（可选）\n15) 装饰用黄瓜片（可选）\n16) 撒上炸洋葱（可选）",
                    "cara_masak": "在大锅或煎锅中加热食用油。 将大蒜和洋葱炒香。\n\n将打散的鸡蛋加入锅中，快速搅拌直至鸡蛋半熟。\n\n将胡萝卜和豌豆放入锅中。 搅拌均匀，煮至蔬菜半熟。\n\n将白米加入锅中。 与其他成分充分混合。\n\n将甜酱油、生抽和辣椒酱倒入锅中。 充分搅拌直至米饭与香料充分混合。\n\n将罗望子溶液加入锅中。 再次搅拌直至所有成分混合均匀。 试一下味道，加盐和胡椒粉调味。\n\n将炒饭从火上移开。 享用热罗望子炒饭，上面撒上饼干、黄瓜片和炸洋葱作为装饰。",
                },
                "post": "发表于 18/12/2024",
                "kategori": "resep",
                "referensi": "https://cookpad.com/id/cari/salad%20segar%20bumbu%20asam%20jawa"
            }
        },
        
        {
            "id": {
                "nama": "Sambal Goreng Kentang Asam Jawa",
                "deskripsi": "Sambal Goreng Kentang Asam Jawa adalah hidangan yang menyatukan rasa pedas, asam, dan gurih dalam satu sajian. Kentang yang digoreng renyah dipadukan dengan bumbu sambal yang kaya akan rasa, menggunakan asam jawa untuk memberikan sentuhan keasaman yang segar dan menggugah selera. Hidangan ini cocok untuk menemani hidangan utama dalam perayaan Natal atau sebagai lauk pendamping nasi. Sambal Goreng Kentang Asam Jawa ini akan menambah kelezatan di meja makan dengan perpaduan rasa yang sempurna.",
                "image": gambar13,
                "masak": {
                    "bahan": "1) 500 gram kentang, kupas dan potong dadu kecil\n2) 2 sendok makan asam jawa\n3) 4 siung bawang merah, iris tipis\n4) 3 siung bawang putih, iris tipis\n5) 5 buah cabai merah, iris serong\n6) 3 buah cabai rawit (sesuai selera pedas), iris serong\n7) 1 sendok teh terasi bakar\n8) 1 sendok makan gula merah serut\n9) 1 sendok teh garam\n10) 2 sendok makan minyak goreng (untuk menumis)\n11) 100 ml air matang",
                    "cara_masak": "Goreng Kentang:\n\nPanaskan minyak dalam wajan, lalu goreng potongan kentang hingga kecoklatan dan renyah. Angkat dan tiriskan.\nTumis Bumbu:\n\nPanaskan minyak dalam wajan, tumis bawang merah, bawang putih, cabai merah, dan cabai rawit hingga harum dan layu.\nMasukkan terasi bakar, aduk rata hingga bumbu matang dan aromanya keluar.\nTambahkan Asam Jawa:\n\nLarutkan asam jawa dengan air matang, lalu tuangkan ke dalam wajan bersama dengan gula merah dan garam. Aduk rata hingga bumbu tercampur sempurna.\nCampurkan Kentang:\n\nMasukkan kentang yang telah digoreng ke dalam wajan, aduk rata dengan bumbu hingga semua kentang terbalut bumbu sambal. Masak beberapa menit hingga bumbu meresap.\nSajikan:\n\nAngkat dan sajikan Sambal Goreng Kentang Asam Jawa sebagai lauk pendamping nasi hangat atau hidangan Natal lainnya.",
                },
                "post": "Diposting pada 18/12/2024",
                "kategori": "resep",
                "referensi": "https://cookpad.com/id/cari/sambal%20goreng%20kentang%20kering%20asam"
          },
            "en": {
                "nama": "The Secret to Fresh Soy Sauce Chicken with an Alluring Touch of Tamarind",
                "deskripsi": "Soy sauce chicken is one of the favorite dishes that is easy to prepare and always liked by many people. With the addition of tamarind, this dish not only becomes fresher, but also has a unique and attractive taste. In this article, we will explore a simple recipe for making delicious and satisfying soy sauce chicken with tamarind.",
                "image": gambar13,
                "masak": {
                    "bahan": "1) 500 grams of chicken meat, cut into small pieces\n2) 2 tablespoons cooking oil\n3) 3 cloves of garlic, finely chopped\n4) 1 onion, thinly sliced\n5) 2 cm ginger, grated\n6) 2 spring onions, thinly sliced\n7) 3 tablespoons sweet soy sauce\n8) 1 tablespoon soy sauce\n9) 2 tablespoons tomato sauce\n10) 2 tablespoons tamarind, dissolved in a little water\n11) Salt and pepper to taste\n12) 1 large red chili, thinly sliced (optional)\n13) Fried onions for sprinkling",
                    "cara_masak": "Heat cooking oil in a large frying pan. Saute garlic, onion, and ginger until fragrant. Put the chicken pieces into the pan. Saute until the chicken turns brown. Pour sweet soy sauce, light soy sauce, and tomato sauce into the pan. Stir well until the chicken is coated with spices. Add the tamarind solution to the pan. Stir again until all ingredients are mixed evenly. Let the chicken simmer and the spices soak into the meat. Taste test, add salt and pepper to taste. Add the sliced spring onions and red chilies (if using) to the pan. Stir briefly until all ingredients are mixed. Remove the soy sauce chicken from the heat and serve hot with a sprinkling of fried onions as garnish.",
                },
                "post": "Posted on 18/12/2024",
                "kategori": "resep",
                "referensi": "https://cookpad.com/id/cari/sambal%20goreng%20kentang%20kering%20asam"
           },
            "ar": {
                "nama": "سر الدجاج بصلصة الصويا الطازجة مع لمسة مغرية من التمر الهندي",
                "deskripsi": "يعتبر الدجاج بصلصة الصويا من الأطباق المفضلة وسهلة التحضير والتي يحبها دائماً الكثير من الناس. مع إضافة التمر الهندي، لا يصبح هذا الطبق طازجًا فحسب، بل يتمتع أيضًا بطعم فريد وجذاب. في هذه المقالة، سوف نستكشف وصفة بسيطة لتحضير دجاج لذيذ ومرضي بصلصة الصويا مع التمر الهندي.",
                "image": gambar13,
                "masak": {
                    "bahan": "1) 500 جرام من لحم الدجاج المقطع إلى قطع صغيرة\n2) 2 ملاعق كبيرة زيت طبخ\n3) 3 فصوص من الثوم المفروم ناعماً\n4) 1 بصلة، مقطعة إلى شرائح رفيعة\n5) 2 سم زنجبيل مبشور\n6) 2 بصل أخضر، مقطع إلى شرائح رفيعة\n7) 3 ملاعق كبيرة صويا صوص حلو\n8) 1 ملعقة كبيرة صويا صوص\n9) 2 ملعقة كبيرة صلصة طماطم\n10) 2 ملعقة كبيرة تمر هندي مذاب في قليل من الماء\n11) ملح وفلفل حسب الرغبة\n12) 1 فلفل أحمر حار كبير، مقطع إلى شرائح رفيعة (اختياري)\n13) بصل مقلي للرش",
                    "cara_masak": "تسخين زيت الطبخ في مقلاة كبيرة. يقلى الثوم والبصل والزنجبيل حتى تفوح رائحته.\nضعي قطع الدجاج في المقلاة. يقلى حتى يتحول الدجاج إلى اللون البني.\nتُسكب صلصة الصويا الحلوة وصلصة الصويا الخفيفة وصلصة الطماطم في المقلاة. قلبي جيدًا حتى يتغطى الدجاج بالبهارات.\nأضف محلول التمر الهندي إلى المقلاة. يقلب مرة أخرى حتى يتم خلط جميع المكونات بالتساوي.\nنترك الدجاج ينضج وتتبل البهارات باللحم. اختبار الذوق، إضافة الملح والفلفل حسب الذوق.\nأضف شرائح البصل الأخضر والفلفل الأحمر الحار (في حالة استخدامه) إلى المقلاة. يقلب لفترة وجيزة حتى يتم خلط جميع المكونات.\nنرفع الدجاج بصلصة الصويا عن النار، ونقدمه ساخناً مع رشة من البصل المقلي للتزيين.",
                },
                "post": "تم النشر بتاريخ 18/12/2024",
                "kategori": "resep",
                "referensi": "https://cookpad.com/id/cari/sambal%20goreng%20kentang%20kering%20asam"
           },
            "zh": {
                "nama": "带有诱人罗望子味的新鲜酱油鸡的秘密",
                "deskripsi": "酱油鸡是人们最喜欢的菜肴之一，其制作方法简单，一直受到很多人的喜爱。加入了罗望子，这道菜不仅变得更加新鲜，而且味道独特诱人。在这篇文章中，我们将探索一个简单的食谱，制作美味可口的罗望子酱油鸡。",
                "image": gambar13,
                "masak": {
                    "bahan": "1）鸡肉500克，切小块\n2）2汤匙食用油\n3）3瓣大蒜，切碎\n4）洋葱1个，切成薄片\n5）2厘米姜，磨碎\n6）2根葱，切成薄片\n7）甜酱油3汤匙\n8）酱油1汤匙\n9）番茄酱2汤匙\n10）2汤匙罗望子，溶解在少许水中\n11) 盐和胡椒调味\n12）1个大红辣椒，切成薄片（可选）\n13) 撒上炸洋葱",
                    "cara_masak": "在大煎锅中加热食用油。 将大蒜、洋葱和姜炒香。\n将鸡块放入锅中。 炒至鸡肉变成棕色。\n将甜酱油、生抽和番茄酱倒入锅中。 搅拌均匀，直到鸡肉裹上香料。\n将罗望子溶液加入锅中。 再次搅拌直至所有成分混合均匀。\n让鸡肉慢火煮，香料浸入肉中。 尝尝，加盐和胡椒调味。\n将切好的葱和红辣椒（如果使用）加入锅中。 短暂搅拌直至所有成分混合。\n将酱油鸡从火上移开，趁热撒上炸洋葱作为装饰。",
                },
                "post": "发表于 18/12/2024",
                "kategori": "resep",
                "referensi": "https://cookpad.com/id/cari/sambal%20goreng%20kentang%20kering%20asam"
          }
        },
        
        {
            "id": {
                "nama": "Kari Ayam Asam Jawa",
                "deskripsi": "Kari Ayam Asam Jawa menggabungkan rasa pedas, gurih, dan asam dalam satu hidangan yang kaya rasa. Daging ayam yang dimasak dalam kuah kari kental dipadukan dengan asam jawa yang memberikan kesegaran dan keasaman alami, menjadikannya pilihan hidangan yang sempurna untuk perayaan Natal. Bumbu kari yang harum, seperti kunyit, jahe, dan lengkuas, berpadu dengan rasa asam jawa yang khas, menciptakan hidangan yang memanjakan lidah dan menyegarkan selera.",
                "image": gambar14,
                "masak": {
                    "bahan": "1) 1 ekor ayam, potong menjadi beberapa bagian\n2) 1 sendok makan asam jawa\n3) 2 sendok makan minyak goreng\n4) 1 batang serai, memarkan\n5) 3 lembar daun salam\n6) 2 cm lengkuas, geprek\n7) 1 buah tomat, potong dadu\n8) 4 siung bawang merah, iris halus\n9) 3 siung bawang putih, iris halus\n10) 2 buah cabai merah, iris serong\n11) 1 sendok teh kunyit bubuk\n12) 1 sendok teh ketumbar bubuk\n13) 1 sendok teh garam\n14) 1 sendok teh gula merah serut\n15) 500 ml santan kelapa\n16) 200 ml air matang\n17) 1 sendok makan kecap manis (opsional)",
                    "cara_masak": "Tumis Bumbu:\nPanaskan minyak goreng dalam wajan, tumis bawang merah, bawang putih, dan cabai merah hingga harum.\nMasukkan kunyit bubuk, ketumbar, serai, daun salam, dan lengkuas. Tumis hingga bumbu matang dan harum.\nMasak Ayam:\nTambahkan potongan ayam ke dalam wajan, aduk rata hingga ayam berubah warna dan tercampur dengan bumbu.\nTambahkan Santan dan Asam Jawa:\nMasukkan santan kelapa dan air matang ke dalam wajan, aduk rata. Tambahkan asam jawa, gula merah, garam, dan kecap manis (jika menggunakan). Aduk lagi hingga bumbu tercampur rata.\nMasak Kari Ayam:\nMasak kari ayam dengan api kecil hingga ayam empuk dan kuah mengental, sekitar 30-40 menit. Cicipi rasa dan sesuaikan garam atau gula jika perlu.\nSajikan:\nAngkat kari ayam, sajikan panas dengan nasi putih hangat.",
                },
                "post": "Diposting pada 18/12/2024",
                "kategori": "resep",
                "referensi": "https://cookpad.com/id/resep/11349394-kari-ayam"
        },
            "en": {
                "nama": "Balado Shrimp with Tamarind Seasoning",
                "deskripsi": "Balado shrimp is a delicious dish that combines the deliciousness of shrimp with the spicy taste of balado sambal. However, this time, we will give it a special twist by adding tamarind seasoning to give a refreshing sour touch to this dish. Enjoy the unique sensation of Balado Shrimp with Tamarind Seasoning through the following recipe:",
                "image": gambar14,
                "masak": {
                    "bahan": "1) 500g prawns, clean and peel\n2) 2 tablespoons of tamarind soaked in water\n3) 5 red chilies, puree\n4) 3 bird's eye chilies, finely sliced (adjust to desired level of spiciness)\n5) 4 red onions, thinly sliced\n6) 3 cloves of garlic, finely chopped\n7) 2 tomatoes, cut into small pieces\n8) 2 stalks of lemongrass, bruised\n9) 2 bay leaves\n10) Sugar, salt and oil to taste",
                    "cara_masak": "Heat oil in a frying pan. Saute the shallots, garlic, red chilies and cayenne pepper until fragrant. Add lemongrass, bay leaves and tamarind that have been soaked in water. Stir well. Add the tomato pieces and cook until the tomatoes are soft. Add the prawns to the pan, stir well until the prawns change color. Adjust the taste with sugar and salt according to your taste. Cook until the shrimp are cooked and the spices are absorbed. Serve Balado Shrimp with Tamarind Seasoning on a serving plate.",
                },
                "post": "Posted on 18/12/2024",
                "kategori": "resep",
                "referensi": "https://cookpad.com/id/resep/11349394-kari-ayam"
        },
            "ar": {
                "nama": "روبيان بالادو مع توابل التمر الهندي",
                "deskripsi": "جمبري بالادو هو طبق لذيذ يجمع بين لذة الجمبري والطعم الحار للبلادو سامبال. ومع ذلك، هذه المرة، سنعطيه نكهة خاصة بإضافة توابل التمر الهندي لإضفاء لمسة حامضة منعشة على هذا الطبق. استمتع بالطعم الفريد لجمبري بالادو مع تتبيلة التمر الهندي من خلال الوصفة التالية:",
                "image": gambar14,
                "masak": {
                    "bahan": "1) 500 جرام جمبري، مقشر ومنظف\n2) 2 ملعقة كبيرة تمر هندي منقوع في الماء\n3) 5 حبات فلفل أحمر حار، مهروسة\n4) 3 حبات فلفل عين الطير الحار، مقطعة إلى شرائح رفيعة (اضبط مستوى البهارات المطلوب)\n5) 4 حبات بصل أحمر، مقطعة إلى شرائح رفيعة\n6) 3 فصوص من الثوم المفروم ناعماً\n7) 2 طماطم مقطعة إلى قطع صغيرة\n8) 2 سيقان من عشبة الليمون، مكدومة\n9) 2 ورق غار\n10) السكر والملح والزيت حسب الرغبة",
                    "cara_masak": "تسخين الزيت في مقلاة. يُقلى الكراث والثوم والفلفل الأحمر الحار والفلفل الحار حتى تفوح رائحته. أضيفي عشبة الليمون وأوراق الغار والتمر الهندي المنقوع في الماء. يقلب جيدا. أضيفي قطع الطماطم واطهيها حتى تصبح الطماطم طرية. أضيفي الجمبري إلى المقلاة، وقلبي جيدًا حتى يتغير لون الجمبري. اضبطي الطعم بالسكر والملح حسب ذوقك. اطبخي حتى ينضج الجمبري وتمتص البهارات. يُقدم جمبري بالادو مع بهارات التمر الهندي في طبق التقديم.",
                },
                "post": "تم النشر بتاريخ 18/12/2024",
                "kategori": "resep",
                "referensi": "https://cookpad.com/id/resep/11349394-kari-ayam"
        },
            "zh": {
                "nama": "罗望子调味巴拉多虾",
                "deskripsi": "Balado虾是一道结合了虾的鲜美和balado参巴辣味的美味佳肴。然而，这一次，我们将通过添加罗望子调味料来给这道菜带来特殊的风味，为这道菜带来清爽的酸味。按照以下食谱，享受罗望子调味巴拉多虾的独特口感：",
                "image": gambar14,
                "masak": {
                    "bahan": "1）虾500克，洗净，去皮\n2）2汤匙泡水的罗望子\n3）红辣椒5个，剁成泥\n4）鸟眼辣椒3条，切细片（根据自己喜欢的辣度调整）\n5）4个红洋葱，切成薄片\n6）3瓣大蒜，切碎\n7）西红柿2个，切小块\n8) 2 枝柠檬草，捣碎\n9) 2片月桂叶\n10) 糖、盐、油适量",
                    "cara_masak": "在煎锅中加热油。 将青葱、大蒜、红辣椒和辣椒炒香。 加入浸泡在水中的柠檬草、月桂叶和罗望子。 搅拌均匀。 加入番茄片，煮至番茄变软。 将虾放入锅中，搅拌均匀直至虾变色。 根据自己的口味加糖和盐调整口味。 煮至虾煮熟且香料被吸收。 将巴拉多虾和罗望子调味料放在盘子上。",
                },
                "post": "发表于 18/12/2024",
                "kategori": "resep",
                "referensi": "https://cookpad.com/id/resep/11349394-kari-ayam"
          }
        },
        
        {
            "id": {
                "nama": "Risotto Labu Asam Jawa",
                "deskripsi": "Risotto Labu Asam Jawa adalah hidangan yang memadukan kelembutan risotto dengan rasa manis alami dari labu dan keasaman segar dari asam jawa. Risotto yang creamy ini memiliki tekstur yang lembut dan rasa yang kaya, sementara labu memberikan sentuhan manis dan warna cerah yang menggugah selera. Asam jawa menambah kedalaman rasa yang unik, menjadikan hidangan ini pilihan sempurna untuk perayaan Natal, baik sebagai hidangan utama atau pendamping yang menyegarkan.",
                "image": gambar15,
                "masak": {
                    "bahan": "1) 200 gram beras arborio (untuk risotto)\n2) 500 gram labu kunir, kupas dan potong dadu kecil\n3) 1 sendok makan asam jawa\n4) 1 liter kaldu sayuran (atau kaldu ayam)\n5) 1/2 cangkir krim kental\n6) 1/2 cangkir keju parmesan parut\n7) 2 sendok makan mentega\n8) 1 sendok makan minyak zaitun\n9) 1/2 bawang bombay, cincang halus\n10) 2 siung bawang putih, cincang halus\n11) 1 sendok teh garam (atau sesuai selera)\n12) 1 sendok teh gula (opsional, untuk menyeimbangkan rasa)\n13) 1 sendok teh lada hitam\n14) 2 sendok makan daun parsley cincang (untuk garnish)",
                    "cara_masak": "Memasak Labu:\nRebus potongan labu dalam air mendidih hingga empuk, sekitar 10-15 menit. Setelah itu, haluskan labu dengan garpu atau blender hingga menjadi puree.\nMembuat Risotto:\nPanaskan minyak zaitun dan mentega dalam wajan besar, tumis bawang bombay dan bawang putih hingga harum dan lembut.\nTambahkan beras arborio ke dalam wajan, aduk rata hingga beras terbalut dengan minyak dan mentega.\nMenambahkan Kaldu:\nTuangkan kaldu sayuran sedikit-sedikit ke dalam beras sambil terus diaduk, tunggu hingga kaldu terserap sebelum menambahkan kaldu lagi. Lakukan ini hingga beras matang dan risotto memiliki tekstur lembut dan creamy (sekitar 18-20 menit).\nMenyelesaikan Risotto:\nSetelah risotto matang, masukkan puree labu, krim kental, dan keju parmesan. Aduk rata hingga semua bahan tercampur sempurna.\nTambahkan asam jawa, garam, gula (jika diperlukan), dan lada hitam. Aduk kembali dan cicipi, sesuaikan rasa.\nSajikan:\nSajikan risotto labu asam jawa dalam mangkuk, taburi dengan daun parsley cincang untuk garnish.",
                },
                "post": "Diposting pada 18/12/2024",
                "kategori": "resep",
                "referensi": "https://cookpad.com/id/cari/labu%20siam%20bening%20asam"
            },
            "en": {
                "nama": "Capcay Fresh Javanese Sour Sauce",
                "deskripsi": "Capcay is a vegetable dish originating from China which has become a favorite in various parts of the world, including Indonesia. This time, we will give a fresh touch to Capcay with a unique tamarind sauce. Enjoy the delicious crunchy vegetables and refreshing sauce with the following Capcay Tamarind Sauce recipe.",
                "image": gambar15,
                "masak": {
                    "bahan": "1) 200g broccoli, cut into small pieces\n2) 1 carrot, cut into matchsticks\n3) 100g green beans, cut obliquely\n4) 1 red bell pepper, cut into cubes\n5) 1 green bell pepper, cut into cubes\n6) 1 onion, cut long\n7) 100g mushrooms, thinly sliced\n8) 2 spring onions, cut obliquely\n9) 2 tablespoons of tamarind soaked in water\n10) 1 liter of chicken or vegetable stock\n11) 3 cloves of garlic, finely chopped\n12) 1 segment of ginger, thinly sliced\n13) 2 tablespoons sweet soy sauce\n14) 1 tablespoon sesame oil\n15) Salt and pepper to taste",
                    "cara_masak": "Heat oil in a large frying pan. Saute garlic and ginger until fragrant. Add broccoli, carrots, green beans, red peppers, and green peppers. Stir well until the vegetables are half cooked. Add mushrooms, onions and spring onions. Stir well and cook until the vegetables are cooked but still crunchy. Prepare tamarind sauce by mixing tamarind that has been soaked in water, stock, sweet soy sauce, sesame oil, salt and pepper. Stir well. Pour the sour sauce into the vegetable pan. Let it boil for a while. Adjust the seasoning according to your taste. Serve the Capcay Tamarind Sauce in a bowl, enjoy the fresh vegetables in the unique tamarind sauce.",
                },
                "post": "Posted on 18/12/2024",
                "kategori": "resep",
                "referensi": "https://cookpad.com/id/cari/labu%20siam%20bening%20asam"
            },
            "ar": {
                "nama": "كابكاي صلصة جاوية حامضة طازجة",
                "deskripsi": "الكابكاي هو طبق خضار أصله من الصين والذي أصبح المفضل في أجزاء مختلفة من العالم، بما في ذلك إندونيسيا. هذه المرة، سنضفي لمسة منعشة على كابكاي مع صلصة التمر الهندي الفريدة. استمتع بالخضار المقرمشة اللذيذة والصلصة المنعشة مع وصفة كابكاي صلصة التمر الهندي التالية.",
                "image": gambar15,
                "masak": {
                    "bahan": "1) 200 جرام بروكلي مقطع إلى قطع صغيرة\n2) 1 جزرة، مقطعة إلى أعواد الثقاب\n3) 100 جرام فاصوليا خضراء مقطعة بشكل مائل\n4) 1 حبة فليفلة حمراء مقطعة إلى مكعبات\n5) 1 حبة فليفلة خضراء مقطعة إلى مكعبات\n6) 1 بصلة مقطعة طولياً\n7) 100 جرام فطر مقطع إلى شرائح رفيعة\n8) 2 بصل أخضر مقطع بشكل مائل\n9) 2 ملعقة كبيرة تمر هندي منقوع في الماء\n10) 1 لتر من مرق الدجاج أو الخضار\n11) 3 فصوص من الثوم المفروم ناعماً\n12) 1 شريحة زنجبيل، مقطعة إلى شرائح رفيعة\n13) 2 ملعقة كبيرة صويا صوص حلو\n14) 1 ملعقة كبيرة زيت سمسم\n15) الملح والفلفل حسب الرغبة",
                    "cara_masak": "يحمى الزيت في مقلاة كبيرة. يقلى الثوم والزنجبيل حتى تفوح رائحته. أضيفي البروكلي، والجزر، والفاصوليا الخضراء، والفلفل الأحمر، والفلفل الأخضر. يقلب جيدًا حتى تنضج الخضار نصف. أضيفي الفطر والبصل والبصل الأخضر. يقلب جيدًا ويطهى حتى تنضج الخضار ولكن لا تزال مقرمشة. تحضير صلصة التمر الهندي عن طريق خلط التمر الهندي المنقوع في الماء والمرق وصلصة الصويا الحلوة وزيت السمسم والملح والفلفل. يقلب جيدا. تُسكب الصلصة الحامضة في وعاء الخضار. دعها تغلي لفترة من الوقت. اضبط التوابل حسب ذوقك. قدّمي صلصة الكابكاي بالتمر الهندي في وعاء، واستمتعي بالخضروات الطازجة في صلصة التمر الهندي الفريدة.",
                },
                "post": "تم النشر بتاريخ 18/12/2024",
                "kategori": "resep",
                "referensi": "https://cookpad.com/id/cari/labu%20siam%20bening%20asam"
            },
            "zh": {
                "nama": "卡卡新鲜爪哇酸酱",
                "deskripsi": "Capcay是一道起源于中国的蔬菜菜肴，现已成为包括印度尼西亚在内的世界各地的人们的最爱。 这次，我们将用独特的罗望子酱给卡卡伊带来新鲜感。 按照以下 Capcay 罗望子酱配方，享受美味松脆的蔬菜和清爽的酱汁。",
                "image": gambar15,
                "masak": {
                    "bahan": "1）西兰花200克，切小块\n2）胡萝卜1根，切成火柴棍状\n3）100克青豆，斜切\n4）红灯笼椒1个，切丁\n5）青椒1个，切丁\n6）洋葱1个，切长条\n7）100克蘑菇，切成薄片\n8）青葱2根，斜切\n9）2汤匙泡水的罗望子\n10）1升鸡肉或蔬菜高汤\n11）3瓣大蒜，切碎\n12）姜1段，切成薄片\n13）甜酱油2汤匙\n14：香油1汤匙\n15) 盐和胡椒调味",
                    "cara_masak": "在大煎锅中加热油。 将大蒜和姜炒香。 加入西兰花、胡萝卜、青豆、红辣椒和青椒。 充分搅拌直至蔬菜半熟。 加入蘑菇、洋葱和葱。 充分搅拌并煮至蔬菜煮熟但仍然松脆。 将浸泡在水、高汤、甜酱油、芝麻油、盐和胡椒中的罗望子混合，制备罗望子酱。 搅拌均匀。 将酸酱倒入蔬菜锅中。 让它煮一会儿。 根据自己的口味调整调味料。 将卡凯罗望子酱盛入碗中，享受独特的罗望子酱中的新鲜蔬菜。",
                },
                "post": "发表于 18/12/2024",
                "kategori": "resep",
                "referensi": "https://cookpad.com/id/cari/labu%20siam%20bening%20asam"
            }
        },        
        {
            id: {
                    "nama": "Kombinasi Asam Jawa dan Madu untuk Sistem Kekebalan Tubuh",
                    "deskripsi": `Kombinasi Asam Jawa dan Madu untuk Sistem Kekebalan Tubuh

                    Menggabungkan asam jawa dan madu sebagai bagian dari pola makan harian Anda dapat memberikan manfaat luar biasa bagi sistem kekebalan tubuh. Asam jawa, yang dikenal karena rasa asamnya yang khas, mengandung berbagai vitamin dan mineral, termasuk vitamin C, yang berperan penting dalam memperkuat sistem kekebalan. Selain itu, asam jawa memiliki sifat antioksidan yang membantu melawan radikal bebas dan mengurangi peradangan dalam tubuh. Ketika dikombinasikan dengan madu, yang juga kaya akan antioksidan serta memiliki sifat antibakteri dan antimikroba, manfaatnya bagi kesehatan menjadi lebih optimal.
                    
                    Madu, selain memberikan rasa manis alami yang lezat, telah lama digunakan dalam pengobatan tradisional untuk meningkatkan daya tahan tubuh dan mempercepat penyembuhan. Kandungan flavonoid dan fenolik dalam madu membantu memperkuat sistem kekebalan dengan meningkatkan produksi sel-sel imun. Kombinasi asam jawa dan madu tidak hanya menciptakan campuran yang lezat tetapi juga menyediakan sumber nutrisi yang dapat membantu tubuh melawan infeksi dan penyakit. Mengonsumsi campuran ini secara rutin dapat membantu menjaga kesehatan secara keseluruhan, memberikan perlindungan tambahan terutama di musim flu dan saat tubuh membutuhkan dukungan ekstra untuk tetap fit dan sehat.`,
                    "image": gambar4,
                    "post": "Diposting pada 12/10/2024",
                    "kategori": "pengobatan",
                    "referensi": "https://www.jpnn.com/news/7-manfaat-asam-jawa-campur-madu-nomor-4-bikin-anda-tenang"
                },
            en: {
                    "nama": "Hidden Benefits of Tamarind as a Natural Treatment for Toothache",
                    "deskripsi": `Combination of Tamarind and Honey for the Immune System

                    Combining tamarind and honey as part of your daily diet can provide tremendous benefits for the immune system. Tamarind, known for its distinctive sour taste, contains various vitamins and minerals, including vitamin C, which plays an important role in strengthening the immune system. In addition, tamarind has antioxidant properties that help fight free radicals and reduce inflammation in the body. When combined with honey, which is also rich in antioxidants and has antibacterial and antimicrobial properties, the health benefits are more optimal.
                    
                    Honey, in addition to providing a delicious natural sweet taste, has long been used in traditional medicine to increase the body's resistance and speed healing. The flavonoid and phenolic content in honey helps strengthen the immune system by increasing the production of immune cells. The combination of tamarind and honey not only creates a delicious mixture but also provides a source of nutrients that can help the body fight infection and disease. Consuming this mixture regularly can help maintain overall health, providing additional protection especially in flu season and when the body needs extra support to stay fit and healthy.`,
                    "image": gambar4,
                    "post": "Post on 12/10/2024",
                    "kategori": "pengobatan",
                    "referensi": "https://www.jpnn.com/news/7-manfaat-asam-jawa-campur-madu-nomor-4-bikin-anda-tenang"
                },
            ar: {
                    "nama": "فوائد التمر الهندي الخفية كعلاج طبيعي لألم الأسنان",
                    "deskripsi": `مزيج التمر الهندي والعسل لجهاز المناعة

                    إن الجمع بين التمر الهندي والعسل كجزء من نظامك الغذائي اليومي يمكن أن يوفر فوائد هائلة لجهاز المناعة. التمر الهندي، المعروف بطعمه اللاذع المميز، يحتوي على فيتامينات ومعادن مختلفة، من بينها فيتامين C، الذي يلعب دوراً مهماً في تقوية جهاز المناعة. بالإضافة إلى ذلك، يتمتع التمر الهندي بخصائص مضادة للأكسدة تساعد على محاربة الجذور الحرة وتقليل الالتهابات في الجسم. عند دمجه مع العسل، الغني أيضًا بمضادات الأكسدة وله خصائص مضادة للبكتيريا ومضادة للميكروبات، تكون الفوائد الصحية أكثر مثالية.
                    
                    العسل، بالإضافة إلى توفير طعم حلو طبيعي لذيذ، يستخدم منذ فترة طويلة في الطب التقليدي لزيادة مقاومة الجسم وسرعة الشفاء. يساعد محتوى الفلافونويد والفينول في العسل على تقوية جهاز المناعة عن طريق زيادة إنتاج الخلايا المناعية. مزيج التمر الهندي والعسل لا يخلق خليطًا لذيذًا فحسب، بل يوفر أيضًا مصدرًا للعناصر الغذائية التي يمكن أن تساعد الجسم على مكافحة العدوى والأمراض. يمكن أن يساعد استهلاك هذا الخليط بانتظام في الحفاظ على الصحة العامة، وتوفير حماية إضافية خاصة في موسم الأنفلونزا وعندما يحتاج الجسم إلى دعم إضافي للحفاظ على لياقته وصحته.`,
                    "image": gambar4,
                    "post": "تم النشر بتاريخ 12/10/2024",
                    "kategori": "pengobatan",
                    "referensi": "https://www.jpnn.com/news/7-manfaat-asam-jawa-campur-madu-nomor-4-bikin-anda-tenang"
                },
            zh: {
                    "nama": "罗望子作为牙痛自然疗法的隐藏好处",
                    "deskripsi": `罗望子和蜂蜜的组合对免疫系统的作用

                    将罗望子和蜂蜜结合作为日常饮食的一部分可以为免疫系统带来巨大的好处。罗望子以其独特的酸味而闻名，含有多种维生素和矿物质，其中维生素 C 在增强免疫系统方面发挥着重要作用。此外，罗望子具有抗氧化特性，有助于对抗自由基并减少体内炎症。当与同样富含抗氧化剂并具有抗菌和抗菌特性的蜂蜜结合使用时，健康益处更加理想。
                    
                    蜂蜜除了提供美味的天然甜味之外，长期以来一直被用于传统医学中以增加身体的抵抗力和加速愈合。蜂蜜中的类黄酮和酚类成分有助于通过增加免疫细胞的产生来增强免疫系统。罗望子和蜂蜜的结合不仅创造了美味的混合物，而且还提供了可以帮助身体抵抗感染和疾病的营养来源。定期食用这种混合物可以帮助维持整体健康，提供额外的保护，尤其是在流感季节以及当身体需要额外支持以保持健康时。`,
                    "image": gambar4,
                    "post": "发表于 12/10/2024",
                    "kategori": "pengobatan",
                    "referensi": "https://www.jpnn.com/news/7-manfaat-asam-jawa-campur-madu-nomor-4-bikin-anda-tenang"
                },
        },
        {
            id: {
                    "nama": "Pengobatan Alami Radang Tenggorokan, Manfaat Asam Jawa sebagai Penawar Tradisional yang Efektif",
                    "deskripsi": `Radang tenggorokan adalah kondisi umum yang dapat menyebabkan rasa sakit, iritasi, dan kesulitan menelan. Selain obat-obatan konvensional, banyak orang mencari pengobatan alami yang dapat membantu meredakan gejala radang tenggorokan. Salah satu bahan alami yang dipercaya memiliki potensi pengobatan adalah asam jawa. Dalam artikel ini, kita akan mengeksplorasi manfaat asam jawa sebagai penawar tradisional yang efektif untuk radang tenggorokan dan cara penggunaannya.

                    Manfaat Asam Jawa dalam Pengobatan Radang Tenggorokan:
                    
                    Sifat Antimikroba: Asam jawa mengandung senyawa-senyawa aktif yang memiliki sifat antimikroba, seperti asam asetat, yang dapat membantu melawan infeksi bakteri dan virus penyebab radang tenggorokan.
                    
                    Sifat Antiinflamasi: Kandungan antioksidan dalam asam jawa dapat membantu mengurangi peradangan dan iritasi di tenggorokan, sehingga meredakan rasa sakit dan ketidaknyamanan.
                    
                    Efek Menenangkan: Asam jawa memiliki efek menenangkan pada jaringan tenggorokan yang meradang, membantu mengurangi sensasi terbakar dan gatal-gatal yang sering terkait dengan radang tenggorokan.
                    
                    Cara Menggunakan Asam Jawa untuk Pengobatan Radang Tenggorokan:
                    
                    Larutan Gargle Asam Jawa: Campurkan asam jawa yang sudah larut dalam air hangat dan gunakan sebagai larutan kumur. Gargle larutan ini selama beberapa detik sebelum meludahkannya. Ulangi beberapa kali sehari untuk meredakan peradangan dan mempercepat proses penyembuhan.
                    
                    Minuman Hangat Asam Jawa: Larutkan asam jawa dalam air hangat dan tambahkan sedikit madu atau gula untuk memberikan rasa yang lebih enak. Minum minuman ini secara perlahan untuk meredakan radang tenggorokan dan meredakan gejala lainnya.
                    
                    Kompres Tenggorokan dengan Asam Jawa: Rendam kapas atau kain lembut dalam larutan asam jawa yang sudah dingin. Tempelkan kompres ini di sekitar leher dan tenggorokan selama beberapa menit untuk memberikan efek menenangkan dan mengurangi peradangan.
                    
                    Peringatan: Meskipun asam jawa memiliki potensi sebagai pengobatan alami untuk radang tenggorokan, penting untuk tetap mengikuti saran dari profesional kesehatan dan berkonsultasi sebelum menggunakan metode pengobatan apa pun, terutama jika gejala Anda parah atau berlangsung untuk waktu yang lama.`,
                    "image": gambar5,
                    "post": "Diposting pada 12/10/2024",
                    "kategori": "pengobatan",
                    "referensi": "https://www.klikdokter.com/info-sehat/tht/adakah-manfaat-asam-jawa-untuk-sakit-tenggorokan"
                },
            en: {
                    "nama": "Natural Treatment for Sore Throat, Benefits of Tamarind as an Effective Traditional Antidote",
                    "deskripsi": `Sore throat is a common condition that can cause pain, irritation, and difficulty swallowing. In addition to conventional medicines, many people are looking for natural remedies that can help relieve the symptoms of sore throat. One natural ingredient that is believed to have medicinal potential is tamarind. In this article, we will explore the benefits of tamarind as an effective traditional antidote for sore throats and how to use it.

                    Benefits of Tamarind in Treatment of Sore Throat:
                   
                    Antimicrobial Properties: Tamarind contains active compounds that have antimicrobial properties, such as acetic acid, which can help fight bacterial and viral infections that cause sore throat.
                   
                    Anti-Inflammatory Properties: The antioxidant content in tamarind can help reduce inflammation and irritation in the throat, thereby easing pain and discomfort.
                   
                    Soothing Effect: Tamarind has a calming effect on inflamed throat tissue, helping to reduce the burning sensation and itching often associated with sore throats.
                   
                    How to Use Tamarind for Treatment of Sore Throat:
                   
                    Tamarind Gargle Solution: Mix dissolved tamarind in warm water and use as a mouth rinse. Gargle this solution for a few seconds before spitting it out. Repeat several times a day to reduce inflammation and speed up the healing process.
                   
                    Warm Tamarind Drink: Dissolve tamarind in warm water and add a little honey or sugar to give it a better taste. Drink this drink slowly to soothe a sore throat and relieve other symptoms.
                   
                    Throat Compress with Tamarind: Soak cotton wool or a soft cloth in the cool tamarind solution. Place this compress around the neck and throat for a few minutes to provide a calming effect and reduce inflammation.
                   
                    Warning: Although tamarind has potential as a natural treatment for sore throat, it is important to follow the advice of a healthcare professional and consult before using any treatment method, especially if your symptoms are severe or persist for a long time.`,
                    "image": gambar5,
                    "post": "Post on 12/10/2024",
                    "kategori": "pengobatan",
                    "referensi": "https://www.klikdokter.com/info-sehat/tht/adakah-manfaat-asam-jawa-untuk-sakit-tenggorokan"
            },
            ar: {
                "nama": "العلاج الطبيعي لالتهاب الحلق، فوائد التمر الهندي كترياق تقليدي فعال",
                "deskripsi": `التهاب الحلق هو حالة شائعة يمكن أن تسبب الألم والتهيج وصعوبة البلع. بالإضافة إلى الأدوية التقليدية، يبحث العديد من الأشخاص عن علاجات طبيعية يمكن أن تساعد في تخفيف أعراض التهاب الحلق. أحد المكونات الطبيعية التي يعتقد أن لها إمكانات طبية هو التمر الهندي. وفي هذا المقال سنستكشف فوائد التمر الهندي كترياق تقليدي فعال لالتهاب الحلق وكيفية استخدامه.

                فوائد التمر الهندي في علاج التهاب الحلق:
               
                خصائص مضادة للميكروبات: يحتوي التمر الهندي على مركبات نشطة لها خصائص مضادة للميكروبات، مثل حمض الأسيتيك، والذي يمكن أن يساعد في مكافحة الالتهابات البكتيرية والفيروسية التي تسبب التهاب الحلق.
               
                خصائص مضادة للالتهابات: يمكن أن يساعد محتوى مضادات الأكسدة الموجودة في التمر الهندي على تقليل الالتهاب والتهيج في الحلق، وبالتالي تخفيف الألم والانزعاج.
               
                تأثير مهدئ: للتمر الهندي تأثير مهدئ على أنسجة الحلق الملتهبة، مما يساعد على تقليل الإحساس بالحرقان والحكة المرتبطة غالبًا بالتهاب الحلق.
               
                كيفية استخدام التمر الهندي لعلاج التهاب الحلق:
               
                محلول الغرغرة بالتمر الهندي: يخلط التمر الهندي المذاب في الماء الدافئ ويستخدم كغسول للفم. تغرغر بهذا المحلول لبضع ثوان قبل بصقه. كرر ذلك عدة مرات يوميًا لتقليل الالتهاب وتسريع عملية الشفاء.
               
                مشروب التمر الهندي الدافئ: يذوب التمر الهندي في الماء الدافئ ويضاف إليه القليل من العسل أو السكر لإضفاء طعم أفضل. اشرب هذا المشروب ببطء لتهدئة التهاب الحلق وتخفيف الأعراض الأخرى.
               
                كمادات الحلق بالتمر الهندي: قم بنقع قطعة من القطن أو قطعة قماش ناعمة في محلول التمر الهندي البارد. ضع هذه الكمادة حول الرقبة والحلق لبضع دقائق لتوفير تأثير مهدئ وتقليل الالتهاب.
               
                تحذير: على الرغم من أن التمر الهندي لديه القدرة كعلاج طبيعي لالتهاب الحلق، فمن المهم اتباع نصيحة أخصائي الرعاية الصحية والتشاور قبل استخدام أي طريقة علاج، خاصة إذا كانت الأعراض شديدة أو مستمرة لفترة طويلة.`,
                "image": gambar5,
                "post": "تم النشر بتاريخ 12/10/2024",
                "kategori": "pengobatan",
                "referensi": "https://www.klikdokter.com/info-sehat/tht/adakah-manfaat-asam-jawa-untuk-sakit-tenggorokan"
            },
            zh: {
                "nama": "喉咙痛的自然疗法，罗望子作为有效传统解毒剂的好处",
                "deskripsi": `喉咙痛是一种常见病症，会导致疼痛、刺激和吞咽困难。 除了传统药物外，许多人还在寻找有助于缓解喉咙痛症状的自然疗法。 罗望子是一种被认为具有药用潜力的天然成分。 在本文中，我们将探讨罗望子作为有效的传统喉咙痛解毒剂的好处以及如何使用它。

                罗望子治疗喉咙痛的好处：
               
                抗菌特性：罗望子含有具有抗菌特性的活性化合物，例如乙酸，可以帮助对抗引起喉咙痛的细菌和病毒感染。
               
                抗炎特性：罗望子中的抗氧化剂含量可以帮助减少喉咙的炎症和刺激，从而缓解疼痛和不适。
               
                舒缓作用：罗望子对发炎的喉咙组织有镇静作用，有助于减轻通常与喉咙痛相关的烧灼感和瘙痒感。
               
                如何使用罗望子治疗喉咙痛：
               
                罗望子漱口液：将溶解的罗望子与温水混合，用作漱口水。 将此溶液漱口几秒钟，然后吐出。 每天重复几次，以减少炎症并加速愈合过程。
               
                温罗望子饮料：将罗望子溶解在温水中，加入少许蜂蜜或糖，味道更好。 慢慢喝这种饮料可以缓解喉咙痛并缓解其他症状。
               
                用罗望子压喉咙：将棉绒或软布浸泡在凉爽的罗望子溶液中。 将这种敷布放在颈部和喉咙周围几分钟，以提供镇静效果并减少炎症。
               
                警告：虽然罗望子有潜力作为喉咙痛的自然疗法，但在使用任何治疗方法之前遵循医疗保健专业人士的建议并咨询很重要，特别是如果您的症状严重或持续很长时间。`,
                "image": gambar5,
                "post": "发表于 12/10/2024",
                "kategori": "pengobatan",
                "referensi": "https://www.klikdokter.com/info-sehat/tht/adakah-manfaat-asam-jawa-untuk-sakit-tenggorokan"
            },
        },
        {
            id: {
                "nama": "Jejak Asam Jawa di Pesisir Navigasi dan Perdagangan Maritim",
                "deskripsi": `
                Pesisir Nusantara memiliki sejarah panjang yang kaya akan jejak perjalanan dan perdagangan maritim. Salah satu unsur yang sangat berpengaruh dalam sejarah ini adalah pohon Asam Jawa (Tamarindus indica). Jejak Asam Jawa di pesisir tidak hanya berupa tumbuhan yang melimpah di sepanjang garis pantai, tetapi juga mencakup peranan pentingnya dalam navigasi dan perdagangan maritim.

                Navigasi Maritim dengan Panduan Asam Jawa
                Sejak zaman kuno, para pelaut Nusantara telah mengandalkan pohon Asam Jawa sebagai panduan navigasi mereka. Asam Jawa dikenal dengan kemampuannya bertahan di lingkungan pesisir, memberikan petunjuk bagi para pelaut akan keberadaan daratan atau bahkan suatu pulau. Daun-daunnya yang lebat dan bentuk tumbuhnya yang khas menjadikan Asam Jawa sebagai referensi penting dalam menentukan arah dan posisi di lautan.
                
                Selain sebagai petunjuk visual, aroma khas yang dihasilkan oleh bunga dan buah Asam Jawa juga dijadikan sebagai petunjuk olfaktif oleh para pelaut tradisional. Dengan mengenali aroma khas ini, pelaut dapat menentukan jarak dan arah ke suatu tempat, memberikan dimensi baru dalam metode navigasi maritim di kawasan pesisir.
                
                Perdagangan Maritim dan Asam Jawa
                Asam Jawa bukan hanya tumbuhan yang memberikan panduan navigasi, tetapi juga memiliki peran signifikan dalam perdagangan maritim. Buah Asam Jawa yang kaya akan asam, memberikan nilai tambah dalam kehidupan sehari-hari masyarakat pesisir. Selain digunakan sebagai bahan masakan, Asam Jawa juga diolah menjadi minuman segar yang khas dan memiliki manfaat kesehatan.
                
                Perdagangan Asam Jawa menjadi salah satu faktor pendorong keberlanjutan perdagangan maritim di masa lalu. Pohon ini ditanam secara luas di pesisir-pesisir Nusantara, menjadi komoditas unggulan yang diperdagangkan antar pulau-pulau. Melalui proses perdagangan ini, terbentuklah jaringan perdagangan maritim yang saling mendukung dan memperkuat hubungan antar komunitas pesisir.
                
                Warisan Budaya dan Pelestarian Asam Jawa
                Dalam konteks sejarah maritim Nusantara, Asam Jawa bukan hanya sekadar tumbuhan, tetapi juga menjadi bagian integral dari warisan budaya. Mitos dan cerita rakyat yang berkaitan dengan Asam Jawa turut membentuk identitas masyarakat pesisir. Pelestarian pohon Asam Jawa dan pengetahuan tentang penggunaannya dalam navigasi dan perdagangan maritim menjadi tanggung jawab bersama untuk menjaga warisan berharga ini.
                
                Pemanfaatan berkelanjutan Asam Jawa juga dapat menjadi salah satu solusi dalam menjaga ekosistem pesisir. Melalui upaya pelestarian dan penanaman kembali Asam Jawa, kita dapat memastikan bahwa jejak berharga ini tetap terjaga dan bermanfaat bagi generasi mendatang.
                
                Kesimpulan
                Jejak Asam Jawa di pesisir Nusantara membuka pintu untuk memahami kedalaman hubungan antara manusia dan lingkungan maritimnya. Sebagai panduan navigasi dan komoditas perdagangan, Asam Jawa memberikan kontribusi besar dalam membentuk peradaban pesisir Nusantara. Dengan memahami dan menjaga jejak ini, kita dapat meresapi kekayaan sejarah dan budaya yang menjadi fondasi kehidupan masyarakat pesisir.
                `,
                "image": gambar18,
                "post": "Diposting pada 12/10/2024",
                "kategori": "sejarah",
                "referensi": "http://rumahbelajar.id/Media/Dokumen/5cff5f5fb646044330d686d0/8305070e7dd15ca1f0b22cc8cb954aa2.pdf"
            },
            en: {
                "nama": "Traces of Tamarind in Coastal Navigation and Maritime Trade",
                "deskripsi": `
                The coast of the archipelago has a long history rich in traces of maritime travel and trade. One element that is very influential in this history is the Tamarind tree (Tamarindus indica). Tamarind's footprint on the coast is not only in the form of abundant vegetation along the coastline, but also includes its important role in navigation and maritime trade.

                Maritime Navigation with Tamarind Guides
                Since ancient times, Indonesian sailors have relied on the Tamarind tree as a guide for their navigation. Tamarind is known for its ability to survive in coastal environments, providing sailors with clues to the whereabouts of land or even an island. Its dense leaves and distinctive growth shape make Tamarind an important reference in determining direction and position in the ocean.
               
                Apart from being a visual clue, the distinctive aroma produced by Tamarind flowers and fruit is also used as an olfactive clue by traditional sailors. By recognizing this distinctive scent, sailors can determine the distance and direction to a place, providing a new dimension to maritime navigation methods in coastal areas.
               
                Maritime and Tamarind Trade
                Tamarind is not only a plant that provides navigational guidance, but also plays a significant role in maritime trade. Tamarind fruit, which is rich in acid, provides added value in the daily lives of coastal communities. Apart from being used as a cooking ingredient, Tamarind is also processed into a unique fresh drink that has health benefits.
               
                Tamarind trade has been one of the factors driving the sustainability of maritime trade in the past. This tree is widely planted on the coasts of the archipelago, becoming a superior commodity traded between islands. Through this trade process, a maritime trade network is formed that supports each other and strengthens relations between coastal communities.
               
                Cultural Heritage and Conservation of Tamarind
                In the context of Indonesian maritime history, Tamarind is not just a plant, but is also an integral part of cultural heritage. Myths and folklore related to Tamarind help shape the identity of coastal communities. The preservation of the Tamarind tree and knowledge of its use in navigation and maritime trade is a shared responsibility to safeguard this valuable heritage.
               
                Sustainable use of Tamarind can also be a solution in protecting coastal ecosystems. Through efforts to preserve and replant Tamarind, we can ensure that this valuable footprint remains preserved and benefits future generations.
               
                Conclusion
                Traces of Tamarind on the coast of the archipelago open the door to understanding the depth of the relationship between humans and their maritime environment. As a navigation guide and trade commodity, Tamarind made a major contribution to shaping the coastal civilization of the archipelago. By understanding and preserving these traces, we can absorb the rich history and culture that is the foundation of the lives of coastal communities.
                `,
                "image": gambar18,
                "post": "Post on 12/10/2024",
                "kategori": "sejarah",
                "referensi": "http://rumahbelajar.id/Media/Dokumen/5cff5f5fb646044330d686d0/8305070e7dd15ca1f0b22cc8cb954aa2.pdf"
            },
            ar: {
                "nama": "آثار التمر الهندي في الملاحة الساحلية والتجارة البحرية",
                "deskripsi": `
                يتمتع ساحل الأرخبيل بتاريخ طويل غني بآثار السفر والتجارة البحرية. أحد العناصر المؤثرة جدًا في هذا التاريخ هو شجرة التمر الهندي (Tamarindus indica). لا تقتصر بصمة التمر الهندي على الساحل على شكل الغطاء النباتي الوفيرة على طول الساحل فحسب، بل تشمل أيضًا دوره المهم في الملاحة والتجارة البحرية.

                الملاحة البحرية مع أدلة التمر الهندي
                منذ العصور القديمة، اعتمد البحارة الإندونيسيون على شجرة التمر الهندي كدليل لملاحتهم. يشتهر التمر الهندي بقدرته على البقاء على قيد الحياة في البيئات الساحلية، مما يوفر للبحارة أدلة حول مكان وجود الأرض أو حتى الجزيرة. أوراقه الكثيفة وشكل نموه المميز يجعل من التمر الهندي مرجعا هاما في تحديد الاتجاه والموقع في المحيط.
               
                بصرف النظر عن كونها دليلًا بصريًا، فإن الرائحة المميزة التي تنتجها زهور وفاكهة التمر الهندي تستخدم أيضًا كدليل شم من قبل البحارة التقليديين. ومن خلال التعرف على هذه الرائحة المميزة، يستطيع البحارة تحديد المسافة والاتجاه إلى مكان ما، مما يوفر بعدًا جديدًا لأساليب الملاحة البحرية في المناطق الساحلية.
               
                التجارة البحرية والتمر الهندي
                التمر الهندي ليس مجرد نبات يوفر التوجيه الملاحي فحسب، بل يلعب أيضًا دورًا مهمًا في التجارة البحرية. توفر فاكهة التمر الهندي الغنية بالحمض قيمة مضافة في الحياة اليومية للمجتمعات الساحلية. وبصرف النظر عن استخدامه كعنصر للطهي، تتم معالجة التمر الهندي أيضًا وتحويله إلى مشروب طازج فريد من نوعه له فوائد صحية.
               
                لقد كانت تجارة التمر الهندي أحد العوامل الدافعة لاستدامة التجارة البحرية في الماضي. وتزرع هذه الشجرة على نطاق واسع على سواحل الأرخبيل، لتصبح سلعة متفوقة يتم تداولها بين الجزر. ومن خلال هذه العملية التجارية، تتشكل شبكة تجارية بحرية تدعم بعضها البعض وتعزز العلاقات بين المجتمعات الساحلية.
               
                التراث الثقافي والحفاظ على التمر الهندي
                في سياق التاريخ البحري الإندونيسي، لا يعد التمر الهندي مجرد نبات، ولكنه أيضًا جزء لا يتجزأ من التراث الثقافي. تساعد الأساطير والفولكلور المتعلق بالتمر الهندي في تشكيل هوية المجتمعات الساحلية. إن الحفاظ على شجرة التمر الهندي ومعرفة استخدامه في الملاحة والتجارة البحرية مسؤولية مشتركة للحفاظ على هذا التراث القيم.
               
                يمكن أن يكون الاستخدام المستدام للتمر الهندي أيضًا حلاً لحماية النظم البيئية الساحلية. ومن خلال الجهود المبذولة للحفاظ على التمر الهندي وإعادة زراعته، يمكننا ضمان بقاء هذه البصمة القيمة محفوظة وتستفيد منها الأجيال القادمة.
               
                خاتمة
                آثار التمر الهندي على سواحل الأرخبيل تفتح الباب لفهم عمق العلاقة بين الإنسان وبيئته البحرية. كدليل ملاحي وسلعة تجارية، ساهم التمر الهندي بشكل كبير في تشكيل الحضارة الساحلية للأرخبيل. ومن خلال فهم هذه الآثار والحفاظ عليها، يمكننا استيعاب التاريخ والثقافة الغنية التي تشكل أساس حياة المجتمعات الساحلية.
                `,
                "image": gambar18,
                "post": "م النشر بتاريخ 12/10/2024",
                "kategori": "sejarah",
                "referensi": "http://rumahbelajar.id/Media/Dokumen/5cff5f5fb646044330d686d0/8305070e7dd15ca1f0b22cc8cb954aa2.pdf"
            },
            zh: {
                "nama": "罗望子在沿海航行和海上贸易中的踪迹",
                "deskripsi": `
                群岛海岸有着悠久的历史，有着丰富的海上旅行和贸易的痕迹。 在这段历史中非常有影响力的一个元素是罗望子树（Tamarindus indica）。 罗望子在海岸的足迹不仅体现在海岸线丰富的植被上，还包括它在航海和海上贸易中的重要作用。

                罗望子指南的海上导航
                自古以来，印尼水手就依靠罗望子树作为航海指南。 罗望子以其在沿海环境中生存的能力而闻名，为水手提供了陆地甚至岛屿位置的线索。 其茂密的叶子和独特的生长形状使罗望子成为确定海洋方向和位置的重要参考。
               
                除了作为视觉线索外，罗望子花和水果产生的独特香气也被传统水手用作嗅觉线索。 通过识别这种独特的气味，水手可以确定到某个地点的距离和方向，为沿海地区的海上导航方法提供了新的维度。
               
                海事和罗望子贸易
                罗望子不仅是提供航海指导的植物，而且在海上贸易中发挥着重要作用。 罗望子果实富含酸，为沿海社区的日常生活提供附加值。 除了用作烹饪原料外，罗望子还被加工成一种独特的新鲜饮料，对健康有益。
               
                过去，罗望子贸易一直是推动海上贸易可持续性的因素之一。 这种树广泛种植在群岛海岸，成为岛屿之间贸易的优质商品。 通过这一贸易过程，形成了一个相互支持并加强沿海社区之间关系的海上贸易网络。
               
                罗望子的文化遗产和保护
                在印度尼西亚航海历史的背景下，罗望子不仅仅是一种植物，也是文化遗产的一个组成部分。 与罗望子有关的神话和民间传说有助于塑造沿海社区的身份。 保护罗望子树及其在航海和海上贸易中的用途的知识是保护这一宝贵遗产的共同责任。
               
                罗望子的可持续利用也可以成为保护沿海生态系统的解决方案。 通过努力保护和重新种植罗望子，我们可以确保这一宝贵的足迹得以保存并造福子孙后代。
               
                结论
                群岛海岸上罗望子的踪迹为了解人类与其海洋环境之间的深度关系打开了大门。 作为航海指南和贸易商品，罗望子为塑造群岛沿海文明做出了重大贡献。 通过了解和保护这些痕迹，我们可以吸收作为沿海社区生活基础的丰富历史和文化。
                `,
                "image": gambar18,
                "post": "发表于 12/10/2024",
                "kategori": "sejarah",
                "referensi": "http://rumahbelajar.id/Media/Dokumen/5cff5f5fb646044330d686d0/8305070e7dd15ca1f0b22cc8cb954aa2.pdf"
            },
        },
        {
            id: {
                    "nama": "Asam Jawa dalam Sastra Nusantara Cerita dan Puisi yang Diilhami",
                    "deskripsi": `
                    Asam Jawa, selain menjadi bagian tak terpisahkan dari kehidupan sehari-hari masyarakat Nusantara, juga tampil sebagai elemen yang kaya inspirasi dalam sastra Nusantara. Dalam bentuk cerita dan puisi, keberadaan Asam Jawa seringkali dijadikan metafora, simbol, atau bahkan menginspirasi plot yang indah. Artikel ini akan mengungkap sebagian kecil dari kekayaan sastra Nusantara yang diilhami oleh keajaiban Asam Jawa.

                    Asam Jawa dalam Cerita Rakyat
                    Cerita rakyat Nusantara kaya akan dongeng dan legenda yang melibatkan pohon Asam Jawa. Salah satu cerita yang terkenal adalah "Legenda Asam Jawa" yang berasal dari Jawa Tengah. Dalam cerita ini, Asam Jawa dianggap sebagai simbol kebijaksanaan dan kesabaran. Karakter yang diilhami oleh Asam Jawa seringkali menjadi pahlawan atau tokoh yang mengajarkan nilai-nilai kebaikan dan kearifan.
                    
                    Puisi: Pesona dan Metafora Asam Jawa
                    Dalam dunia puisi, Asam Jawa menjadi sumber inspirasi bagi banyak penyair Nusantara. Keindahan pohon yang menjulang dengan daun lebatnya mengilhami banyak puisi yang menggambarkan pesona alam Nusantara. Beberapa puisi menciptakan metafora Asam Jawa sebagai lambang cinta, dengan buahnya yang asam menyimbolkan pahitnya perjuangan cinta yang indah.
                    
                    Asam Jawa dalam Kritik Sosial
                    Asam Jawa juga sering muncul dalam cerita dan puisi sebagai alat kritik sosial. Kehidupan masyarakat pesisir yang erat kaitannya dengan Asam Jawa menjadi bahan refleksi bagi banyak penulis. Pohon ini sering dijadikan cermin untuk menyoroti isu-isu seperti keberlanjutan lingkungan, perubahan sosial, dan tantangan ekonomi di masyarakat pesisir.
                    
                    Asam Jawa Sebagai Metafora Identitas Lokal
                    Dalam karya sastra, Asam Jawa juga digunakan sebagai simbol identitas lokal. Beberapa karya menggambarkan keberagaman dan kekayaan budaya Nusantara dengan mengaitkan pohon Asam Jawa dengan beragam tradisi dan kepercayaan yang ada di berbagai daerah.
                    
                    Penciptaan Karya Baru: Menggugah Imajinasi Melalui Asam Jawa
                    Bukan hanya sebagai objek cerita dan puisi, Asam Jawa juga menjadi sumber inspirasi bagi penulis kontemporer. Sastrawan modern menggunakan keunikan Asam Jawa sebagai titik tolak untuk menciptakan karya-karya baru yang mencerminkan realitas sosial dan budaya masa kini.
                    
                    Kesimpulan
                    Asam Jawa tidak hanya tumbuh subur di tanah Nusantara, tetapi juga menanamkan akarnya dalam sastra Nusantara. Melalui cerita rakyat yang timeless, puisi yang memukau, dan kritik sosial yang tajam, Asam Jawa menjadi elemen yang tak terpisahkan dari identitas sastra Nusantara. Dengan terus menggali dan mengapresiasi karya-karya ini, kita dapat merayakan keajaiban budaya yang tersembunyi di balik frondositas daun dan keasaman buah Asam Jawa.
                    `,
                    "image": gambar6,
                    "post": "Diposting pada 12/10/2024",
                    "kategori": "sejarah",
                    "referensi": "https://fbsb.uny.ac.id/sites/fbsb.uny.ac.id/files/PROCEEDINGS%20SEMINAR%20INTERNASIONAL%20HISKI%202016_BAGIAN%202.pdf"
                },
            en: {
                    "nama": "Tamarind in Indonesian Literature Inspired Stories and Poetry",
                    "deskripsi": `
                    Tamarind, apart from being an inseparable part of the daily lives of Indonesian people, also appears as an element rich in inspiration in Indonesian literature. In the form of stories and poetry, the presence of Tamarind is often used as a metaphor, symbol, or even inspires beautiful plots. This article will reveal a small part of the richness of Indonesian literature inspired by the magic of Tamarind.

                    Tamarind in Folklore
                    Indonesian folklore is rich in fairy tales and legends involving the Tamarind tree. One of the famous stories is the "Legend of Tamarind" which originates from Central Java. In this story, Tamarind is considered a symbol of wisdom and patience. Characters inspired by Tamarind are often heroes or figures who teach the values of goodness and wisdom.
                   
                    Poetry: The Charm and Metaphor of Tamarind
                    In the world of poetry, Tamarind is a source of inspiration for many Indonesian poets. The beauty of the towering trees with their dense leaves inspired many poems describing the natural charm of the archipelago. Several poems create the metaphor of Tamarind as a symbol of love, with the sour fruit symbolizing the bitterness of the beautiful struggle of love.
                   
                    Tamarind in Social Criticism
                    Tamarind also often appears in stories and poetry as a tool for social criticism. The lives of coastal communities which are closely related to Tamarind have become material for reflection for many writers. This tree is often used as a mirror to highlight issues such as environmental sustainability, social change and economic challenges in coastal communities.
                   
                    Tamarind as a Metaphor for Local Identity
                    In literary works, Tamarind is also used as a symbol of local identity. Several works depict the diversity and richness of Indonesian culture by linking the Tamarind tree with various traditions and beliefs that exist in various regions.
                   
                    Creating New Works: Evoking Imagination Through Tamarind
                    Not only as an object for stories and poetry, Tamarind is also a source of inspiration for contemporary writers. Modern writers use the uniqueness of Tamarind as a starting point to create new works that reflect today's social and cultural realities.
                   
                    Conclusion
                    Tamarind not only grows abundantly in the land of the archipelago, but also plants its roots in Indonesian literature. Through timeless folklore, fascinating poetry and sharp social criticism, Tamarind has become an inseparable element of the Indonesian literary identity. By continuing to explore and appreciate these works, we can celebrate the cultural wonders hidden behind the frondosity of the leaves and the acidity of the Tamarind fruit.
                    `,
                    "image": gambar6,
                    "post": "Post on 12/10/2024",
                    "kategori": "sejarah",
                    "referensi": "https://fbsb.uny.ac.id/sites/fbsb.uny.ac.id/files/PROCEEDINGS%20SEMINAR%20INTERNASIONAL%20HISKI%202016_BAGIAN%202.pdf"
            },
            ar: {
                "nama": "التمر الهندي في الأدب الإندونيسي مستوحى من القصص والشعر",
                "deskripsi": `
                التمر الهندي، بصرف النظر عن كونه جزءًا لا يتجزأ من الحياة اليومية للشعب الإندونيسي، يظهر أيضًا كعنصر غني بالإلهام في الأدب الإندونيسي. في شكل قصص وشعر، غالبًا ما يُستخدم وجود التمر الهندي كاستعارة أو رمز أو حتى لإلهام حبكات جميلة. ستكشف هذه المقالة عن جزء بسيط من ثراء الأدب الإندونيسي المستوحى من سحر التمر الهندي.

                التمر الهندي في التراث الشعبي
                الفولكلور الإندونيسي غني بالحكايات والأساطير المتعلقة بشجرة التمر الهندي. إحدى القصص الشهيرة هي "أسطورة التمر الهندي" التي يعود أصلها إلى جاوة الوسطى. وفي هذه القصة يعتبر التمر الهندي رمزا للحكمة والصبر. الشخصيات المستوحاة من التمر الهندي غالبًا ما تكون أبطالًا أو شخصيات تعلم قيم الخير والحكمة.
               
                الشعر: سحر واستعارة التمر الهندي
                وفي عالم الشعر، يعتبر التمر الهندي مصدر إلهام للعديد من الشعراء الإندونيسيين. وقد ألهم جمال الأشجار الشاهقة بأوراقها الكثيفة العديد من القصائد التي تصف السحر الطبيعي للأرخبيل. تخلق العديد من القصائد استعارة التمر الهندي كرمز للحب، حيث ترمز الفاكهة الحامضة إلى مرارة صراع الحب الجميل.
               
                التمر الهندي في النقد الاجتماعي
                غالبًا ما يظهر التمر الهندي أيضًا في القصص والشعر كأداة للنقد الاجتماعي. أصبحت حياة المجتمعات الساحلية المرتبطة ارتباطًا وثيقًا بالتمر الهندي مادة للتفكير للعديد من الكتاب. غالبًا ما تُستخدم هذه الشجرة كمرآة لتسليط الضوء على قضايا مثل الاستدامة البيئية والتغير الاجتماعي والتحديات الاقتصادية في المجتمعات الساحلية.
               
                التمر الهندي كاستعارة للهوية المحلية
                وفي الأعمال الأدبية، يستخدم التمر الهندي أيضًا كرمز للهوية المحلية. تصور العديد من الأعمال تنوع الثقافة الإندونيسية وغناها من خلال ربط شجرة التمر الهندي بمختلف التقاليد والمعتقدات الموجودة في مختلف المناطق.
               
                خلق أعمال جديدة: استحضار الخيال من خلال التمر الهندي
                ليس فقط كموضوع للقصص والشعر، بل يعد التمر الهندي أيضًا مصدر إلهام للكتاب المعاصرين. يستخدم الكتاب المعاصرون تفرد التمر الهندي كنقطة انطلاق لإنشاء أعمال جديدة تعكس الحقائق الاجتماعية والثقافية اليوم.
               
                خاتمة
                لا ينمو التمر الهندي بكثرة في أرض الأرخبيل فحسب، بل يزرع جذوره أيضًا في الأدب الإندونيسي. من خلال الفولكلور الخالد والشعر الرائع والنقد الاجتماعي الحاد، أصبح التمر الهندي عنصرًا لا ينفصل عن الهوية الأدبية الإندونيسية. من خلال الاستمرار في استكشاف وتقدير هذه الأعمال، يمكننا أن نحتفل بالعجائب الثقافية المخبأة وراء رقة الأوراق وحموضة فاكهة التمر الهندي.
                `,
                "image": gambar6,
                "post": "تم النشر بتاريخ -12/10/2024",
                "kategori": "sejarah",
                "referensi": "https://fbsb.uny.ac.id/sites/fbsb.uny.ac.id/files/PROCEEDINGS%20SEMINAR%20INTERNASIONAL%20HISKI%202016_BAGIAN%202.pdf"
            },
            zh: {
                "nama": "印度尼西亚文学中的罗望子启发故事和诗歌",
                "deskripsi": `
                罗望子除了是印尼人民日常生活中不可分割的一部分之外，也是印尼文学中富有灵感的元素。 在故事和诗歌的形式中，罗望子的存在经常被用作隐喻、象征，甚至激发美丽的情节。 本文将揭示受罗望子魔力启发的印尼文学丰富性的一小部分。

                民间传说中的罗望子
                印度尼西亚民间传说中有丰富的与罗望子树有关的童话故事和传说。 著名的故事之一是起源于中爪哇的“罗望子传说”。 在这个故事中，罗望子被认为是智慧和耐心的象征。 受罗望子启发的人物通常是教导善良和智慧价值观的英雄或人物。
               
                诗歌：罗望子的魅力与隐喻
                在诗歌世界中，罗望子是许多印度尼西亚诗人的灵感源泉。 高耸的树木和茂密的叶子的美丽激发了许多描述群岛自然魅力的诗歌。 有几首诗将罗望子比喻为爱情的象征，酸酸的果实象征着爱情美丽斗争的辛酸。
               
                社会批评中的罗望子
                罗望子也经常出现在故事​​和诗歌中，作为社会批评的工具。 与罗望子密切相关的沿海社区的生活成为许多作家反思的素材。 这棵树经常被用作一面镜子，突出沿海社区的环境可持续性、社会变革和经济挑战等问题。
               
                罗望子作为当地身份的隐喻
                在文学作品中，罗望子也被用作当地身份的象征。 一些作品通过将罗望子树与不同地区存在的各种传统和信仰联系起来，描绘了印度尼西亚文化的多样性和丰富性。
               
                创作新作品：通过罗望子激发想象力
                罗望子不仅是故事和诗歌的对象，也是当代作家的灵感来源。 现代作家以罗望子的独特性为起点，创作反映当今社会和文化现实的新作品。
               
                结论
                罗望子不仅在群岛土地上大量生长，而且还植根于印度尼西亚文学中。 通过永恒的民间传说、迷人的诗歌和尖锐的社会批评，罗望子已成为印度尼西亚文学身份不可分割的元素。 通过继续探索和欣赏这些作品，我们可以庆祝隐藏在罗望子叶子的叶子和酸度的罗望子果实背后的文化奇迹。
                `,
                "image": gambar6,
                "post": "发表于 12/10/2024",
                "kategori": "sejarah",
                "referensi": "https://fbsb.uny.ac.id/sites/fbsb.uny.ac.id/files/PROCEEDINGS%20SEMINAR%20INTERNASIONAL%20HISKI%202016_BAGIAN%202.pdf"
            },
        },
        {
            id: {
                "nama": "Asam Jawa dalam Mitologi Nusantara Kisah-kisah Mistis dari Tanaman Ajaib",
                "deskripsi": `
                Mitologi Nusantara memberikan tempat istimewa bagi Asam Jawa, pohon yang bukan hanya tumbuh subur di pesisir-pesisir pantai, tetapi juga menyimpan berbagai kisah mistis dan legenda yang melekat padanya. Dalam artikel ini, kita akan menyelami dunia magis dan mistis melalui cerita-cerita yang melibatkan tanaman ajaib ini.

                Asam Jawa sebagai Penjaga Batas Alam Gaib
                Dalam banyak mitos Nusantara, Asam Jawa sering dianggap sebagai penjaga batas antara alam nyata dan alam gaib. Dikisahkan bahwa pohon ini tumbuh di tempat-tempat tertentu sebagai pembatas antara dunia manusia dan dunia roh. Beberapa mitos mengatakan bahwa pohon Asam Jawa memancarkan energi mistis yang melindungi dan menjaga keberadaan makhluk halus agar tidak mengganggu manusia.
                
                Asam Jawa sebagai Tempat Bertemu Dengan Makhluk Gaib
                Ada pula cerita-cerita yang menggambarkan Asam Jawa sebagai tempat di mana manusia dapat berinteraksi dengan makhluk gaib. Dalam mitologi ini, dikatakan bahwa malam-malam tertentu, pohon Asam Jawa menjadi tempat bertemunya manusia dengan roh-roh leluhur atau makhluk-makhluk halus. Pohon ini dianggap sebagai "pintu gerbang" antara dua dunia yang berbeda.
                
                Asam Jawa dan Keajaiban Pengobatan
                Dalam beberapa mitos, Asam Jawa dianggap memiliki kekuatan penyembuhan yang luar biasa. Dikisahkan bahwa daun, bunga, atau buah Asam Jawa dapat digunakan sebagai ramuan penyembuh berbagai penyakit fisik dan spiritual. Beberapa dukun atau perajurit bahkan mengonsumsi bagian-bagian tertentu dari pohon ini untuk mendapatkan kekuatan ekstra dalam menjalani kehidupan sehari-hari atau melawan kekuatan jahat.
                
                Asam Jawa dan Ritual Keagamaan
                Asam Jawa juga sering kali menjadi bagian dari ritual keagamaan dalam masyarakat Nusantara. Dalam beberapa tradisi, pohon ini dianggap sakral dan digunakan dalam upacara-upacara keagamaan sebagai simbol spiritualitas dan perlindungan. Beberapa masyarakat juga meyakini bahwa Asam Jawa memiliki hubungan erat dengan dewa atau roh-roh suci yang harus dihormati dan diberi persembahan.
                
                Asam Jawa sebagai Pemberi Petunjuk
                Dalam mitologi Nusantara, Asam Jawa juga dianggap sebagai pemberi petunjuk atau ramalan. Dikatakan bahwa melalui pertanda-pertanda dari bentuk daun, bunga, atau buah Asam Jawa, manusia dapat memahami nasib atau mendapatkan petunjuk tentang keputusan yang harus diambil.
                
                Kesimpulan
                Asam Jawa bukan hanya tumbuhan biasa di Nusantara; ia membawa keberadaannya ke dalam alam mistis dan magis melalui mitologi yang begitu kaya. Kisah-kisah ini tidak hanya menghibur, tetapi juga mencerminkan hubungan yang dalam antara manusia dan alam di Nusantara. Dengan menjelajahi mitologi Asam Jawa, kita dapat lebih memahami warisan budaya dan spiritualitas yang telah mengakar di dalam masyarakat Nusantara selama berabad-abad.
                `,
                "image": gambar19,
                "post": "Diposting pada 12/10/2024",
                "kategori": "sejarah",
                "referensi": "https://www.suaramerdeka.com/gaya-hidup/0410127130/mitos-mistis-dan-manfaat-pohon-asam-jawa-yang-menjadi-bagian-perjalanan-kota-semarang"
            },
            en: {
                "nama": "Tamarind in Indonesian Mythology Mystical Stories from Magical Plants",
                "deskripsi": `
                Archipelago mythology gives a special place to Tamarind, a tree that not only grows abundantly on the coasts, but also has various mystical stories and legends attached to it. In this article, we will dive into the magical and mystical world through stories involving this magical plant.

                Tamarind as a Guardian of the Boundaries of the Magical Realm
                In many Indonesian myths, Tamarind is often seen as the guardian of the boundary between the real world and the supernatural. It is said that this tree grows in certain places as a barrier between the human world and the spirit world. Some myths say that the Tamarind tree emits mystical energy that protects and keeps spirits from disturbing humans.
               
                Tamarind as a Place to Meet Magical Creatures
                There are also stories that describe Tamarind as a place where humans can interact with supernatural beings. In this mythology, it is said that on certain nights, the Tamarind tree becomes a meeting place for humans with ancestral spirits or spirits. This tree is considered a "gateway" between two different worlds.
               
                Tamarind and the Miracle of Medicine
                In some myths, Tamarind is considered to have extraordinary healing powers. It is said that the leaves, flowers or fruit of Tamarind can be used as a healing potion for various physical and spiritual ailments. Some shamans or warriors even consume certain parts of this tree to gain extra strength in carrying out daily life or fighting evil forces.
               
                Tamarind and Religious Rituals
                Tamarind is also often part of religious rituals in Indonesian society. In some traditions, this tree is considered sacred and is used in religious ceremonies as a symbol of spirituality and protection. Some people also believe that Tamarind has a close relationship with gods or holy spirits who must be respected and offered.
               
                Tamarind as a Guide
                In Indonesian mythology, Tamarind is also considered to give guidance or prophecy. It is said that through signs from the shape of Tamarind leaves, flowers or fruit, humans can understand fate or get clues about decisions that must be taken.
               
                Conclusion
                Tamarind is not just a common plant in the archipelago; he brings his existence into a mystical and magical realm through a rich mythology. These stories are not only entertaining, but also reflect the deep connection between humans and nature in the archipelago. By exploring the mythology of Tamarind, we can better understand the cultural heritage and spirituality that has been rooted in Indonesian society for centuries.
                `,
                "image": gambar19,
                "post": "Post on 12/10/2024",
                "kategori": "sejarah",
                "referensi": "https://www.suaramerdeka.com/gaya-hidup/0410127130/mitos-mistis-dan-manfaat-pohon-asam-jawa-yang-menjadi-bagian-perjalanan-kota-semarang"
            },
            ar: {
                    "nama": "التمر الهندي في الأساطير الإندونيسية: قصص صوفية من النباتات السحرية",
                    "deskripsi": `
                    تعطي أساطير الأرخبيل مكانًا خاصًا لشجرة التمر الهندي، وهي شجرة لا تنمو بكثرة على السواحل فحسب، بل لها أيضًا العديد من القصص والأساطير الغامضة المرتبطة بها. وفي هذا المقال سنغوص في عالم السحر والغموض من خلال قصص تحكي عن هذا النبات السحري.

                    التمر الهندي كحارس لحدود العالم السحري
                    في العديد من الأساطير الإندونيسية، غالبًا ما يُنظر إلى التمر الهندي على أنه حارس الحدود بين العالم الحقيقي والعالم الخارق. ويقال أن هذه الشجرة تنمو في أماكن معينة لتكون حاجزاً بين عالم الإنسان وعالم الأرواح. تقول بعض الأساطير أن شجرة التمر الهندي تبعث طاقة غامضة تحمي الأرواح وتمنعها من إزعاج البشر.
                   
                    التمر الهندي كمكان للقاء المخلوقات السحرية
                    هناك أيضًا قصص تصف التمر الهندي بأنه مكان يمكن للبشر فيه التفاعل مع كائنات خارقة للطبيعة. ويقال في هذه الأسطورة أنه في ليالي معينة تصبح شجرة التمر الهندي ملتقى للبشر بأرواح أو أرواح الأجداد. تعتبر هذه الشجرة "بوابة" بين عالمين مختلفين.
                   
                    التمر الهندي ومعجزة الطب
                    في بعض الأساطير، يعتبر التمر الهندي يتمتع بقدرات شفاء غير عادية. يقال أن أوراق وأزهار وثمر التمر الهندي يمكن استخدامها كجرعة شفاء لمختلف الأمراض الجسدية والروحية. حتى أن بعض الشامان أو المحاربين يستهلكون أجزاء معينة من هذه الشجرة لاكتساب قوة إضافية في القيام بالحياة اليومية أو محاربة قوى الشر.
                   
                    التمر الهندي والطقوس الدينية
                    غالبًا ما يكون التمر الهندي أيضًا جزءًا من الطقوس الدينية في المجتمع الإندونيسي. في بعض التقاليد، تعتبر هذه الشجرة مقدسة وتستخدم في الاحتفالات الدينية كرمز للروحانية والحماية. ويعتقد بعض الناس أيضًا أن التمر الهندي له علاقة وثيقة بالآلهة أو الأرواح المقدسة التي يجب احترامها وتقديمها.
                   
                    التمر الهندي كدليل
                    في الأساطير الإندونيسية، يعتبر التمر الهندي أيضًا بمثابة إعطاء التوجيه أو النبوة. ويقال أنه من خلال الإشارات التي تظهر على شكل أوراق أو زهور أو ثمار التمر الهندي، يستطيع الإنسان فهم القدر أو الحصول على أدلة حول القرارات التي يجب اتخاذها.
                   
                    خاتمة
                    التمر الهندي ليس مجرد نبات شائع في الأرخبيل؛ إنه ينقل وجوده إلى عالم غامض وسحري من خلال أساطير غنية. هذه القصص ليست مسلية فحسب، بل تعكس أيضًا العلاقة العميقة بين البشر والطبيعة في الأرخبيل. ومن خلال استكشاف أساطير التمر الهندي، يمكننا أن نفهم بشكل أفضل التراث الثقافي والروحانيات المتجذرة في المجتمع الإندونيسي منذ قرون.
                    `,
                    "image": gambar19,
                    "post": "تم النشر بتاريخ 12/10/2024",
                    "kategori": "sejarah",
                    "referensi": "https://www.suaramerdeka.com/gaya-hidup/0410127130/mitos-mistis-dan-manfaat-pohon-asam-jawa-yang-menjadi-bagian-perjalanan-kota-semarang"
            },
            zh: {
                "nama": "印尼神话中的罗望子 神奇植物的神秘故事",
                "deskripsi": `
                群岛神话赋予罗望子以特殊的地位，这种树不仅大量生长在海岸上，而且还有各种神秘的故事和传说。 在这篇文章中，我们将通过涉及这种神奇植物的故事，深入了解神奇而神秘的世界。

                罗望子作为魔法领域边界的守护者
                在许多印度尼西亚神话中，罗望子经常被视为现实世界与超自然之间边界的守护者。 据说，这种树生长在某些地方，是人界与灵界之间的屏障。 一些神话称罗望子树会散发出神秘的能量，可以保护并防止灵魂骚扰人类。
               
                罗望子是邂逅神奇生物的地方
                还有一些故事将罗望子描述为人类可以与超自然生物互动的地方。 在这个神话中，据说在某些夜晚，罗望子树成为人类与祖先灵魂或灵魂的聚会场所。 这棵树被认为是两个不同世界之间的“门户”。
               
                罗望子和医学奇迹
                在一些神话中，罗望子被认为具有非凡的治疗能力。 据说罗望子的叶子、花或果实可以用作治疗各种身体和精神疾病的药剂。 一些萨满或战士甚至会食用这棵树的某些部分来获得额外的力量来进行日常生活或对抗邪恶势力。
               
                罗望子和宗教仪式
                罗望子也经常是印度尼西亚社会宗教仪式的一部分。 在一些传统中，这棵树被认为是神圣的，并在宗教仪式中用作灵性和保护的象征。 有些人还认为罗望子与神或圣灵有密切的关系，必须受到尊重和供奉。
               
                罗望子作为指南
                在印度尼西亚神话中，罗望子也被认为可以提供指导或预言。 据说，通过罗望子叶、花或果实形状的迹象，人类可以了解命运或获得必须做出决定的线索。
               
                结论
                罗望子不仅是群岛上常见的植物，也是群岛上常见的植物。 他通过丰富的神话将自己的存在带入了一个神秘而神奇的境界。 这些故事不仅有趣，还体现了群岛上人与自然的深厚联系。 通过探索罗望子的神话，我们可以更好地了解植根于印度尼西亚社会几个世纪的文化遗产和精神。
                `,
                "image": gambar19,
                "post": "发表于 12/10/2024",
                "kategori": "sejarah",
                "referensi": "https://www.suaramerdeka.com/gaya-hidup/0410127130/mitos-mistis-dan-manfaat-pohon-asam-jawa-yang-menjadi-bagian-perjalanan-kota-semarang"
            },
        },
        {
            id: {
                    "nama": "Rahasia Penyembuhan Batuk Berdahak, Manfaat Asam Jawa sebagai Pengobatan Alami yang Efektif",
                    "deskripsi": `Batuk berdahak adalah masalah yang umum terjadi dan bisa sangat mengganggu aktivitas sehari-hari. Meskipun ada banyak obat batuk di pasaran, pengobatan alami seringkali juga menjadi pilihan bagi banyak orang. Salah satu bahan alami yang telah digunakan secara tradisional untuk mengatasi batuk berdahak adalah asam jawa. Dalam artikel ini, kita akan mengeksplorasi manfaat asam jawa sebagai pengobatan alami yang efektif untuk batuk berdahak dan bagaimana cara penggunaannya.

                    Manfaat Asam Jawa dalam Pengobatan Batuk Berdahak:
                    
                    Melonggarkan Dahak: Asam jawa memiliki sifat ekspektoran alami yang dapat membantu melonggarkan dahak di saluran pernapasan, sehingga memudahkan untuk dikeluarkan melalui batuk.
                    
                    Sifat Antimikroba: Kandungan asam dalam asam jawa memiliki sifat antimikroba yang dapat membantu membunuh bakteri dan virus penyebab infeksi saluran pernapasan yang menyebabkan batuk berdahak.
                    
                    Sifat Antiinflamasi: Asam jawa juga memiliki sifat antiinflamasi yang dapat membantu mengurangi peradangan di saluran pernapasan, yang seringkali menjadi penyebab dari iritasi dan batuk yang persisten.
                    
                    Cara Menggunakan Asam Jawa untuk Pengobatan Batuk Berdahak:
                    
                    Minuman Hangat Asam Jawa: Larutkan asam jawa dalam air hangat dan tambahkan sedikit madu atau gula untuk memberikan rasa yang lebih enak. Minum minuman ini secara perlahan untuk meredakan iritasi tenggorokan dan membantu mengencerkan dahak.
                    
                    Kompres Dada dengan Asam Jawa: Rendam kain handuk kecil dalam larutan asam jawa yang hangat dan letakkan di dada selama beberapa menit. Ini dapat membantu mengurangi peradangan di saluran pernapasan dan meredakan batuk berdahak.
                    
                    Gargle dengan Larutan Asam Jawa: Campurkan asam jawa yang sudah larut dalam air hangat dan gunakan sebagai larutan kumur. Gargle larutan ini selama beberapa detik untuk membersihkan tenggorokan dan mengurangi iritasi yang menyebabkan batuk.
                    
                    Peringatan: Meskipun asam jawa memiliki potensi sebagai pengobatan alami untuk batuk berdahak, konsultasikan dengan dokter atau profesional kesehatan terlebih dahulu sebelum menggunakan metode pengobatan apa pun, terutama jika gejala batuk berdahak Anda parah atau berlangsung untuk waktu yang lama.`,
                    "image": gambar7,
                    "post": "Diposting pada 12/10/2024",
                    "kategori": "pengobatan",
                    "referensi": "https://health.grid.id/read/352882602/gunakan-asam-jawa-untuk-atasi-batuk-membandel-begini-caranya?page=all"
                },
            en: {
                    "nama": "The Secret to Healing a Phlegmy Cough, Benefits of Tamarind as an Effective Natural Treatment",
                    "deskripsi": `Coughing up phlegm is a common problem and can really interfere with daily activities. Although there are many cough medicines on the market, natural remedies are often an option for many people. One of the natural ingredients that has been used traditionally to treat coughs with phlegm is tamarind. In this article, we will explore the benefits of tamarind as an effective natural treatment for coughs with phlegm and how to use it.

                    Benefits of Tamarind in the Treatment of Phlegmy Cough:
                   
                    Loosens Phlegm: Tamarind has natural expectorant properties that can help loosen phlegm in the respiratory tract, making it easier to expel through coughing.
                   
                    Antimicrobial Properties: The acid content in tamarind has antimicrobial properties which can help kill bacteria and viruses that cause respiratory tract infections which cause coughing up phlegm.
                   
                    Anti-Inflammatory Properties: Tamarind also has anti-inflammatory properties that can help reduce inflammation in the respiratory tract, which is often the cause of irritation and persistent coughing.
                   
                    How to Use Tamarind to Treat Phlegmy Coughs:
                   
                    Warm Tamarind Drink: Dissolve tamarind in warm water and add a little honey or sugar to give it a better taste. Drink this drink slowly to relieve throat irritation and help thin phlegm.
                   
                    Chest Compress with Tamarind: Soak a small towel in warm tamarind solution and place it on the chest for a few minutes. This can help reduce inflammation in the respiratory tract and relieve coughing up phlegm.
                   
                    Gargle with Tamarind Solution: Mix dissolved tamarind in warm water and use as a mouth rinse. Gargle this solution for a few seconds to clear the throat and reduce irritation that causes coughing.
                   
                    Warning: Although tamarind has potential as a natural treatment for cough with phlegm, consult a doctor or health professional first before using any treatment method, especially if your cough with phlegm symptoms are severe or last for a long time.`,
                    "image": gambar7,
                    "post": "Post on 12/10/2024",
                    "kategori": "pengobatan",
                    "referensi": "https://health.grid.id/read/352882602/gunakan-asam-jawa-untuk-atasi-batuk-membandel-begini-caranya?page=all"
            },
            ar: {
                "nama": "سر شفاء السعال البلغم، فوائد التمر الهندي كعلاج طبيعي فعال",
                "deskripsi": `يعد سعال البلغم مشكلة شائعة ويمكن أن يتداخل مع الأنشطة اليومية. على الرغم من وجود العديد من أدوية السعال في السوق، إلا أن العلاجات الطبيعية غالبًا ما تكون خيارًا لكثير من الأشخاص. أحد المكونات الطبيعية التي تم استخدامها تقليديًا لعلاج السعال المصحوب بالبلغم هو التمر الهندي. وفي هذا المقال سنتعرف على فوائد التمر الهندي كعلاج طبيعي فعال للسعال المصحوب بالبلغم وكيفية استخدامه.

                فوائد التمر الهندي في علاج السعال البلغمي:
               
                يخفف البلغم: يمتلك التمر الهندي خصائص مقشعة طبيعية يمكن أن تساعد في تخفيف البلغم في الجهاز التنفسي، مما يسهل طرده عن طريق السعال.
               
                خصائص مضادة للميكروبات: يحتوي التمر الهندي على خصائص مضادة للميكروبات يمكن أن تساعد في قتل البكتيريا والفيروسات التي تسبب التهابات الجهاز التنفسي التي تسبب سعال البلغم.
               
                خصائص مضادة للالتهابات: يتمتع التمر الهندي أيضًا بخصائص مضادة للالتهابات يمكن أن تساعد في تقليل الالتهاب في الجهاز التنفسي، والذي غالبًا ما يكون سببًا للتهيج والسعال المستمر.
               
                كيفية استخدام التمر الهندي لعلاج السعال البلغم:
               
                مشروب التمر الهندي الدافئ: يذوب التمر الهندي في الماء الدافئ ويضاف إليه القليل من العسل أو السكر لإضفاء طعم أفضل. اشرب هذا المشروب ببطء لتخفيف تهيج الحلق والمساعدة في تخفيف البلغم.
               
                كمادات الصدر بالتمر الهندي: قم بنقع منشفة صغيرة في محلول التمر الهندي الدافئ وضعها على الصدر لبضع دقائق. يمكن أن يساعد ذلك في تقليل الالتهاب في الجهاز التنفسي وتخفيف السعال والبلغم.
               
                الغرغرة بمحلول التمر الهندي: يخلط التمر الهندي المذاب في الماء الدافئ ويستخدم كغسول للفم. قم بالغرغرة بهذا المحلول لبضع ثوان لتنظيف الحلق وتقليل التهيج الذي يسبب السعال.
               
                تحذير: على الرغم من أن التمر الهندي لديه القدرة كعلاج طبيعي للسعال المصحوب بالبلغم، استشر الطبيب أو أخصائي الصحة أولاً قبل استخدام أي طريقة علاجية، خاصة إذا كانت أعراض السعال المصحوب بالبلغم شديدة أو تستمر لفترة طويلة.`,
                "image": gambar7,
                "post": "تم النشر بتاريخ 12/10/2024",
                "kategori": "pengobatan",
                "referensi": "https://health.grid.id/read/352882602/gunakan-asam-jawa-untuk-atasi-batuk-membandel-begini-caranya?page=all"
            },
            zh: {
                "nama": "治疗痰咳的秘诀，罗望子作为有效自然疗法的好处",
                "deskripsi": `咳痰是一个常见问题，并且确实会干扰日常活动。 尽管市场上有许多止咳药物，但自然疗法通常是许多人的选择。 传统上用于治疗痰咳的天然成分之一是罗望子。 在本文中，我们将探讨罗望子作为治疗痰咳的有效自然疗法的好处以及如何使用它。

                罗望子治疗痰咳的好处：
               
                化痰：罗望子具有天然的祛痰特性，可以帮助化解呼吸道中的痰液，使其更容易通过咳嗽排出。
               
                抗菌特性：罗望子中的酸含量具有抗菌特性，可以帮助杀死引起呼吸道感染并导致咳痰的细菌和病毒。
               
                抗炎特性：罗望子还具有抗炎特性，可以帮助减少呼吸道炎症，这通常是刺激和持续咳嗽的原因。
               
                如何使用罗望子治疗痰咳：
               
                温罗望子饮料：将罗望子溶解在温水中，加入少许蜂蜜或糖，味道更好。 慢慢地喝这种饮料可以缓解喉咙刺激并帮助化痰。
               
                罗望子胸部按压：将小毛巾浸入温热的罗望子溶液中，然后将其放在胸部几分钟。 这可以帮助减少呼吸道炎症并缓解咳嗽痰。
               
                用罗望子溶液漱口：将溶解的罗望子与温水混合，用作漱口水。 将此溶液漱口几秒钟，以清理喉咙并减少引起咳嗽的刺激。
               
                警告：虽然罗望子有潜力作为治疗痰咳的自然疗法，但在使用任何治疗方法之前，请先咨询医生或健康专业人士，特别是当您的痰咳症状严重或持续很长时间时。`,
                "image": gambar7,
                "post": "发表于 12/10/2024",
                "kategori": "pengobatan",
                "referensi": "https://health.grid.id/read/352882602/gunakan-asam-jawa-untuk-atasi-batuk-membandel-begini-caranya?page=all"
            },
        },
        {
            id:  {
                    "nama": "Asam Jawa dan Kesehatan Reproduksi Pria Menjaga Kesuburan",
                    "deskripsi": `Kesehatan reproduksi pria memiliki peran penting dalam mendukung keluarga dan generasi yang sehat. Asam Jawa, yang dikenal dengan sifatnya yang kaya akan nutrisi, senyawa antioksidan, dan manfaat kesehatan, telah menjadi fokus penelitian karena potensinya untuk mempengaruhi kesehatan reproduksi pria. Mari kita eksplorasi bagaimana asam Jawa dapat menjadi sekutu alami dalam menjaga kesuburan pria.

                    1. Meningkatkan Kualitas Sperma:
                    Asam Jawa dapat memberikan kontribusi positif terhadap kualitas sperma. Beberapa penelitian menunjukkan bahwa asam Jawa memiliki efek positif terhadap motilitas sperma (kemampuan sperma untuk bergerak) dan morfologi sperma (bentuk fisik sperma). Kualitas sperma yang baik adalah faktor kunci dalam mencapai keberhasilan konsepsi.
                    
                    2. Menjaga Keseimbangan Hormon:
                    Hormon, seperti testosteron, berperan penting dalam fungsi reproduksi pria. Asam Jawa diketahui dapat membantu menjaga keseimbangan hormon, termasuk produksi testosteron yang optimal. Keseimbangan hormonal yang baik mendukung fungsi testis dan produksi sperma yang sehat.
                    
                    3. Mengandung Antioksidan untuk Melawan Kerusakan Sel:
                    Asam Jawa mengandung senyawa antioksidan seperti flavonoid dan polifenol yang dapat melawan radikal bebas. Radikal bebas dapat menyebabkan stres oksidatif, yang dapat merusak sel-sel sperma. Dengan meminimalkan kerusakan sel, asam Jawa dapat membantu menjaga integritas sel sperma.
                    
                    4. Mengatasi Masalah Libido:
                    Beberapa penelitian menunjukkan bahwa asam Jawa dapat memiliki efek positif pada libido atau gairah seksual. Peningkatan libido dapat meningkatkan motivasi seksual dan frekuensi hubungan seksual, yang pada gilirannya dapat mendukung peluang konsepsi.
                    
                    5. Mencegah Gangguan Reproduksi:
                    Asam Jawa diketahui memiliki sifat antiinflamasi dan dapat membantu melawan infeksi. Gangguan dan infeksi pada sistem reproduksi pria dapat mempengaruhi kesuburan. Dengan memberikan perlindungan terhadap infeksi dan peradangan, asam Jawa dapat membantu mencegah gangguan reproduksi.
                    
                    Cara Menggunakan Asam Jawa untuk Mendukung Kesehatan Reproduksi Pria:
                    
                    Minuman Asam Jawa:
                    Konsumsi minuman asam Jawa secara teratur. Anda dapat membuat minuman asam Jawa dengan merendam asam Jawa dalam air dan menambahkan sedikit madu untuk memberikan rasa yang lebih enak.
                    
                    Tambahkan ke Makanan:
                    Tambahkan asam Jawa sebagai bahan dalam masakan. Ini bisa digunakan dalam berbagai hidangan, memberikan sentuhan rasa yang unik dan manfaat kesehatan yang menyeluruh.
                    
                    Suplemen Asam Jawa:
                    Jika sulit mendapatkan asam Jawa segar, pertimbangkan untuk mengonsumsi suplemen asam Jawa. Pastikan untuk memilih suplemen yang berkualitas dan berkonsultasi dengan profesional kesehatan sebelum mengonsumsinya.
                    
                    Pentingnya Gaya Hidup Sehat:
                    Selain mengonsumsi asam Jawa, penting untuk menjalani gaya hidup sehat secara umum. Berolahraga teratur, menjaga berat badan yang sehat, dan menghindari kebiasaan merokok serta minuman beralkohol dapat mendukung kesehatan reproduksi secara keseluruhan.
                    
                    Peringatan:
                    Meskipun asam Jawa dapat memberikan manfaat bagi kesehatan reproduksi pria, tetaplah konsultasikan dengan profesional kesehatan, terutama jika Anda memiliki kondisi kesehatan tertentu atau sedang mengonsumsi obat-obatan.
                    
                    Dengan potensinya dalam meningkatkan kualitas sperma, menjaga keseimbangan hormon, dan memberikan dukungan pada kesehatan reproduksi pria secara keseluruhan, asam Jawa dapat dianggap sebagai pilihan alami yang bermanfaat dalam perjalanan menuju kesuburan dan kesehatan reproduksi pria yang optimal.`,
                    "image": gambar8,
                    "post": "Diposting pada 12/10/2024",
                    "kategori": "pengobatan",
                    "referensi": "https://food.detik.com/info-kuliner/d-6040496/apa-benar-konsumsi-asam-jawa-bikin-sperma-kental-ini-penjelasannya",
                },
            en: {
                    "nama": "Tamarind and Men's Reproductive Health Maintain Fertility",
                    "deskripsi": `Men's reproductive health has an important role in supporting healthy families and generations. Tamarind, known for its nutrient-rich properties, antioxidant compounds, and health benefits, has been the focus of research due to its potential to influence men's reproductive health. Let's explore how tamarind can be a natural ally in maintaining male fertility.

                    1. Improves Sperm Quality:
                    Tamarind can make a positive contribution to sperm quality. Several studies show that tamarind has a positive effect on sperm motility (the ability of sperm to move) and sperm morphology (the physical shape of sperm). Good sperm quality is a key factor in achieving successful conception.
                   
                    2. Maintains Hormonal Balance:
                    Hormones, such as testosterone, play an important role in male reproductive function. Tamarind is known to help maintain hormonal balance, including optimal testosterone production. Good hormonal balance supports testicular function and healthy sperm production.
                   
                    3. Contains Antioxidants to Fight Cell Damage:
                    Tamarind contains antioxidant compounds such as flavonoids and polyphenols which can fight free radicals. Free radicals can cause oxidative stress, which can damage sperm cells. By minimizing cell damage, tamarind can help maintain the integrity of sperm cells.
                   
                    4. Addresses Libido Problems:
                    Several studies show that tamarind can have a positive effect on libido or sexual desire. Increased libido can increase sexual motivation and frequency of sexual intercourse, which in turn can support the chances of conception.
                   
                    5. Prevents Reproductive Disorders:
                    Tamarind is known to have anti-inflammatory properties and can help fight infections. Disorders and infections in the male reproductive system can affect fertility. By providing protection against infection and inflammation, tamarind can help prevent reproductive disorders.
                   
                    How to Use Tamarind to Support Men's Reproductive Health:
                   
                    Tamarind Drink:
                    Consume tamarind drinks regularly. You can make tamarind drink by soaking tamarind in water and adding a little honey to give it a better taste.
                   
                    Add to Food:
                    Add tamarind as an ingredient in cooking. It can be used in a variety of dishes, providing a unique touch of flavor and comprehensive health benefits.
                   
                    Tamarind Supplements:
                    If it's difficult to get fresh tamarind, consider taking tamarind supplements. Make sure to choose quality supplements and consult a health professional before consuming them.
                   
                    Importance of a Healthy Lifestyle:
                    Apart from consuming tamarind, it is important to live a healthy lifestyle in general. Exercising regularly, maintaining a healthy weight, and avoiding smoking and alcoholic beverages can support overall reproductive health.
                   
                    Warning:
                    Although tamarind can provide benefits for men's reproductive health, still consult a health professional, especially if you have certain health conditions or are taking medications.
                   
                    With its potential to improve sperm quality, maintain hormonal balance, and provide support to overall male reproductive health, tamarind can be considered a beneficial natural choice on the journey to optimal male fertility and reproductive health.`,
                    "image": gambar8,
                    "post": "Post on 12/10/2024",
                    "kategori": "pengobatan",
                    "referensi": "https://food.detik.com/info-kuliner/d-6040496/apa-benar-konsumsi-asam-jawa-bikin-sperma-kental-ini-penjelasannya",
            },
            ar:  {
                "nama": "التمر الهندي والصحة الإنجابية للرجال يحافظان على الخصوبة",
                "deskripsi": `وللصحة الإنجابية للرجال دور مهم في دعم صحة الأسر والأجيال. كان التمر الهندي، المعروف بخصائصه الغنية بالمغذيات، ومركباته المضادة للأكسدة، وفوائده الصحية، محور البحث بسبب قدرته على التأثير على الصحة الإنجابية للرجال. دعونا نستكشف كيف يمكن أن يكون التمر الهندي حليفًا طبيعيًا في الحفاظ على خصوبة الرجال.

                1. تحسين جودة الحيوانات المنوية:
                يمكن للتمر الهندي أن يقدم مساهمة إيجابية في جودة الحيوانات المنوية. تظهر العديد من الدراسات أن التمر الهندي له تأثير إيجابي على حركة الحيوانات المنوية (قدرة الحيوانات المنوية على الحركة) وتشكل الحيوانات المنوية (الشكل المادي للحيوانات المنوية). تعتبر نوعية الحيوانات المنوية الجيدة عاملاً رئيسياً في تحقيق الحمل الناجح.
               
                2. يحافظ على التوازن الهرموني :
                تلعب الهرمونات، مثل هرمون التستوستيرون، دورًا مهمًا في الوظيفة الإنجابية لدى الذكور. من المعروف أن التمر الهندي يساعد في الحفاظ على التوازن الهرموني، بما في ذلك إنتاج هرمون التستوستيرون الأمثل. التوازن الهرموني الجيد يدعم وظيفة الخصية وإنتاج الحيوانات المنوية بشكل صحي.
               
                3. يحتوي على مضادات الأكسدة لمحاربة تلف الخلايا:
                يحتوي التمر الهندي على مركبات مضادة للأكسدة مثل الفلافونويد والبوليفينول التي يمكنها محاربة الجذور الحرة. يمكن أن تسبب الجذور الحرة الإجهاد التأكسدي، والذي يمكن أن يؤدي إلى تلف خلايا الحيوانات المنوية. من خلال تقليل تلف الخلايا، يمكن أن يساعد التمر الهندي في الحفاظ على سلامة خلايا الحيوانات المنوية.
               
                4. يعالج مشاكل الرغبة الجنسية :
                تظهر العديد من الدراسات أن التمر الهندي يمكن أن يكون له تأثير إيجابي على الرغبة الجنسية أو الرغبة الجنسية. زيادة الرغبة الجنسية يمكن أن تزيد من الدافع الجنسي وتكرار الجماع، والذي بدوره يمكن أن يدعم فرص الحمل.
               
                5. يمنع الاضطرابات الإنجابية:
                ومن المعروف أن التمر الهندي له خصائص مضادة للالتهابات ويمكن أن يساعد في مكافحة الالتهابات. يمكن أن تؤثر الاضطرابات والالتهابات في الجهاز التناسلي الذكري على الخصوبة. من خلال توفير الحماية ضد العدوى والالتهابات، يمكن أن يساعد التمر الهندي في منع الاضطرابات الإنجابية.
               
                كيفية استخدام التمر الهندي لدعم الصحة الإنجابية للرجال:
               
                مشروب التمر الهندي:
                تناول مشروبات التمر الهندي بانتظام. يمكنك تحضير مشروب التمر الهندي عن طريق نقع التمر الهندي في الماء وإضافة القليل من العسل لإضفاء طعم أفضل عليه.
               
                أضف إلى الطعام:
                إضافة التمر الهندي كعنصر في الطبخ. يمكن استخدامه في مجموعة متنوعة من الأطباق، مما يوفر لمسة فريدة من النكهة وفوائد صحية شاملة.
               
                مكملات التمر الهندي:
                إذا كان من الصعب الحصول على التمر الهندي الطازج، فكر في تناول مكملات التمر الهندي. تأكد من اختيار المكملات الغذائية عالية الجودة واستشارة أخصائي الصحة قبل تناولها.
               
                أهمية نمط الحياة الصحي:
                وبصرف النظر عن استهلاك التمر الهندي، فمن المهم أن نعيش نمط حياة صحي بشكل عام. ممارسة الرياضة بانتظام، والحفاظ على وزن صحي، وتجنب التدخين والمشروبات الكحولية يمكن أن تدعم الصحة الإنجابية الشاملة.
               
                تحذير:
                على الرغم من أن التمر الهندي يمكن أن يوفر فوائد للصحة الإنجابية للرجال، فلا يزال عليك استشارة أخصائي صحي، خاصة إذا كنت تعاني من ظروف صحية معينة أو تتناول أدوية.
               
                بفضل قدرته على تحسين جودة الحيوانات المنوية، والحفاظ على التوازن الهرموني، وتقديم الدعم للصحة الإنجابية للذكور بشكل عام، يمكن اعتبار التمر الهندي خيارًا طبيعيًا مفيدًا في الرحلة إلى الخصوبة المثلى للذكور والصحة الإنجابية.`,
                "image": gambar8,
                "post": "تم النشر بتاريخ 12/10/2024",
                "kategori": "pengobatan",
                "referensi": "https://food.detik.com/info-kuliner/d-6040496/apa-benar-konsumsi-asam-jawa-bikin-sperma-kental-ini-penjelasannya",
            },
            zh:  {
                "nama": "罗望子和男性生殖健康保持生育能力",
                "deskripsi": `男性生殖健康对于支持健康的家庭和后代具有重要作用。 罗望子以其营养丰富、抗氧化化合物和健康益处而闻名，由于其可能影响男性生殖健康，一直是研究的焦点。 让我们探讨一下罗望子如何成为维持男性生育能力的天然盟友。

                1. 提高精子质量：
                罗望子可以对精子质量做出积极贡献。 多项研究表明，罗望子对精子活力（精子移动的能力）和精子形态（精子的物理形状）具有积极影响。 良好的精子质量是成功受孕的关键因素。
               
                2. 维持荷尔蒙平衡：
                激素，例如睾酮，在男性生殖功能中发挥着重要作用。 众所周知，罗望子有助于维持荷尔蒙平衡，包括最佳睾酮生成。 良好的荷尔蒙平衡支持睾丸功能和健康的精子产生。
               
                3. 含有抗氧化剂，可对抗细胞损伤：
                罗望子含有抗氧化化合物，例如类黄酮和多酚，可以对抗自由基。 自由基会引起氧化应激，从而损害精子细胞。 通过最大限度地减少细胞损伤，罗望子可以帮助维持精子细胞的完整性。
               
                4.解决性欲问题：
                多项研究表明，罗望子可以对性欲或性欲产生积极影响。 性欲增加可以增加性动机和性交频率，从而提高受孕机会。
               
                5. 预防生殖疾病：
                众所周知，罗望子具有抗炎特性，有助于抵抗感染。 男性生殖系统的疾病和感染会影响生育能力。 通过提供针对感染和炎症的保护，罗望子可以帮助预防生殖疾病。
               
                如何使用罗望子支持男性生殖健康：
               
                罗望子饮料：
                定期饮用罗望子饮料。 您可以通过将罗望子浸泡在水中并添加少许蜂蜜来制作罗望子饮料，以使其味道更好。
               
                添加到食物中：
                添加罗望子作为烹饪原料。 它可用于多种菜肴，提供独特的风味和全面的健康益处。
               
                罗望子补充剂：
                如果很难获得新鲜的罗望子，请考虑服用罗望子补充剂。 确保选择优质补充剂并在食用前咨询健康专业人士。
               
                健康生活方式的重要性：
                除了食用罗望子之外，健康的生活方式也很重要。 定期锻炼、保持健康体重、避免吸烟和饮酒可以支持整体生殖健康。
               
                警告：
                尽管罗望子可以为男性生殖健康带来好处，但仍应咨询健康专业人士，特别是如果您有某些健康问题或正在服用药物。
               
                罗望子具有提高精子质量、维持荷尔蒙平衡并为整体男性生殖健康提供支持的潜力，被认为是实现最佳男性生育力和生殖健康过程中有益的自然选择。`,
                "image": gambar8,
                "post": "发表于 12/10/2024",
                "kategori": "pengobatan",
                "referensi": "https://food.detik.com/info-kuliner/d-6040496/apa-benar-konsumsi-asam-jawa-bikin-sperma-kental-ini-penjelasannya",
            },
        },
        {
            id: {
                    "nama": "Asam Jawa sebagai Katalisator Ekonomi Dampak pada Industri dan Perdagangan",
                    "deskripsi": `
                    Pohon Asam Jawa (Tamarindus indica), selain menjadi bagian penting dalam ekosistem pesisir Nusantara, juga memainkan peran krusial dalam menggerakkan roda ekonomi melalui dampaknya pada industri dan perdagangan. Artikel ini akan menjelajahi bagaimana Asam Jawa berperan sebagai katalisator ekonomi dan memberikan dampak positif pada sektor industri dan perdagangan di Nusantara.

                    Asam Jawa dalam Industri Pangan
                    Asam Jawa memiliki peran penting dalam industri pangan, khususnya di kuliner Nusantara. Buah yang kaya akan asam ini digunakan sebagai bumbu, pengawet, dan penyedap alami pada berbagai jenis makanan. Produk-produk olahan Asam Jawa, seperti sambal, asam pedas, atau manisan asam Jawa, tidak hanya mendukung keberagaman kuliner, tetapi juga menciptakan lapangan pekerjaan dalam industri makanan dan minuman.
                    
                    Industri Farmasi dan Kesehatan
                    Buah Asam Jawa mengandung berbagai senyawa yang memiliki manfaat kesehatan. Oleh karena itu, Asam Jawa digunakan dalam industri farmasi untuk produksi berbagai obat herbal dan suplemen kesehatan. Pemanfaatan Asam Jawa dalam industri ini tidak hanya meningkatkan ekonomi, tetapi juga mendukung pengembangan produk-produk kesehatan yang ramah lingkungan dan berbasis alam.
                    
                    Pertanian dan Perkebunan
                    Asam Jawa tidak hanya memberikan manfaat langsung melalui buahnya, tetapi juga melalui pengelolaan perkebunan. Pertanian Asam Jawa menciptakan pekerjaan di sektor pertanian dan membantu perekonomian masyarakat lokal. Selain itu, pengelolaan perkebunan Asam Jawa yang berkelanjutan juga mendukung keberlanjutan lingkungan dan menjaga keseimbangan ekosistem pesisir.
                    
                    Perdagangan Maritim
                    Asam Jawa memiliki peran dalam memperkuat perdagangan maritim di Nusantara. Sebagai salah satu komoditas ekspor, Asam Jawa menjadi barang dagangan yang diperdagangkan antar pulau-pulau. Perdagangan Asam Jawa tidak hanya menciptakan konektivitas antarwilayah, tetapi juga meningkatkan aktivitas perdagangan maritim, mendukung pertumbuhan ekonomi regional.
                    
                    Pariwisata Kuliner
                    Keunikan rasa Asam Jawa dan beragam produk olahannya juga menjadi daya tarik dalam industri pariwisata kuliner. Destinasi wisata kuliner yang mengandalkan keberagaman hidangan berbahan dasar Asam Jawa dapat menarik wisatawan lokal maupun internasional. Ini membuka peluang bisnis di sektor pariwisata dan mendukung pertumbuhan ekonomi di daerah tersebut.
                    
                    Kesimpulan
                    Asam Jawa bukan hanya sekadar tanaman pesisir; ia adalah katalisator ekonomi yang menggerakkan berbagai sektor. Dengan peran pentingnya dalam industri pangan, farmasi, pertanian, perdagangan, dan pariwisata kuliner, Asam Jawa memainkan peran yang signifikan dalam membangun fondasi ekonomi yang kuat dan berkelanjutan di Nusantara. Dengan menjaga keberlanjutan pengelolaannya, Asam Jawa akan terus menjadi aset berharga bagi pembangunan ekonomi dan kesejahteraan masyarakat pesisir.
                    `,
                    "image": gambar9,
                    "post": "Diposting pada 12/10/2024",
                    "kategori": "sejarah",
                    "referensi": "https://fiskal.kemenkeu.go.id/files/tekf/file/1648713446_tekf_i_2022_30032022.pdf"
                },
            en: {
                    "nama": "Tamarind as an Economic Catalyst Impacts on Industry and Trade",
                    "deskripsi": `
                    The Tamarind Tree (Tamarindus indica), apart from being an important part of the Indonesian coastal ecosystem, also plays a crucial role in driving the economy through its impact on industry and trade. This article will explore how Tamarind acts as an economic catalyst and has a positive impact on the industrial and trade sectors in the archipelago.

                    Tamarind in the Food Industry
                    Tamarind has an important role in the food industry, especially in Indonesian culinary delights. This acid-rich fruit is used as a spice, preservative and natural flavoring in various types of food. Tamarind processed products, such as chili sauce, spicy tamarind, or tamarind sweets, not only support culinary diversity, but also create jobs in the food and beverage industry.
                   
                    Pharmaceutical and Health Industry
                    Tamarind fruit contains various compounds that have health benefits. Therefore, Tamarind is used in the pharmaceutical industry for the production of various herbal medicines and health supplements. The use of Tamarind in this industry not only improves the economy, but also supports the development of environmentally friendly and nature-based health products.
                   
                    Agriculture and farming
                    Tamarind not only provides direct benefits through its fruit, but also through plantation management. Tamarind farming creates jobs in the agricultural sector and helps the economy of local communities. Apart from that, sustainable management of Tamarind plantations also supports environmental sustainability and maintains the balance of coastal ecosystems.
                   
                    Maritime Trade
                    Tamarind has a role in strengthening maritime trade in the archipelago. As an export commodity, Tamarind is a commodity traded between islands. Tamarind trade not only creates connectivity between regions, but also increases maritime trade activities, supporting regional economic growth.
                   
                    Culinary Tourism
                    The unique taste of Tamarind and its various processed products are also an attraction in the culinary tourism industry. Culinary tourism destinations that rely on a diversity of dishes made from tamarind can attract local and international tourists. This opens up business opportunities in the tourism sector and supports economic growth in the area.
                   
                    Conclusion
                    Tamarind is not just a coastal plant; it is an economic catalyst that drives various sectors. With its important role in the food, pharmaceutical, agricultural, trade and culinary tourism industries, Tamarind plays a significant role in building a strong and sustainable economic foundation in the archipelago. By maintaining sustainable management, Tamarind will continue to be a valuable asset for economic development and welfare of coastal communities.
                    `,
                    "image": gambar9,
                    "post": "Post on 12/10/2024",
                    "kategori": "sejarah",
                    "referensi": "https://fiskal.kemenkeu.go.id/files/tekf/file/1648713446_tekf_i_2022_30032022.pdf"
            },
            ar: {
                "nama": "التمر الهندي كمحفز اقتصادي وتأثيره على الصناعة والتجارة",
                "deskripsi": `
                شجرة التمر الهندي (Tamarindus indica)، بصرف النظر عن كونها جزءًا مهمًا من النظام البيئي الساحلي الإندونيسي، تلعب أيضًا دورًا حاسمًا في دفع الاقتصاد من خلال تأثيرها على الصناعة والتجارة. سوف تستكشف هذه المقالة كيف يعمل التمر الهندي كمحفز اقتصادي وله تأثير إيجابي على قطاعي الصناعة والتجارة في الأرخبيل.

                التمر الهندي في صناعة المواد الغذائية
                يلعب التمر الهندي دورًا مهمًا في صناعة المواد الغذائية، وخاصة في المأكولات الإندونيسية الشهية. تُستخدم هذه الفاكهة الغنية بالأحماض كتوابل ومواد حافظة ونكهة طبيعية في أنواع مختلفة من الأطعمة. إن منتجات التمر الهندي المصنعة، مثل صلصة الفلفل الحار، أو التمر الهندي الحار، أو حلويات التمر الهندي، لا تدعم التنوع الطهوي فحسب، بل تخلق أيضًا فرص عمل في صناعة الأغذية والمشروبات.
               
                صناعة الأدوية والصحة
                تحتوي فاكهة التمر الهندي على مركبات مختلفة لها فوائد صحية. ولذلك، يستخدم التمر الهندي في صناعة الأدوية لإنتاج الأدوية العشبية المختلفة والمكملات الصحية. إن استخدام التمر الهندي في هذه الصناعة لا يؤدي إلى تحسين الاقتصاد فحسب، بل يدعم أيضًا تطوير منتجات صحية صديقة للبيئة ومعتمدة على الطبيعة.
               
                الزراعة والزراعة
                لا يوفر التمر الهندي فوائد مباشرة من خلال ثماره فحسب، بل أيضًا من خلال إدارة المزارع. تخلق زراعة التمر الهندي فرص عمل في القطاع الزراعي وتساعد اقتصاد المجتمعات المحلية. وبصرف النظر عن ذلك، فإن الإدارة المستدامة لمزارع التمر الهندي تدعم أيضًا الاستدامة البيئية وتحافظ على توازن النظم البيئية الساحلية.
               
                التجارة البحرية
                وللتمر الهندي دور في تعزيز التجارة البحرية في الأرخبيل. كسلعة تصدير، التمر الهندي هو سلعة يتم تداولها بين الجزر. لا تعمل تجارة التمر الهندي على خلق التواصل بين المناطق فحسب، بل تعمل أيضًا على زيادة أنشطة التجارة البحرية، مما يدعم النمو الاقتصادي الإقليمي.
               
                السياحة الطهي
                يعد المذاق الفريد للتمر الهندي ومنتجاته المصنعة المتنوعة أيضًا عامل جذب في صناعة سياحة الطهي. يمكن لوجهات سياحة الطهي التي تعتمد على مجموعة متنوعة من الأطباق المصنوعة من التمر الهندي أن تجتذب السياح المحليين والدوليين. وهذا يفتح فرصًا تجارية في قطاع السياحة ويدعم النمو الاقتصادي في المنطقة.
               
                خاتمة
                التمر الهندي ليس مجرد نبات ساحلي؛ فهو حافز اقتصادي يدفع مختلف القطاعات. بفضل دورها المهم في الصناعات الغذائية والصيدلانية والزراعية والتجارية وسياحة الطهي، تلعب التمر الهندي دورًا مهمًا في بناء أساس اقتصادي قوي ومستدام في الأرخبيل. ومن خلال الحفاظ على الإدارة المستدامة، سيظل التمر الهندي مصدرًا قيمًا للتنمية الاقتصادية ورفاهية المجتمعات الساحلية.
                `,
                "image": gambar9,
                "post": "تم النشر بتاريخ 12/10/2024",
                "kategori": "sejarah",
                "referensi": "https://fiskal.kemenkeu.go.id/files/tekf/file/1648713446_tekf_i_2022_30032022.pdf"
            },
            zh: {
                "nama": "罗望子作为经济催化剂对工业和贸易的影响",
                "deskripsi": `
                罗望子树 (Tamarindus indica) 除了是印度尼西亚沿海生态系统的重要组成部分外，还通过其对工业和贸易的影响，在推动经济发展方面发挥着至关重要的作用。 本文将探讨罗望子如何充当经济催化剂并对群岛的工业和贸易部门产生积极影响。

                食品工业中的罗望子
                罗望子在食品工业中发挥着重要作用，尤其是在印度尼西亚美食中。 这种富含酸的水果在各种食品中用作香料、防腐剂和天然调味剂。 罗望子加工产品，如辣椒酱、辣罗望子或罗望子糖果，不仅支持烹饪多样性，还为食品和饮料行业创造就业机会。
               
                医药健康产业
                罗望子果实含有多种对健康有益的化合物。 因此，罗望子在制药工业中用于生产各种草药和保健品。 罗望子在该行业的使用不仅可以提高经济，还可以支持环保和基于自然的健康产品的开发。
               
                农业与种植业
                罗望子不仅通过其果实提供直接效益，还通过种植园管理提供直接效益。 罗望子种植为农业部门创造了就业机会，并有助于当地社区的经济。 除此之外，罗望子种植园的可持续管理还支持环境可持续性并维持沿海生态系统的平衡。
               
                海上贸易
                罗望子在加强群岛的海上贸易方面发挥着作用。 作为出口商品，罗望子是岛屿之间贸易的商品。 罗望子贸易不仅建立了地区之间的联系，还增加了海上贸易活动，支持地区经济增长。
               
                美食旅游
                罗望子的独特口味及其各种加工产品也是美食旅游业的一大亮点。 依靠罗望子制成的各种菜肴的美食旅游目的地可以吸引当地和国际游客。 这为旅游业开辟了商机，并支持该地区的经济增长。
               
                结论
                罗望子不仅是一种沿海植物，也是一种沿海植物。 它是推动各个部门发展的经济催化剂。 罗望子在食品、制药、农业、贸易和烹饪旅游业中发挥着重要作用，在为群岛建立强大和可持续的经济基础方面发挥着重要作用。 通过保持可持续管理，罗望子将继续成为沿海社区经济发展和福祉的宝贵资产。
                `,
                "image": gambar9,
                "post": "发表于 12/10/2024",
                "kategori": "sejarah",
                "referensi": "https://fiskal.kemenkeu.go.id/files/tekf/file/1648713446_tekf_i_2022_30032022.pdf"
            },
        },
        {
            id: {
                "nama": "Asam Jawa dan Warisan Tumbuhan Obat Kearifan Lokal yang Terjaga",
                "deskripsi": `
                Asam Jawa (Tamarindus indica) bukan hanya pohon yang tumbuh subur di pesisir Nusantara, tetapi juga merupakan bagian tak terpisahkan dari warisan tumbuhan obat tradisional. Dalam artikel ini, kita akan menjelajahi peran Asam Jawa dalam menjaga kearifan lokal terkait penggunaan tumbuhan obat, sebuah warisan yang terus diwariskan dari generasi ke generasi.

                Keberagaman Khasiat Asam Jawa dalam Pengobatan Tradisional
                Asam Jawa telah lama digunakan dalam pengobatan tradisional di berbagai komunitas di Nusantara. Buah, daun, dan kulit batang Asam Jawa mengandung senyawa-senyawa aktif yang memiliki khasiat kesehatan. Asam Jawa sering digunakan sebagai antiseptik, penurun panas, pencahar, dan bahkan sebagai obat penambah nafsu makan. Kekayaan khasiat ini menjadi bagian integral dari pengetahuan medis lokal yang diwariskan secara turun-temurun.
                
                Warisan Tumbuhan Obat: Penyelarasan dengan Alam dan Budaya Lokal
                Penggunaan Asam Jawa sebagai tumbuhan obat mencerminkan penyelarasan antara manusia, alam, dan budaya lokal. Kearifan lokal dalam menggunakan tumbuhan obat sering kali melibatkan pengetahuan tentang waktu yang tepat untuk mengumpulkan, cara pengolahan yang benar, dan dosis yang sesuai. Hal ini mencerminkan kedalaman hubungan manusia dengan alam sekitarnya dan kearifan lokal yang telah teruji sepanjang waktu.
                
                Peran Asam Jawa dalam Mengatasi Penyakit Tradisional
                Asam Jawa telah menjadi kunci dalam penanganan berbagai penyakit tradisional di masyarakat Nusantara. Dalam upaya penyembuhan penyakit seperti demam, batuk, maag, atau gangguan pencernaan lainnya, berbagai bentuk penggunaan Asam Jawa, mulai dari ramuan minuman hingga salep, menjadi solusi yang diandalkan oleh banyak orang.
                
                Peningkatan Kesejahteraan dan Keberlanjutan
                Pemanfaatan Asam Jawa dalam konteks tumbuhan obat tidak hanya berkontribusi pada kesehatan masyarakat, tetapi juga pada kesejahteraan ekonomi. Pengembangan industri obat tradisional berbasis Asam Jawa dapat menciptakan lapangan pekerjaan lokal dan mendukung pertumbuhan ekonomi di daerah tersebut. Dengan menjaga keberlanjutan pengelolaan Asam Jawa, masyarakat dapat terus mengakses keberagaman manfaat kesehatan dan ekonomi dari tanaman ini.
                
                Pentingnya Pelestarian dan Pendidikan Budaya
                Agar kearifan lokal terkait Asam Jawa dan tumbuhan obat tradisional tetap terjaga, penting untuk melakukan upaya pelestarian dan pendidikan budaya. Generasi muda perlu diberi kesempatan untuk memahami nilai-nilai budaya dan pengetahuan tradisional terkait penggunaan Asam Jawa. Ini dapat dilakukan melalui program pendidikan, kampanye pelestarian tumbuhan obat, dan pengembangan kebun obat masyarakat.
                
                Kesimpulan
                Asam Jawa tidak hanya menjadi bagian dari keanekaragaman alam Nusantara, tetapi juga merupakan penjaga warisan tumbuhan obat yang kaya. Dalam keberagaman khasiatnya, Asam Jawa mencerminkan kebijaksanaan lokal yang telah terjaga selama berabad-abad. Melalui pelestarian, pendidikan, dan pemanfaatan yang berkelanjutan, kita dapat terus memelihara kearifan lokal terkait Asam Jawa, mewariskannya kepada generasi mendatang, dan merayakan hubungan manusia dengan tanaman obat yang telah menjadi bagian integral dari kehidupan sehari-hari masyarakat Nusantara.
                `,
                "image": gambar16,
                "post": "Diposting pada 12/10/2024",
                "kategori": "sejarah",
                "referensi": "https://repo-mhs.ulm.ac.id/handle/123456789/39034?show=full"
            },
            en: {
                "nama": "Tamarind and the Preserved Heritage of Local Wisdom Medicinal Plants",
                "deskripsi": `
                Tamarind (Tamarindus indica) is not only a tree that grows abundantly on the coast of the archipelago, but is also an inseparable part of the heritage of traditional medicinal plants. In this article, we will explore Tamarind's role in preserving local wisdom regarding the use of medicinal plants, a legacy that continues to be passed down from generation to generation.

                The Diversity of Benefits of Tamarind in Traditional Medicine
                Tamarind has long been used in traditional medicine in various communities in the archipelago. Tamarind fruit, leaves and bark contain active compounds that have health benefits. Tamarind is often used as an antiseptic, fever reducer, laxative, and even as a medicine to increase appetite. This wealth of properties is an integral part of local medical knowledge passed down from generation to generation.
               
                Medicinal Plant Heritage: Harmonizing with Nature and Local Culture
                The use of Tamarind as a medicinal plant reflects the harmony between humans, nature and local culture. Local wisdom in using medicinal plants often involves knowledge of the right time to collect, the right way to process them, and the appropriate dosage. This reflects the depth of human relationships with the natural surroundings and local wisdom that has been tested over time.
               
                The Role of Tamarind in Overcoming Traditional Diseases
                Tamarind has become the key in treating various traditional diseases in Indonesian society. In an effort to cure diseases such as fever, cough, ulcers, or other digestive disorders, various forms of using Tamarind, from drinking concoctions to ointments, are a solution that many people rely on.
               
                Increased Welfare and Sustainability
                The use of Tamarind in the context of medicinal plants not only contributes to public health, but also to economic prosperity. The development of a traditional medicine industry based on Tamarind can create local jobs and support economic growth in the area. By maintaining sustainable management of Tamarind, the community can continue to access the diversity of health and economic benefits of this plant.
               
                The Importance of Cultural Preservation and Education
                So that local wisdom related to Tamarind and traditional medicinal plants is maintained, it is important to carry out cultural preservation and education efforts. The younger generation needs to be given the opportunity to understand cultural values and traditional knowledge regarding the use of Tamarind. This can be done through educational programs, medicinal plant conservation campaigns, and the development of community medicinal gardens.
               
                Conclusion
                Tamarind is not only part of the natural diversity of the archipelago, but is also the guardian of a rich medicinal plant heritage. In its diversity of properties, Tamarind reflects local wisdom that has been preserved for centuries. Through preservation, education and sustainable use, we can continue to preserve local wisdom regarding Tamarind, pass it on to future generations, and celebrate the human relationship with this medicinal plant which has become an integral part of the daily lives of the people of the archipelago.
                              `,
                "image": gambar16,
                "post": "Posted on 12/10/2024",
                "kategori": "sejarah",
                "referensi": "https://repo-mhs.ulm.ac.id/handle/123456789/39034?show=full"
            },
            ar: {
                "nama": "التمر الهندي والتراث المحفوظ للنباتات الطبية الحكيمة المحلية",
                "deskripsi": `
                التمر الهندي (Tamarindus indica) ليس فقط شجرة تنمو بكثرة على ساحل الأرخبيل، ولكنه أيضًا جزء لا يتجزأ من تراث النباتات الطبية التقليدية. في هذا المقال، سنستكشف دور التمر الهندي في الحفاظ على الحكمة المحلية فيما يتعلق باستخدام النباتات الطبية، وهو إرث يستمر في تناقله من جيل إلى جيل.

                تنوع فوائد التمر الهندي في الطب التقليدي
                لقد استخدم التمر الهندي منذ فترة طويلة في الطب التقليدي في مجتمعات مختلفة في الأرخبيل. تحتوي ثمار التمر الهندي وأوراقه ولحاءه على مركبات نشطة لها فوائد صحية. غالبًا ما يستخدم التمر الهندي كمطهر، ومخفض للحمى، وملين، وحتى كدواء لزيادة الشهية. تعد هذه الثروة من الخصائص جزءًا لا يتجزأ من المعرفة الطبية المحلية التي تنتقل من جيل إلى جيل.
               
                التراث النباتي الطبي: الانسجام مع الطبيعة والثقافة المحلية
                إن استخدام التمر الهندي كنبات طبي يعكس الانسجام بين الإنسان والطبيعة والثقافة المحلية. غالبًا ما تتضمن الحكمة المحلية في استخدام النباتات الطبية معرفة الوقت المناسب لجمعها، والطريقة الصحيحة لمعالجتها، والجرعة المناسبة. وهذا يعكس عمق العلاقات الإنسانية مع البيئة الطبيعية والحكمة المحلية التي تم اختبارها مع مرور الوقت.
               
                دور التمر الهندي في التغلب على الأمراض التقليدية
                أصبح التمر الهندي هو المفتاح في علاج الأمراض التقليدية المختلفة في المجتمع الإندونيسي. في محاولة لعلاج أمراض مثل الحمى والسعال والقرحة أو غيرها من اضطرابات الجهاز الهضمي، فإن الأشكال المختلفة لاستخدام التمر الهندي، من شرب الخلطات إلى المراهم، هي الحل الذي يعتمد عليه الكثير من الناس.
               
                زيادة الرفاهية والاستدامة
                إن استخدام التمر الهندي في سياق النباتات الطبية لا يساهم في الصحة العامة فحسب، بل يساهم أيضًا في الرخاء الاقتصادي. إن تطوير صناعة الطب التقليدي المعتمدة على التمر الهندي يمكن أن يخلق فرص عمل محلية ويدعم النمو الاقتصادي في المنطقة. من خلال الحفاظ على الإدارة المستدامة للتمر الهندي، يمكن للمجتمع الاستمرار في الوصول إلى مجموعة متنوعة من الفوائد الصحية والاقتصادية لهذا النبات.
               
                أهمية الحفاظ على الثقافة والتعليم
                ومن أجل الحفاظ على الحكمة المحلية المتعلقة بالتمر الهندي والنباتات الطبية التقليدية، فمن المهم بذل جهود الحفاظ على الثقافة والتعليم. يجب منح جيل الشباب الفرصة لفهم القيم الثقافية والمعارف التقليدية المتعلقة باستخدام التمر الهندي. ويمكن القيام بذلك من خلال البرامج التعليمية، وحملات الحفاظ على النباتات الطبية، وتطوير الحدائق الطبية المجتمعية.
               
                خاتمة
                لا يعد التمر الهندي جزءًا من التنوع الطبيعي للأرخبيل فحسب، بل هو أيضًا الوصي على تراث النباتات الطبية الغني. يعكس التمر الهندي، في تنوع خصائصه، الحكمة المحلية التي تم الحفاظ عليها لعدة قرون. من خلال الحفظ والتعليم والاستخدام المستدام، يمكننا الاستمرار في الحفاظ على الحكمة المحلية فيما يتعلق بالتمر الهندي، ونقلها إلى الأجيال القادمة، والاحتفال بالعلاقة الإنسانية مع هذا النبات الطبي الذي أصبح جزءًا لا يتجزأ من الحياة اليومية لسكان الأرخبيل. .
                              `,
                "image": gambar16,
                "post": "تم النشر بتاريخ 12/10/2024",
                "kategori": "sejarah",
                "referensi": "https://repo-mhs.ulm.ac.id/handle/123456789/39034?show=full"
            },
            zh: {
                "nama": "罗望子和当地智慧药用植物的保存遗产",
                "deskripsi": `
                罗望子（Tamarindus indica）不仅是群岛沿岸大量生长的树木，也是传统药用植物遗产不可分割的一部分。 在本文中，我们将探讨罗望子在保存当地药用植物使用智慧方面的作用，这一遗产继续代代相传。

                罗望子在传统医学中的多种功效
                罗望子长期以来一直在群岛各个社区的传统医学中使用。 罗望子果实、叶子和树皮含有对健康有益的活性化合物。 罗望子常被用作防腐剂、退烧剂、泻药，甚至用作增进食欲的药物。 这些丰富的财产是当地代代相传的医学知识的组成部分。
               
                药用植物遗产：与自然和当地文化的和谐统一
                使用罗望子作为药用植物体现了人类、自然和当地文化之间的和谐。 当地使用药用植物的智慧通常涉及正确的采集时间、正确的加工方法以及适当的剂量。 这反映了人类与自然环境的关系的深度以及经过时间考验的当地智慧。
               
                罗望子在克服传统疾病中的作用
                罗望子已成为印尼社会治疗各种传统疾病的关键。 为了治疗发烧、咳嗽、溃疡或其他消化系统疾病等疾病，使用罗望子的各种形式，从饮用混合物到药膏，是许多人依赖的解决方案。
               
                增加福利和可持续性
                在药用植物中使用罗望子不仅有助于公众健康，而且有助于经济繁荣。 以罗望子为基础的传统医药产业的发展可以创造当地就业机会并支持该地区的经济增长。 通过维持罗望子的可持续管理，社区可以继续获得该植物的多样性健康和经济效益。
               
                文化保护和教育的重要性
                为了保持与罗望子和传统药用植物相关的当地智慧，开展文化保护和教育工作非常重要。 年轻一代需要有机会了解有关罗望子用途的文化价值观和传统知识。 这可以通过教育计划、药用植物保护运动和社区药用花园的发展来实现。
               
                结论
                罗望子不仅是群岛自然多样性的一部分，也是丰富的药用植物遗产的守护者。 罗望子的特性多种多样，反映了几个世纪以来保存下来的当地智慧。 通过保护、教育和可持续利用，我们可以继续保存有关罗望子的当地智慧，将其传承给子孙后代，并庆祝人类与这种药用植物的关系，这种药用植物已成为群岛人民日常生活中不可或缺的一部分。
                              `,
                "image": gambar16,
                "post": "发表于 12/10/2024",
                "kategori": "sejarah",
                "referensi": "https://repo-mhs.ulm.ac.id/handle/123456789/39034?show=full"
            }
        },
        {
            id: {
                "nama": "Peran Asam Jawa dalam Keseimbangan Ekologi Konservasi Alam di Nusantara",
                "deskripsi": `
                Asam Jawa (Tamarindus indica) bukan hanya pohon pesisir biasa di Nusantara, melainkan juga pemain kunci dalam menjaga keseimbangan ekologi. Artikel ini akan membahas peran penting Asam Jawa dalam konservasi alam di Nusantara dan dampak positifnya terhadap ekosistem pesisir.

                Keanekaragaman Ekosistem Pesisir
                Pohon Asam Jawa tumbuh subur di wilayah pesisir, menciptakan ekosistem yang kaya akan keanekaragaman hayati. Daun-daun lebat dan akar yang kuat membentuk lapisan penutup tanah yang melindungi pantai dari erosi. Keberadaannya memberikan tempat tinggal bagi berbagai spesies tanaman, hewan, dan mikroorganisme yang mendukung rantai makanan pesisir yang kompleks.
                
                Perlindungan Terhadap Erosi Pesisir
                Asam Jawa memiliki sistem akar yang kuat dan menjalar, yang mampu menahan tanah di daerah pesisir. Akar-akar ini membantu mengurangi erosi pantai yang dapat disebabkan oleh gelombang laut dan aktivitas manusia. Oleh karena itu, kehadiran Asam Jawa berkontribusi secara signifikan pada pemeliharaan dan perlindungan garis pantai.
                
                Pengaruh Terhadap Kualitas Tanah
                Daun dan ranting Asam Jawa yang gugur memberikan lapisan humus yang kaya akan nutrisi kepada tanah di sekitarnya. Proses dekomposisi bahan organik ini meningkatkan kesuburan tanah dan mendukung pertumbuhan tanaman lainnya. Dengan demikian, Asam Jawa bukan hanya melibatkan diri dalam ekosistem pesisir, tetapi juga berkontribusi pada keseimbangan ekologi melalui pemulihan dan pemeliharaan kualitas tanah.
                
                Sumber Daya Hayati dan Biodiversitas
                Asam Jawa menjadi rumah bagi beragam spesies flora dan fauna, termasuk burung, serangga, dan mamalia pesisir. Banyak spesies ini bergantung pada pohon Asam Jawa untuk mencari makanan, bertelur, atau berteduh. Oleh karena itu, melindungi Asam Jawa berarti melindungi sumber daya hayati dan mempertahankan keanekaragaman biologis di wilayah pesisir.
                
                Pemanfaatan Berkelanjutan dalam Ekowisata
                Pemanfaatan berkelanjutan Asam Jawa dalam konteks ekowisata juga dapat mendukung konservasi alam. Melibatkan masyarakat lokal dalam pengelolaan ekowisata yang berbasis pada Asam Jawa dapat menciptakan kesadaran akan pentingnya keseimbangan ekologi dan memberikan insentif ekonomi yang dapat mendukung upaya pelestarian.
                
                Tantangan dan Upaya Pelestarian Asam Jawa
                Meskipun memiliki peran penting dalam keseimbangan ekologi, Asam Jawa menghadapi tantangan, termasuk perubahan iklim, deforestasi, dan pengembangan pantai. Upaya pelestarian yang melibatkan pemantauan ekosistem, penanaman kembali, dan kesadaran masyarakat dapat membantu menjaga agar Asam Jawa tetap berperan sebagai penjaga keseimbangan ekologi di pesisir Nusantara.
                
                Kesimpulan
                Asam Jawa bukan hanya tumbuhan pesisir biasa; ia adalah penjaga keseimbangan ekologi di Nusantara. Dengan memberikan kontribusi pada keanekaragaman hayati, melindungi pantai dari erosi, dan mendukung kualitas tanah, Asam Jawa membuktikan dirinya sebagai pilar konservasi alam yang tak tergantikan. Melalui upaya pelestarian dan pemahaman tentang perannya, kita dapat memastikan bahwa Asam Jawa tetap menjadi penjaga ekosistem pesisir yang berharga untuk generasi-generasi mendatang.
                `,
                "image": gambar17,
                "post": "Diposting pada 12/10/2024",
                "kategori": "sejarah",
                "referensi": "https://ksdae.menlhk.go.id/artikel/9476/diskusi-konservasi-bersama-penggiat-konservasi-sumatera-utara.html"
            },
            en: {
                "nama": "The Role of Tamarind in the Ecological Balance of Nature Conservation in the Archipelago",
                "deskripsi": `
                Tamarind (Tamarindus indica) is not only a common coastal tree in the archipelago, but is also a key player in maintaining ecological balance. This article will discuss the important role of Tamarind in nature conservation in the archipelago and its positive impact on coastal ecosystems.

                Coastal Ecosystem Diversity
                Tamarind trees grow abundantly in coastal areas, creating an ecosystem rich in biodiversity. Dense leaves and strong roots form a soil cover that protects the coast from erosion. Its existence provides a home for various species of plants, animals and microorganisms that support complex coastal food chains.
               
                Protection Against Coastal Erosion
                Tamarind has a strong and spreading root system, which is able to hold the soil in coastal areas. These roots help reduce coastal erosion that can be caused by ocean waves and human activities. Therefore, the presence of Tamarind contributes significantly to the maintenance and protection of coastlines.
               
                Influence on Soil Quality
                Fallen Tamarind leaves and twigs provide a layer of nutrient-rich humus to the surrounding soil. This decomposition process of organic material increases soil fertility and supports the growth of other plants. In this way, Tamarind not only involves itself in coastal ecosystems, but also contributes to ecological balance through restoring and maintaining soil quality.
               
                Biological Resources and Biodiversity
                Tamarind is home to a variety of flora and fauna species, including birds, insects and coastal mammals. Many of these species depend on Tamarind trees for food, eggs, or shelter. Therefore, protecting Tamarind means protecting biological resources and maintaining biological diversity in coastal areas.
               
                Sustainable Use in Ecotourism
                Sustainable use of Tamarind in an ecotourism context can also support nature conservation. Involving local communities in managing ecotourism based on Tamarind can create awareness of the importance of ecological balance and provide economic incentives that can support conservation efforts.
               
                Challenges and Efforts to Preserve Tamarind
                Despite its important role in ecological balance, Tamarind faces challenges, including climate change, deforestation and coastal development. Conservation efforts involving ecosystem monitoring, replanting and public awareness can help ensure that Tamarind continues to act as a guardian of ecological balance on the coast of the archipelago.
               
                Conclusion
                Tamarind is not just an ordinary coastal plant; he is the guardian of ecological balance in the archipelago. By contributing to biodiversity, protecting beaches from erosion, and supporting soil quality, Tamarind is proving itself to be an irreplaceable pillar of nature conservation. Through conservation efforts and understanding its role, we can ensure that Tamarind remains a valuable guardian of coastal ecosystems for generations to come.
                              `,
                "image": gambar17,
                "post": "Posted on 12/10/2024",
                "kategori": "sejarah",
                "referensi": "https://ksdae.menlhk.go.id/artikel/9476/diskusi-konservasi-bersama-penggiat-konservasi-sumatera-utara.html"
            },
            ar: {
                "nama": "دور التمر الهندي في التوازن البيئي للحفاظ على الطبيعة في الأرخبيل",
                "deskripsi": `
                التمر الهندي (Tamarindus indica) ليس مجرد شجرة ساحلية شائعة في الأرخبيل، ولكنه أيضًا لاعب رئيسي في الحفاظ على التوازن البيئي. يناقش هذا المقال الدور المهم للتمر الهندي في الحفاظ على الطبيعة في الأرخبيل وتأثيره الإيجابي على النظم البيئية الساحلية.

                تنوع النظام البيئي الساحلي
                تنمو أشجار التمر الهندي بكثرة في المناطق الساحلية، مما يخلق نظامًا بيئيًا غنيًا بالتنوع البيولوجي. تشكل الأوراق الكثيفة والجذور القوية غطاءً للتربة يحمي الساحل من التآكل. يوفر وجودها موطنًا لأنواع مختلفة من النباتات والحيوانات والكائنات الحية الدقيقة التي تدعم سلاسل الغذاء الساحلية المعقدة.
               
                الحماية ضد التآكل الساحلي
                يتمتع التمر الهندي بنظام جذر قوي ومنتشر، وهو قادر على تثبيت التربة في المناطق الساحلية. تساعد هذه الجذور في تقليل التآكل الساحلي الذي يمكن أن تسببه أمواج المحيط والأنشطة البشرية. ولذلك فإن وجود التمر الهندي يساهم بشكل كبير في صيانة وحماية السواحل.
               
                التأثير على جودة التربة
                توفر أوراق وأغصان التمر الهندي المتساقطة طبقة من الدبال الغني بالمغذيات للتربة المحيطة. تعمل عملية تحلل المواد العضوية على زيادة خصوبة التربة ودعم نمو النباتات الأخرى. وبهذه الطريقة، لا يقتصر التمر الهندي على النظم البيئية الساحلية فحسب، بل يساهم أيضًا في التوازن البيئي من خلال استعادة جودة التربة والحفاظ عليها.
               
                الموارد البيولوجية والتنوع البيولوجي
                يعد التمر الهندي موطنًا لمجموعة متنوعة من أنواع النباتات والحيوانات، بما في ذلك الطيور والحشرات والثدييات الساحلية. تعتمد العديد من هذه الأنواع على أشجار التمر الهندي في الغذاء أو البيض أو المأوى. ولذلك فإن حماية التمر الهندي تعني حماية الموارد البيولوجية والحفاظ على التنوع البيولوجي في المناطق الساحلية.
               
                الاستخدام المستدام في السياحة البيئية
                الاستخدام المستدام للتمر الهندي في سياق السياحة البيئية يمكن أن يدعم أيضًا الحفاظ على الطبيعة. إن إشراك المجتمعات المحلية في إدارة السياحة البيئية القائمة على التمر الهندي يمكن أن يخلق الوعي بأهمية التوازن البيئي ويوفر حوافز اقتصادية يمكن أن تدعم جهود الحفاظ على البيئة.
               
                التحديات والجهود المبذولة للحفاظ على التمر الهندي
                على الرغم من دوره المهم في التوازن البيئي، يواجه التمر الهندي تحديات، بما في ذلك تغير المناخ وإزالة الغابات والتنمية الساحلية. يمكن أن تساعد جهود الحفظ التي تشمل مراقبة النظام البيئي وإعادة الزراعة والتوعية العامة في ضمان استمرار التمر الهندي في العمل كحارس للتوازن البيئي على ساحل الأرخبيل.
               
                خاتمة
                التمر الهندي ليس مجرد نبات ساحلي عادي؛ وهو حارس التوازن البيئي في الأرخبيل. من خلال المساهمة في التنوع البيولوجي، وحماية الشواطئ من التآكل، ودعم جودة التربة، أثبت التمر الهندي أنه ركيزة لا يمكن الاستغناء عنها في الحفاظ على الطبيعة. ومن خلال جهود الحفاظ على البيئة وفهم دورها، يمكننا التأكد من أن التمر الهندي يظل حارسًا قيمًا للنظم البيئية الساحلية للأجيال القادمة.
                             `,
                "image": gambar17,
                "post": "تم النشر بتاريخ 12/10/2024",
                "kategori": "sejarah",
                "referensi": "https://ksdae.menlhk.go.id/artikel/9476/diskusi-konservasi-bersama-penggiat-konservasi-sumatera-utara.html"
            },
            zh: {
                "nama": "罗望子在群岛自然保护生态平衡中的作用",
                "deskripsi": `
                罗望子（Tamarindus indica）不仅是群岛上常见的沿海树木，也是维持生态平衡的关键角色。 本文将讨论罗望子在群岛自然保护中的重要作用及其对沿海生态系统的积极影响。

                沿海生态系统多样性
                罗望子树在沿海地区生长丰富，创造了生物多样性丰富的生态系统。 茂密的叶子和坚固的根形成土壤覆盖层，保护海岸免受侵蚀。 它的存在为各种植物、动物和微生物提供了家园，支撑着复杂的沿海食物链。
               
                防止海岸侵蚀
                罗望子具有强大且伸展的根系，能够保持沿海地区的土壤。 这些根有助于减少海浪和人类活动引起的海岸侵蚀。 因此，罗望子的存在对海岸线的维护和保护做出了重大贡献。
               
                对土壤质量的影响
                掉落的罗望子叶子和树枝为周围的土壤提供了一层营养丰富的腐殖质。 有机物质的分解过程增加了土壤肥力并支持其他植物的生长。 这样，罗望子不仅参与沿海生态系统，还通过恢复和维持土壤质量为生态平衡做出贡献。
               
                生物资源和生物多样性
                罗望子是多种动植物物种的家园，包括鸟类、昆虫和沿海哺乳动物。 其中许多物种依赖罗望子树获取食物、卵或庇护所。 因此，保护​​罗望子就意味着保护生物资源，维护沿海地区的生物多样性。
               
                生态旅游的可持续利用
                在生态旅游背景下可持续利用罗望子也可以支持自然保护。 让当地社区参与基于罗望子的生态旅游管理可以提高人们对生态平衡重要性的认识，并提供支持保护工作的经济激励措施。
               
                保护罗望子的挑战和努力
                尽管罗望子在生态平衡中发挥着重要作用，但它仍面临着气候变化、森林砍伐和沿海开发等挑战。 涉及生态系统监测、重新种植和公众意识的保护工作有助于确保罗望子继续充当群岛海岸生态平衡的守护者。
               
                结论
                罗望子不仅仅是一种普通的沿海植物； 他是群岛生态平衡的守护者。 通过促进生物多样性、保护海滩免受侵蚀和支持土壤质量，罗望子正在证明自己是自然保护领域不可替代的支柱。 通过保护工作和了解其作用，我们可以确保罗望子仍然是子孙后代沿海生态系统的宝贵守护者。
                              `,
                "image": gambar17,
                "post": "发表于 12/10/2024",
                "kategori": "sejarah",
                "referensi": "https://ksdae.menlhk.go.id/artikel/9476/diskusi-konservasi-bersama-penggiat-konservasi-sumatera-utara.html"
            }
        },
        {
            id:  {
                    "nama": "Mengoptimalkan Kesehatan Gigi dengan Asam Jawa Perlindungan dari Karies",
                    "deskripsi": `Kesehatan gigi adalah bagian integral dari kesejahteraan umum kita. Asam Jawa, yang dikenal dengan sifat-sifat alaminya yang kaya akan nutrisi dan senyawa antioksidan, ternyata dapat memberikan perlindungan yang baik terhadap kesehatan gigi, membantu mencegah risiko karies dan masalah gigi lainnya. Mari kita eksplorasi bagaimana asam Jawa dapat menjadi sekutu alami dalam menjaga kesehatan gigi.

                    1. Mengurangi Pertumbuhan Bakteri Penyebab Karies:
                    Asam Jawa telah terbukti memiliki sifat antibakteri yang dapat membantu menghambat pertumbuhan bakteri, terutama Streptococcus mutans. Bakteri ini dikenal sebagai penyebab utama terjadinya karies gigi. Dengan mengurangi jumlah bakteri ini, asam Jawa membantu mencegah pembentukan plak dan menjaga kesehatan rongga mulut.
                    
                    2. Sumber Kalsium untuk Kesehatan Gigi:
                    Kalsium merupakan mineral penting untuk kekuatan dan kepadatan gigi. Asam Jawa mengandung kalsium, yang dapat memberikan dukungan tambahan untuk kesehatan gigi. Peningkatan asupan kalsium dapat membantu memperkuat enamel gigi, lapisan pelindung yang melindungi gigi dari kerusakan.
                    
                    3. Efek Antiinflamasi pada Gusi:
                    Asam Jawa memiliki sifat antiinflamasi yang dapat membantu meredakan peradangan pada gusi. Peradangan gusi yang kronis dapat menyebabkan penyakit gusi, yang pada gilirannya dapat menyebabkan kerusakan pada gigi dan tulang pendukungnya. Dengan meredakan peradangan, asam Jawa dapat berkontribusi pada kesehatan gusi yang optimal.
                    
                    4. Mencegah Pembentukan Plak dan Karang Gigi:
                    Plak dan karang gigi merupakan masalah umum yang dapat menyebabkan kerusakan gigi dan penyakit gusi. Asam Jawa dapat membantu mencegah pembentukan plak dan karang gigi dengan menghambat pertumbuhan bakteri penyebabnya. Ini memberikan perlindungan tambahan terhadap gigi dari ancaman kerusakan.
                    
                    5. Meminimalkan Risiko Karies:
                    Dengan sifat antibakteri, antiinflamasi, dan kandungan kalsiumnya, asam Jawa membantu meminimalkan risiko karies gigi. Pemakaian teratur asam Jawa, baik melalui konsumsi langsung maupun integrasi dalam makanan dan minuman, dapat menjadi langkah preventif efektif dalam menjaga kesehatan gigi.
                    
                    Cara Menggunakan Asam Jawa untuk Menjaga Kesehatan Gigi:
                    
                    Minuman Asam Jawa:
                    Konsumsi minuman asam Jawa secara teratur. Anda dapat membuat minuman asam Jawa dengan mencampurkan asam Jawa yang sudah direndam air dengan sedikit madu atau gula untuk memberikan rasa yang lebih enak.
                    
                    Gosok Gigi dengan Asam Jawa:
                    Buatlah pasta gigi alami dengan mencampurkan asam Jawa yang sudah dihaluskan dengan sedikit baking soda. Gosok gigi menggunakan campuran ini untuk mendapatkan manfaat pembersihan dan perlindungan tambahan.
                    
                    Tambahkan ke Makanan:
                    Tambahkan asam Jawa sebagai bahan dalam masakan. Ini bisa digunakan dalam berbagai hidangan, memberikan manfaat kesehatan pada gigi dari dalam.
                    
                    Peringatan:
                    Meskipun asam Jawa dapat memberikan manfaat bagi kesehatan gigi, tetaplah menjaga kebersihan mulut dengan rutin menyikat gigi, menggunakan benang gigi, dan mengunjungi dokter gigi secara teratur.
                    
                    Dengan kemampuannya dalam menghambat bakteri penyebab karies, memberikan dukungan kalsium, dan meredakan peradangan, asam Jawa dapat dianggap sebagai bahan alami yang efektif dalam menjaga kesehatan gigi. Dengan merangkul kebiasaan hidup sehat dan integrasi asam Jawa dalam perawatan gigi, kita dapat memastikan senyuman yang sehat dan cerah untuk jangka panjang.`,
                    "image": gambar10,
                    "post": "Diposting pada 12/10/2024",
                    "kategori": "pengobatan",
                    "referensi": "https://journal.unnes.ac.id/sju/index.php/higeia/article/view/13998"
                },
            en: {
                    "nama": "Optimizing Dental Health with Tamarind Protection from Caries",
                    "deskripsi": `Dental health is an integral part of our general well-being. Tamarind, which is known for its natural properties which are rich in nutrients and antioxidant compounds, can actually provide good protection for dental health, helping prevent the risk of caries and other dental problems. Let's explore how tamarind can be a natural ally in maintaining healthy teeth.

                    1. Reduces the growth of bacteria that cause caries:
                    Tamarind has been proven to have antibacterial properties which can help inhibit the growth of bacteria, especially Streptococcus mutans. This bacteria is known as the main cause of dental caries. By reducing the number of these bacteria, tamarind helps prevent plaque formation and maintains a healthy oral cavity.
                   
                    2. Source of Calcium for Dental Health:
                    Calcium is an important mineral for the strength and density of teeth. Tamarind contains calcium, which can provide additional support for healthy teeth. Increasing calcium intake can help strengthen tooth enamel, the protective layer that protects teeth from decay.
                   
                    3. Anti-Inflammatory Effects on Gums:
                    Tamarind has anti-inflammatory properties which can help relieve inflammation of the gums. Chronic gum inflammation can lead to gum disease, which in turn can cause damage to the teeth and supporting bone. By reducing inflammation, tamarind can contribute to optimal gum health.
                   
                    4. Prevents Plaque and Tartar Formation:
                    Plaque and tartar are common problems that can cause tooth decay and gum disease. Tamarind can help prevent the formation of plaque and tartar by inhibiting the growth of the bacteria that cause it. This provides additional protection to teeth from the threat of damage.
                   
                    5. Minimizes the Risk of Caries:
                    With its antibacterial, anti-inflammatory properties and calcium content, tamarind helps minimize the risk of dental caries. Regular use of tamarind, either through direct consumption or integration in food and drinks, can be an effective preventive measure in maintaining dental health.
                   
                    How to Use Tamarind to Maintain Dental Health:
                   
                    Tamarind Drink:
                    Consume tamarind drinks regularly. You can make a tamarind drink by mixing tamarind that has been soaked in water with a little honey or sugar to give it a better taste.
                   
                    Brush Teeth with Tamarind:
                    Make a natural toothpaste by mixing mashed tamarind with a little baking soda. Brush your teeth with this mixture for added cleaning and protective benefits.
                   
                    Add to Food:
                    Add tamarind as an ingredient in cooking. It can be used in a variety of dishes, providing health benefits to teeth from within.
                   
                    Warning:
                    Even though tamarind can provide benefits for dental health, still maintain oral hygiene by brushing your teeth regularly, using dental floss, and visiting the dentist regularly.
                   
                    With its ability to inhibit caries-causing bacteria, provide calcium support, and reduce inflammation, tamarind can be considered an effective natural ingredient in maintaining healthy teeth. By embracing healthy living habits and integrating tamarind in dental care, we can ensure a healthy, bright smile for the long term.`,
                    "image": gambar10,
                    "post": "Post on 12/10/2024",
                    "kategori": "pengobatan",
                    "referensi": "https://journal.unnes.ac.id/sju/index.php/higeia/article/view/13998"
            },
            ar:  {
                "nama": "تحسين صحة الأسنان من خلال حماية التمر الهندي من التسوس",
                "deskripsi": `صحة الأسنان هي جزء لا يتجزأ من رفاهيتنا العامة. التمر الهندي، المعروف بخصائصه الطبيعية الغنية بالمواد المغذية والمركبات المضادة للأكسدة، يمكن أن يوفر حماية جيدة لصحة الأسنان، مما يساعد على منع خطر التسوس ومشاكل الأسنان الأخرى. دعونا نستكشف كيف يمكن أن يكون التمر الهندي حليفًا طبيعيًا في الحفاظ على صحة الأسنان.

                1. يقلل من نمو البكتيريا المسببة للتسوس :
                ثبت أن التمر الهندي له خصائص مضادة للجراثيم والتي يمكن أن تساعد في منع نمو البكتيريا، وخاصة العقدية الطافرة. وتعرف هذه البكتيريا بأنها السبب الرئيسي لتسوس الأسنان. من خلال تقليل عدد هذه البكتيريا، يساعد التمر الهندي على منع تكوين البلاك ويحافظ على صحة تجويف الفم.
               
                2. مصدر الكالسيوم لصحة الأسنان:
                الكالسيوم هو معدن مهم لقوة وكثافة الأسنان. يحتوي التمر الهندي على الكالسيوم، الذي يمكن أن يوفر دعمًا إضافيًا للأسنان الصحية. يمكن أن تساعد زيادة تناول الكالسيوم في تقوية مينا الأسنان، وهي الطبقة الواقية التي تحمي الأسنان من التسوس.
               
                3. التأثيرات المضادة للالتهابات على اللثة:
                يمتلك التمر الهندي خصائص مضادة للالتهابات والتي يمكن أن تساعد في تخفيف التهاب اللثة. يمكن أن يؤدي التهاب اللثة المزمن إلى أمراض اللثة، والتي بدورها يمكن أن تسبب ضررًا للأسنان والعظام الداعمة. من خلال تقليل الالتهاب، يمكن أن يساهم التمر الهندي في تحسين صحة اللثة.
               
                4. يمنع تكون البلاك والجير:
                البلاك والجير من المشاكل الشائعة التي يمكن أن تسبب تسوس الأسنان وأمراض اللثة. يمكن أن يساعد التمر الهندي في منع تكون البلاك والجير عن طريق تثبيط نمو البكتيريا المسببة له. وهذا يوفر حماية إضافية للأسنان من خطر التلف.
               
                5. يقلل من مخاطر التسوس:
                بفضل خصائصه المضادة للبكتيريا والمضادة للالتهابات ومحتواه من الكالسيوم، يساعد التمر الهندي على تقليل مخاطر تسوس الأسنان. الاستخدام المنتظم للتمر الهندي، سواء من خلال الاستهلاك المباشر أو دمجه في الأطعمة والمشروبات، يمكن أن يكون إجراء وقائي فعال في الحفاظ على صحة الأسنان.
               
                كيفية استخدام التمر الهندي للحفاظ على صحة الأسنان:
               
                مشروب التمر الهندي:
                تناول مشروبات التمر الهندي بانتظام. يمكنك تحضير مشروب التمر الهندي عن طريق خلط التمر الهندي المنقوع في الماء مع القليل من العسل أو السكر لإضفاء طعم أفضل عليه.
               
                تنظيف الأسنان بالتمر الهندي:
                اصنعي معجون أسنان طبيعي عن طريق خلط التمر الهندي المهروس مع القليل من صودا الخبز. قم بتنظيف أسنانك بهذا الخليط لمزيد من فوائد التنظيف والحماية.
               
                أضف إلى الطعام:
                إضافة التمر الهندي كعنصر في الطبخ. يمكن استخدامه في مجموعة متنوعة من الأطباق، مما يوفر فوائد صحية للأسنان من الداخل.
               
                تحذير:
                على الرغم من أن التمر الهندي يمكن أن يوفر فوائد لصحة الأسنان، إلا أنه لا يزال يحافظ على نظافة الفم عن طريق تنظيف أسنانك بانتظام، واستخدام خيط تنظيف الأسنان، وزيارة طبيب الأسنان بانتظام.
               
                بفضل قدرته على تثبيط البكتيريا المسببة للتسوس، وتوفير دعم الكالسيوم، وتقليل الالتهاب، يمكن اعتبار التمر الهندي مكونًا طبيعيًا فعالاً في الحفاظ على صحة الأسنان. ومن خلال تبني عادات الحياة الصحية ودمج التمر الهندي في العناية بالأسنان، يمكننا ضمان ابتسامة صحية ومشرقة على المدى الطويل.`,
                "image": gambar10,
                "post": "تم النشر بتاريخ 12/10/2024",
                "kategori": "pengobatan",
                "referensi": "https://journal.unnes.ac.id/sju/index.php/higeia/article/view/13998"
            },
            zh:  {
                "nama": "通过罗望子预防龋齿优化牙齿健康",
                "deskripsi": `牙齿健康是我们整体福祉不可或缺的一部分。 罗望子以其富含营养物质和抗氧化化合物的天然特性而闻名，实际上可以为牙齿健康提供良好的保护，有助于预防龋齿和其他牙齿问题的风险。 让我们探讨一下罗望子如何成为保持牙齿健康的天然盟友。

                1. 减少引起龋齿的细菌生长：
                罗望子已被证明具有抗菌特性，有助于抑制细菌的生长，尤其是变形链球菌。 这种细菌被认为是龋齿的主要原因。 通过减少这些细菌的数量，罗望子有助于防止牙菌斑形成并保持健康的口腔。
               
                2. 牙齿健康的钙来源：
                钙是牙齿强度和密度的重要矿物质。 罗望子含有钙，可以为健康的牙齿提供额外的支持。 增加钙的摄入量可以帮助强化牙釉质，牙釉质是防止牙齿腐烂的保护层。
               
                3. 对牙龈的抗炎作用：
                罗望子具有抗炎特性，有助于缓解牙龈炎症。 慢性牙龈炎症会导致牙龈疾病，进而导致牙齿和支撑骨骼受损。 通过减少炎症，罗望子可以促进最佳的牙龈健康。
               
                4. 防止牙菌斑和牙垢形成：
                牙菌斑和牙垢是可能导致蛀牙和牙龈疾病的常见问题。 罗望子可以通过抑制引起牙菌斑和牙垢的细菌生长来帮助防止牙菌斑和牙垢的形成。 这为牙齿提供了额外的保护，使其免受损坏的威胁。
               
                5. 最大限度地降低龋齿风险：
                罗望子具有抗菌、抗炎特性和钙含量，有助于降低龋齿风险。 经常使用罗望子，无论是直接食用还是与食物和饮料混合，都可以成为保持牙齿健康的有效预防措施。
               
                如何使用罗望子来保持牙齿健康：
               
                罗望子饮料：
                定期饮用罗望子饮料。 您可以将浸泡在水中的罗望子与少许蜂蜜或糖混合制成罗望子饮料，以使其味道更好。
               
                用罗望子刷牙：
                将捣碎的罗望子与少量小苏打混合制成天然牙膏。 用这种混合物刷牙可增加清洁和保护作用。
               
                添加到食物中：
                添加罗望子作为烹饪原料。 它可用于各种菜肴，从内部为牙齿提供健康益处。
               
                警告：
                尽管罗望子对牙齿健康有益，但仍然可以通过定期刷牙、使用牙线和定期看牙医来保持口腔卫生。
               
                罗望子具有抑制引起龋齿的细菌、提供钙支持和减少炎症的能力，可以被认为是保持牙齿健康的有效天然成分。 通过养成健康的生活习惯并将罗望子融入牙齿护理中，我们可以确保长期健康、明亮的笑容。`,
                "image": gambar10,
                "post": "发表于 12/10/2024",
                "kategori": "pengobatan",
                "referensi": "https://journal.unnes.ac.id/sju/index.php/higeia/article/view/13998"
            },
         },
         {
            id: {
                "nama": "Manfaat Kesehatan Luar Biasa dari Asam Jawa: Sumber Nutrisi dan Antioksidan Alami",
                "deskripsi": `Asam Jawa (Tamarindus indica) adalah buah yang umumnya digunakan sebagai bahan makanan dan bumbu di berbagai masakan Asia dan Afrika. 
                              Selain memberikan rasa asam dan citarasa unik pada hidangan, asam jawa juga terkenal karena manfaat kesehatan luar biasa yang dimilikinya. Buah ini mengandung berbagai nutrisi penting dan senyawa antioksidan yang dapat membantu mendukung kesehatan tubuh Anda. 
                              Dalam artikel ini, kita akan menjelajahi beberapa manfaat kesehatan asam jawa dan mengapa Anda harus mempertimbangkan untuk mengonsumsinya secara teratur.
                              Kaya akan Nutrisi Penting :
                              Asam jawa adalah sumber berbagai nutrisi penting yang mendukung kesehatan tubuh. Ini termasuk vitamin C, vitamin A, vitamin B, kalsium, kalium, zat besi, dan serat. Vitamin C adalah antioksidan yang membantu melawan radikal bebas dan mendukung sistem kekebalan tubuh. Serat dalam asam jawa membantu pencernaan dan dapat membantu mengatur gula darah.
                              Antioksidan Kuat :
                              Asam jawa mengandung sejumlah senyawa antioksidan, termasuk asam askorbat, karotenoid, dan polifenol. Antioksidan membantu melindungi tubuh dari kerusakan yang disebabkan oleh radikal bebas, yang dapat berkontribusi pada penuaan dini dan perkembangan penyakit serius seperti kanker dan penyakit jantung.
                              Meningkatkan Pencernaan :
                              Asam jawa telah lama digunakan sebagai obat alami untuk masalah pencernaan. Kandungan seratnya membantu mengurangi sembelit dan mendukung kesehatan usus. Selain itu, asam jawa juga memiliki sifat antimikroba yang dapat membantu mengurangi pertumbuhan bakteri berbahaya dalam saluran pencernaan.
                              Pengelolaan Berat Badan :
                              Kandungan serat dalam asam jawa dapat membantu Anda merasa kenyang lebih lama, sehingga mengurangi keinginan untuk makan berlebihan. Ini dapat membantu dalam pengelolaan berat badan dan pencegahan obesitas.
                              Penggunaan dalam Pengobatan Tradisional :
                              Di banyak budaya, asam jawa digunakan dalam pengobatan tradisional untuk mengobati berbagai masalah kesehatan, seperti batuk, demam, dan sakit tenggorokan. Manfaat kesehatan asam jawa telah dihargai sejak ribuan tahun yang lalu dan masih digunakan dalam pengobatan alternatif.
                              Cara Mengonsumsi Asam Jawa :
                              Asam jawa dapat dikonsumsi dalam berbagai cara. Anda dapat membuat jus asam jawa, menambahkannya ke dalam hidangan masakan, atau menggunakannya sebagai bumbu. Jus asam jawa juga sering digunakan dalam minuman segar.
                              Namun, perlu diingat bahwa asam jawa dalam jumlah berlebihan mungkin tidak cocok untuk semua orang. Jika Anda memiliki masalah kesehatan tertentu, konsultasikan dengan dokter atau ahli gizi sebelum menambahkan asam jawa ke dalam diet Anda.
                              Dalam kesimpulan, asam jawa adalah buah yang kaya akan nutrisi dan antioksidan yang memiliki manfaat kesehatan luar biasa. Dengan mengonsumsinya secara teratur, Anda dapat mendukung kesehatan tubuh Anda dan menikmati cita rasa uniknya dalam berbagai hidangan. Jadi, jangan ragu untuk memasukkan asam jawa dalam diet sehat Anda dan merasakan manfaat kesehatannya.
                              `,
                "image":  gambar11,
                "post": "Diposting pada 12/10/2024",
                "kategori": "pengobatan"
            },
            en: {
                "nama": "Amazing Health Benefits of Tamarind: A Natural Source of Nutrients and Antioxidants",
                "deskripsi": `Tamarind (Tamarindus indica) is a fruit that is generally used as a food ingredient and spice in various Asian and African cuisines.
                              Apart from providing a sour taste and unique flavor to dishes, tamarind is also renowned for its incredible health benefits. This fruit contains various important nutrients and antioxidant compounds that can help support your body's health.
                              In this article, we will explore some of the health benefits of tamarind and why you should consider consuming it regularly.
                              Rich in Important Nutrients:
                              Tamarind is a source of various important nutrients that support body health. These include vitamin C, vitamin A, vitamin B, calcium, potassium, iron, and fiber. Vitamin C is an antioxidant that helps fight free radicals and supports the immune system. The fiber in tamarind aids digestion and can help regulate blood sugar.
                              Powerful Antioxidant:
                              Tamarind contains a number of antioxidant compounds, including ascorbic acid, carotenoids and polyphenols. Antioxidants help protect the body from damage caused by free radicals, which can contribute to premature aging and the development of serious diseases such as cancer and heart disease.
                              Improves Digestion:
                              Tamarind has long been used as a natural remedy for digestive problems. The fiber content helps reduce constipation and supports gut health. Apart from that, tamarind also has antimicrobial properties which can help reduce the growth of harmful bacteria in the digestive tract.
                              Weight Management:
                              The fiber content in tamarind can help you feel full longer, thereby reducing the desire to overeat. This can help in weight management and obesity prevention.
                              Uses in Traditional Medicine:
                              In many cultures, tamarind is used in traditional medicine to treat various health problems, such as coughs, fevers, and sore throats. The health benefits of tamarind have been appreciated for thousands of years and are still used in alternative medicine.
                              How to consume tamarind:
                              Tamarind can be consumed in various ways. You can make tamarind juice, add it to dishes, or use it as a spice. Tamarind juice is also often used in fresh drinks.
                              However, keep in mind that excessive amounts of tamarind may not be suitable for everyone. If you have certain health problems, consult a doctor or nutritionist before adding tamarind to your diet.
                              In conclusion, tamarind is a fruit rich in nutrients and antioxidants that has extraordinary health benefits. By consuming it regularly, you can support your body's health and enjoy its unique taste in various dishes. So, don't hesitate to include tamarind in your healthy diet and experience its health benefits.
                             `,
                "image": gambar11,
                "post": "Posted on 12/10/2024",
                "kategori": "pengobatan"
            },
            ar: {
                "nama": "فوائد صحية مذهلة للتمر الهندي: مصدر طبيعي للمغذيات ومضادات الأكسدة",
                "deskripsi": `التمر الهندي (تمريندوس إنديكا) هو فاكهة تستخدم عادة كمكون غذائي وتوابل في مختلف المأكولات الآسيوية والأفريقية.
                              بصرف النظر عن توفير المذاق الحامض والنكهة الفريدة للأطباق، يشتهر التمر الهندي أيضًا بفوائده الصحية المذهلة. تحتوي هذه الفاكهة على العديد من العناصر الغذائية الهامة والمركبات المضادة للأكسدة التي يمكن أن تساعد في دعم صحة الجسم.
                              في هذه المقالة، سنستكشف بعض الفوائد الصحية للتمر الهندي ولماذا يجب عليك التفكير في تناوله بانتظام.
                               غنية بالعناصر الغذائية الهامة:
                               يعتبر التمر الهندي مصدراً للعديد من العناصر الغذائية الهامة التي تدعم صحة الجسم. وتشمل هذه فيتامين ج، وفيتامين أ، وفيتامين ب، والكالسيوم، والبوتاسيوم، والحديد، والألياف. فيتامين C هو أحد مضادات الأكسدة التي تساعد على محاربة الجذور الحرة ويدعم جهاز المناعة. تساعد الألياف الموجودة في التمر الهندي على الهضم ويمكن أن تساعد في تنظيم نسبة السكر في الدم.
                              مضادات الأكسدة القوية:
                              يحتوي التمر الهندي على عدد من المركبات المضادة للأكسدة، بما في ذلك حمض الأسكوربيك والكاروتينات والبوليفينول. تساعد مضادات الأكسدة على حماية الجسم من الأضرار التي تسببها الجذور الحرة، والتي يمكن أن تساهم في الشيخوخة المبكرة وتطور الأمراض الخطيرة مثل السرطان وأمراض القلب.
                              يحسن عملية الهضم:
                               منذ فترة طويلة يستخدم التمر الهندي كعلاج طبيعي لمشاكل الجهاز الهضمي. يساعد محتوى الألياف على تقليل الإمساك ويدعم صحة الأمعاء. بصرف النظر عن ذلك، يتمتع التمر الهندي أيضًا بخصائص مضادة للميكروبات يمكن أن تساعد في تقليل نمو البكتيريا الضارة في الجهاز الهضمي.
                               إدارة الوزن:
                               يمكن أن يساعدك محتوى الألياف في التمر الهندي على الشعور بالشبع لفترة أطول، وبالتالي تقليل الرغبة في الإفراط في تناول الطعام. وهذا يمكن أن يساعد في إدارة الوزن والوقاية من السمنة.
                               الاستخدامات في الطب التقليدي:
                               في العديد من الثقافات، يستخدم التمر الهندي في الطب التقليدي لعلاج المشاكل الصحية المختلفة، مثل السعال والحمى والتهاب الحلق. لقد تم تقدير الفوائد الصحية للتمر الهندي منذ آلاف السنين وما زالت تستخدم في الطب البديل.
                               طريقة تناول التمر الهندي:
                               يمكن استهلاك التمر الهندي بطرق مختلفة. يمكنك تحضير عصير التمر الهندي، أو إضافته إلى الأطباق، أو استخدامه كتوابل. وكثيرا ما يستخدم عصير التمر الهندي في المشروبات الطازجة.
                               ومع ذلك، ضع في اعتبارك أن الكميات الزائدة من التمر الهندي قد لا تكون مناسبة للجميع. إذا كنت تعاني من مشاكل صحية معينة، استشر الطبيب أو أخصائي التغذية قبل إضافة التمر الهندي إلى نظامك الغذائي.
                               في الختام، التمر الهندي هو ثمرة غنية بالمواد المغذية ومضادات الأكسدة التي لها فوائد صحية غير عادية. ومن خلال تناوله بانتظام، يمكنك دعم صحة جسمك والاستمتاع بمذاقه الفريد في أطباق متنوعة. لذلك، لا تتردد في إدراج التمر الهندي في نظامك الغذائي الصحي وتجربة فوائده الصحية.
                               `,
                "image": gambar11,
                "post": "تم النشر بتاريخ 12/10/2024",
                "kategori": "pengobatan"
            },
            zh: {
                "nama": "罗望子的惊人健康益处：营养素和抗氧化剂的天然来源",
                "deskripsi": `罗望子（Tamarindus indica）是一种水果，通常在各种亚洲和非洲美食中用作食品配料和香料。
                              除了为菜肴提供酸味和独特风味外，罗望子还以其令人难以置信的健康益处而闻名。这种水果含有各种重要的营养素和抗氧化化合物，可以帮助支持您的身体健康。
                              在本文中，我们将探讨罗望子的一些健康益处以及为什么您应该考虑定期食用它。
                              富含重要营养素：
                              罗望子是支持身体健康的各种重要营养素的来源。其中包括维生素 C、维生素 A、维生素 B、钙、钾、铁和纤维。维生素 C 是一种抗氧化剂，有助于对抗自由基并支持免疫系统。罗望子中的纤维有助于消化并有助于调节血糖。
                              强效抗氧化剂：
                              罗望子含有多种抗氧化化合物，包括抗坏血酸、类胡萝卜素和多酚。抗氧化剂有助于保护身体免受自由基造成的损害，自由基会导致过早衰老以及癌症和心脏病等严重疾病的发生。
                              改善消化：
                              罗望子长期以来一直被用作治疗消化问题的天然药物。纤维含量有助于减少便秘并支持肠道健康。除此之外，罗望子还具有抗菌特性，有助于减少消化道中有害细菌的生长。
                              体重管理：
                              罗望子中的纤维含量可以帮助您延长饱腹感，从而减少暴饮暴食的欲望。这有助于体重管理和预防肥胖。
                              在传统医学中的用途：
                              在许多文化中，罗望子在传统医学中用于治疗各种健康问题，例如咳嗽、发烧和喉咙痛。几千年来，罗望子的健康益处一直受到人们的重视，并且仍然用于替代医学。
                              罗望子的食用方法：
                              罗望子可以通过多种方式食用。您可以制作罗望子汁，将其添加到菜肴中，或将其用作香料。罗望子汁也常用于新鲜饮料中。
                              然而，请记住，过量的罗望子可能并不适合所有人。如果您有某些健康问题，请在饮食中添加罗望子之前咨询医生或营养师。
                              总之，罗望子是一种富含营养和抗氧化剂的水果，具有非凡的健康益处。经常食用它可以支持身体健康，并在各种菜肴中享受其独特的味道。因此，请毫不犹豫地将罗望子纳入您的健康饮食并体验其健康益处。
                             `,
                "image": gambar11,
                "post": "发表于 12/10/2024",
                "kategori": "pengobatan"
            }
         },
         {
            id: {
                "nama": "Asam Jawa untuk Kesehatan Pencernaan: Cara Meningkatkan Fungsi Lambung Anda",
                "deskripsi": `Pencernaan yang sehat adalah kunci utama untuk kesejahteraan tubuh kita. Lambung berperan penting dalam proses pencernaan makanan, dan masalah pencernaan dapat mengganggu kualitas hidup kita. Salah satu cara alami untuk meningkatkan fungsi lambung dan pencernaan adalah dengan mengonsumsi asam jawa. Dalam artikel ini, kita akan menjelajahi manfaat asam jawa bagi kesehatan pencernaan dan cara mengintegrasikannya ke dalam diet Anda.
                              Asam Jawa: Sumber Serat dan Enzim Pencernaan :
                              Asam jawa adalah sumber serat yang penting dalam diet kita. Serat adalah bagian penting dari makanan yang membantu dalam pencernaan, mengatur gerakan usus, dan mencegah sembelit. Selain itu, asam jawa juga mengandung enzim pencernaan seperti amilase, lipase, dan protease. Enzim-enzim ini dapat membantu memecah makanan dalam lambung sehingga tubuh dapat mencernanya dengan lebih efisien.
                              Mengatasi Masalah Pencernaan :
                              Asam jawa telah digunakan secara tradisional untuk mengatasi masalah pencernaan, seperti perut kembung, gas, dan gangguan lambung. Jus asam jawa sering digunakan sebagai obat alami untuk mengurangi gejala masalah pencernaan. Ini membantu mengurangi ketidaknyamanan dan meningkatkan proses pencernaan.
                              Menyembuhkan Luka pada Lambung :
                              Asam jawa juga memiliki sifat antibakteri yang dapat membantu melawan pertumbuhan bakteri berbahaya dalam lambung. Ini membantu melindungi lambung dari kerusakan dan luka. Dalam beberapa kasus, asam jawa bahkan digunakan untuk membantu mengobati luka lambung.
                              Mengurangi Risiko Konstipasi :
                              Konstipasi adalah masalah pencernaan yang umum, dan serat dalam asam jawa dapat membantu mengurangi risiko konstipasi. Serat membantu melunakkan tinja dan mempercepat gerakan usus, membuat pencernaan lebih lancar.
                              Cara Mengonsumsi Asam Jawa :
                              Anda dapat mengonsumsi asam jawa dalam berbagai cara. Salah satu cara yang paling umum adalah dengan membuat jus asam jawa. Jus ini dapat diminum langsung atau digunakan sebagai bumbu dalam masakan. Anda juga dapat menemukan suplemen asam jawa di toko makanan kesehatan.
                              `,
                "image":  gambar12,
                "post": "Diposting pada 12/10/2024",
                "kategori": "pengobatan"
           },
           en: {
            "nama": "Tamarind for Digestive Health: How to Improve Your Stomach Function",
            "deskripsi": `Healthy digestion is the main key to our body's well-being. The stomach plays an important role in the process of digestion of food, and digestive problems can interfere with our quality of life. One natural way to improve stomach and digestive function is to consume tamarind. In this article, we'll explore the digestive health benefits of tamarind and how to integrate it into your diet.
                          Tamarind: Source of Fiber and Digestive Enzymes:
                          Tamarind is an important source of fiber in our diet. Fiber is an important part of the diet that helps in digestion, regulates bowel movements, and prevents constipation. Apart from that, tamarind also contains digestive enzymes such as amylase, lipase and protease. These enzymes can help break down food in the stomach so the body can digest it more efficiently.
                          Overcoming Digestive Problems:
                          Tamarind has been traditionally used to treat digestive problems, such as flatulence, gas, and gastric disorders. Tamarind juice is often used as a natural remedy to reduce symptoms of digestive problems. This helps reduce discomfort and improves the digestive process.
                           Healing Wounds in the Stomach:
                           Tamarind also has antibacterial properties which can help fight the growth of harmful bacteria in the stomach. This helps protect the stomach from damage and injury. In some cases, tamarind is even used to help treat stomach ulcers.
                           Reduces the risk of constipation:
                          Constipation is a common digestive problem, and the fiber in tamarind may help reduce the risk of constipation. Fiber helps soften stools and speed up bowel movements, making digestion smoother.
                           How to consume tamarind:
                          You can consume tamarind in various ways. One of the most common ways is to make tamarind juice. This juice can be drunk directly or used as a spice in cooking. You can also find tamarind supplements in health food stores.
                          `,
            "image":  gambar12,
            "post": "Posted on 12/10/2024",
            "kategori": "pengobatan"
       },
       ar: {
        "nama": "التمر الهندي لصحة الجهاز الهضمي: كيفية تحسين وظيفة المعدة",
        "deskripsi": `الهضم الصحي هو المفتاح الرئيسي لرفاهية الجسم. تلعب المعدة دورًا مهمًا في عملية هضم الطعام، ويمكن لمشاكل الجهاز الهضمي أن تتداخل مع نوعية حياتنا. إحدى الطرق الطبيعية لتحسين وظيفة المعدة والجهاز الهضمي هي تناول التمر الهندي. في هذه المقالة، سنستكشف فوائد التمر الهندي الصحية للجهاز الهضمي وكيفية دمجه في نظامك الغذائي.
                     التمر الهندي: مصدر للألياف والإنزيمات الهاضمة:
                     التمر الهندي هو مصدر مهم للألياف في نظامنا الغذائي. تعتبر الألياف جزءًا مهمًا من النظام الغذائي الذي يساعد في عملية الهضم، وينظم حركة الأمعاء، ويمنع الإمساك. وبصرف النظر عن ذلك، يحتوي التمر الهندي أيضًا على إنزيمات هضمية مثل الأميليز والليباز والبروتياز. يمكن أن تساعد هذه الإنزيمات في تكسير الطعام في المعدة حتى يتمكن الجسم من هضمه بكفاءة أكبر.
                     التغلب على مشاكل الجهاز الهضمي:
                     يُستخدم التمر الهندي تقليدياً لعلاج مشاكل الجهاز الهضمي، مثل انتفاخ البطن والغازات واضطرابات المعدة. غالبًا ما يستخدم عصير التمر الهندي كعلاج طبيعي لتقليل أعراض مشاكل الجهاز الهضمي. وهذا يساعد على تقليل الانزعاج ويحسن عملية الهضم.
                     شفاء الجروح في المعدة:
                     يتمتع التمر الهندي أيضًا بخصائص مضادة للبكتيريا يمكن أن تساعد في مكافحة نمو البكتيريا الضارة في المعدة. وهذا يساعد على حماية المعدة من التلف والإصابة. وفي بعض الحالات، يتم استخدام التمر الهندي للمساعدة في علاج قرحة المعدة.
                      يقلل من خطر الإمساك:
                     الإمساك هو مشكلة شائعة في الجهاز الهضمي، والألياف الموجودة في التمر الهندي قد تساعد في تقليل خطر الإمساك. تساعد الألياف على تليين البراز وتسريع حركات الأمعاء، مما يجعل عملية الهضم أكثر سلاسة.
                     طريقة تناول التمر الهندي:
                      يمكنك تناول التمر الهندي بطرق مختلفة. إحدى الطرق الأكثر شيوعًا هي صنع عصير التمر الهندي. يمكن شرب هذا العصير مباشرة أو استخدامه كتوابل في الطبخ. يمكنك أيضًا العثور على مكملات التمر الهندي في متاجر الأطعمة الصحية.
                      `,
        "image":  gambar12,
        "post": "تم النشر بتاريخ 12/10/2024",
        "kategori": "pengobatan"
   },
   zh: {
    "nama": "罗望子促进消化健康：如何改善胃功能",
    "deskripsi": `健康的消化是我们身体健康的关键。胃在食物消化过程中起着重要作用，消化问题会影响我们的生活质量。改善胃和消化功能的一种自然方法是食用罗望子。在本文中，我们将探讨罗望子对消化系统健康的益处以及如何将其融入您的饮食中。
                  罗望子：纤维和消化酶的来源：
                  罗望子是我们饮食中纤维的重要来源。纤维是饮食的重要组成部分，有助于消化、调节肠蠕动和预防便秘。除此之外，罗望子还含有淀粉酶、脂肪酶和蛋白酶等消化酶。这些酶可以帮助分解胃中的食物，以便身体更有效地消化它。
                  克服消化问题：
                  罗望子传统上用于治疗消化问题，例如胀气、胀气和胃病。罗望子汁通常被用作减轻消化问题症状的自然疗法。这有助于减少不适并改善消化过程。
                  治愈胃部伤口：
                  罗望子还具有抗菌特性，可以帮助对抗胃中有害细菌的生长。这有助于保护胃免受损伤。在某些情况下，罗望子甚至被用来帮助治疗胃溃疡。
                  降低便秘的风险：
                  便秘是一种常见的消化问题，罗望子中的纤维可能有助于降低便秘的风险。纤维有助于软化粪便并加速排便，使消化更顺畅。
                  罗望子的食用方法：
                  您可以通过多种方式食用罗望子。最常见的方法之一是制作罗望子汁。这种汁液可以直接饮用，也可以作为烹饪的调味品。您还可以在保健食品商店找到罗望子补充剂。
                  `,
    "image":  gambar12,
    "post": "发表于 12/10/2024",
    "kategori": "pengobatan"
},
        }
    ]
            

export default dataArtikel;